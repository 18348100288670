import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Table, Modal, Row, Col } from 'antd'
import useSWR from '@/hooks/useSWR'
import styled from 'styled-components'

const ACTION = {
  NOPROCESS: 0,
  SOLVED: 1,
  PENDING: 2,
}

const ACTION_NAME = {
  [ACTION.NOPROCESS]: 'unsolved',
  [ACTION.SOLVED]: 'solved',
  [ACTION.PENDING]: 'pending',
}

export default function ClearedAlertHistoryModal({ item, onCancel }) {
  const { data, loading } = useSWR(`/v1/notify/detail/${item.id}/0/9999`)

  const { t } = useTranslation()
  const columns = [
    {
      title: t('all:time'),
      dataIndex: 'createAt',
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('all:action'),
      dataIndex: 'action',
      width: 150,
      render: (v) => ACTION_NAME[v],
    },
    {
      title: t('all:description'),
      dataIndex: 'description',
    },
  ]

  return (
    <StyleModal
      visible
      title={item?.braceletId || ''}
      width={650}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary' }}>
      <Row>
        <Col>
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={data?.data}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </Col>
      </Row>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)`
  .ant-modal-footer button + button {
    display: none;
  }
`
