import { createAction, handleActions } from 'redux-actions'
import { pipe, pathOr, compose, pluck, flatten, filter } from 'ramda'
import { switchMap, map } from 'rxjs/operators'
import { createRequestTypes } from 'actions/Types'
import { ofType, catchRequestError } from '../utils/extendOperators'
import { districtSetAllAPI, districtSetAPI } from '../apis'

/**
 * Action Types
 */
const LIST_AREAS = createRequestTypes('LIST_AREAS')
const LIST_ALERT_AREA = createRequestTypes('LIST_ALERT_AREA')
/**
 * Action Creator
 */
export const listAreas = createAction(LIST_AREAS.REQUEST)
export const listAlertArea = createAction(LIST_ALERT_AREA.REQUEST)
/**
 * Epics
 */

export const listAreasEpic = pipe(
  ofType(LIST_AREAS.REQUEST),
  switchMap(({ payload = {} }) =>
    districtSetAllAPI(payload).pipe(
      map((response) => {
        const states = pathOr([], ['data'], response)

        const dfs = (node, result) => {
          if (!node) {
            return
          }

          result[node.id] = node

          if (node.childs) {
            node.childs.map((c) => dfs(c, result))
          }
        }

        const addKey = (_states) => {
          const traversal = (item) => {
            if (!item) {
              return
            }

            item = { ...item, key: item.id }

            if (item.childs) {
              item.childs = item.childs.map(traversal)
            }

            return item
          }

          return _states.map(traversal)
        }

        const byId = {}
        states.map((node) => dfs(node, byId))

        return createAction(LIST_AREAS.SUCCESS)({
          ...payload,
          data: { content: response.data, states: addKey(states), byId },
        })
      }),
      catchRequestError(createAction(LIST_AREAS.FAILURE)),
    ),
  ),
)

export const listAlertAreaEpic = pipe(
  ofType(LIST_ALERT_AREA.REQUEST),
  switchMap(({ payload = {} }) =>
    districtSetAPI(payload).pipe(
      map((response) => {
        const _flatten = compose(filter(Boolean), flatten, pluck('childs'))

        const states = pathOr([], ['data'], response)
        const countries = _flatten(states)
        const areas = _flatten(countries)

        const dfs = (node, result) => {
          if (!node) {
            return
          }

          result[node.id] = node

          if (node.childs) {
            node.childs.map((c) => dfs(c, result))
          }
        }

        const byId = {}
        states.map((node) => dfs(node, byId))

        return createAction(LIST_ALERT_AREA.SUCCESS)({
          ...payload,
          data: { states, countries, areas, byId },
        })
      }),
      catchRequestError(createAction(LIST_ALERT_AREA.FAILURE)),
    ),
  ),
)

const initialState = {
  alertArea: { states: [], countries: [], areas: [], byId: null },
  allArea: { states: [], byId: null, isLoading: false, loaded: false },
}

export default handleActions(
  {
    [LIST_AREAS.REQUEST]: (state, action) => ({
      ...state,
      allArea: {
        ...state.allArea,
        isLoading: true,
        loaded: false,
      },
    }),
    [LIST_AREAS.SUCCESS]: (state, { payload: { data } = {} }) => ({
      ...state,
      allArea: {
        states: data.states,
        byId: data.byId,
        isLoading: false,
        loaded: true,
      },
    }),
    [LIST_AREAS.FAILURE]: (state, action) => ({
      ...state,
      allArea: {
        ...state.allArea,
        isLoading: false,
        loaded: true,
      },
    }),
    [LIST_ALERT_AREA.SUCCESS]: (state, { payload: { data } = {} }) => ({
      ...state,
      alertArea: data,
    }),
  },
  initialState,
)
