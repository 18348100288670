import styled from 'styled-components'

const DrawerHandle = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

  &.is--top-left {
    border-radius: 0 0 4px 0;
    top: 0;
    left: 0;
    width: 36px;
  }
`

export default DrawerHandle
