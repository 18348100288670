import React from 'react'
import { Form } from 'antd'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isEmpty } from 'ramda'
import zipcelx from 'zipcelx'

import useSWR from '@/hooks/useSWR'

import { NOTIFY_STATE_NAME, NOTIFY_STATE_COLOR } from 'constants/notifyState'

import SearchForm from './SearchForm'
import initChart from './chart-config'
import { CheckboxListOrder } from './CheckboxList'
;(function (H) {
  H.wrap(H.Chart.prototype, 'getCSV', function (proceed) {
    let result = proceed.apply(this, Array.prototype.slice.call(arguments, 1))
    result += '\n"Total",'
    // eslint-disable-next-line
    this.options.series.map((s) => {
      result += `"${s.data.reduce((a, b) => a + b)}",`
    })
    return result
  })
})(Highcharts)
;(function (H) {
  H.wrap(H.Chart.prototype, 'downloadXLS', function () {
    var div = document.createElement('div'),
      name,
      xlsxRows = [],
      rows
    div.style.display = 'none'
    document.body.appendChild(div)
    rows = this.getDataRows(true)

    xlsxRows = rows.slice(1).map(function (row) {
      return row.map(function (column) {
        return {
          type: typeof column === 'number' ? 'number' : 'string',
          value: column,
        }
      })
    })

    const total = [
      {
        type: 'string',
        value: 'Total',
      },
    ]

    for (let i = 1; i < xlsxRows.length; i++) {
      const row = xlsxRows[i]
      for (let s = 1; s < row.length; s++) {
        const item = row[s]
        if (!total[s]) {
          total[s] = {
            type: 'number',
            value: 0,
          }
        }
        total[s]['value'] += parseInt(item.value)
      }
    }

    xlsxRows.push(total)

    if (this.options.exporting.filename) {
      name = this.options.exporting.filename
    } else if (this.title && this.title.textStr) {
      name = this.title.textStr.replace(/ /g, '-').toLowerCase()
    } else {
      name = 'chart'
    }

    zipcelx({
      filename: name,
      sheet: {
        data: xlsxRows,
      },
    })
  })
})(Highcharts)

function AlertReport() {
  const { data: dataSource, refetch } = useSWR('/v1/report/alert', {
    lazy: true,
  })

  const [chartOptions, setChartOptions] = React.useState()
  const [title, setTitle] = React.useState()

  React.useEffect(() => {
    if (!dataSource) {
      return
    }

    const xAxisData = dataSource.data.map((i) => i.date)

    let temp = {}

    dataSource.data.forEach((i) =>
      i.data.forEach((j) => {
        if (temp[j.notifyType]) {
          temp[j.notifyType].data.push(j.count)
          return
        }

        temp[j.notifyType] = {}
        temp[j.notifyType].data = []

        temp[j.notifyType].name = NOTIFY_STATE_NAME[j.notifyType]
        temp[j.notifyType].color = NOTIFY_STATE_COLOR[j.notifyType]
        temp[j.notifyType].data.push(j.count)
      }),
    )

    const series = CheckboxListOrder.map((i) => temp[i]).filter((i) => i)

    const options = initChart({ series, xAxisData, title })

    setChartOptions(options)
  }, [dataSource, title])

  const handleSearch = ({ dateStr, ...values }) => {
    refetch(values)

    setTitle(` ${isEmpty(dateStr) ? '' : dateStr[0] + ' ~ ' + dateStr[1]}`)
  }

  return (
    <>
      <SearchForm onSearch={handleSearch} />
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{
            style: { width: '100%', backgroundColor: '#fff', height: 400 },
          }}
          allowChartUpdate
        />
      )}
    </>
  )
}

export default Form.create()(AlertReport)
