import { filter, pluck, compose, includes, pathOr, not, values } from 'ramda'
import useUser from '@/hooks/useUser'
import { cardStatus } from '@/constants/card'
import { ROLES } from 'constants/roles'
import { NOTIFY_STATE as notify } from 'constants/notifyState'
import { NOTIFY_STATE as statuses } from 'constants/notifyState'

const excludePermissions = {
  [ROLES.MEDICAL_STAFF]: [
    notify.LeaveFence,
    notify.RemoveBracelte,
    notify.LowBattery,
    notify.DelayToHome,
    notify.LeaveHome,
    notify.OnTheWayHome,
    notify.FanceOff,
  ],
  [ROLES.IPD]: [notify.Fever, notify.HighBloodPressure, notify.LowBloodOxygen],
  [ROLES.IPK]: [notify.Fever, notify.HighBloodPressure, notify.LowBloodOxygen],
  [ROLES.CONTROL_CENTER]: [notify.Fever, notify.HighBloodPressure, notify.LowBloodOxygen],
  [ROLES.ENFORCEMENT_TEAM]: [notify.Fever, notify.HighBloodPressure, notify.LowBloodOxygen],
  [ROLES.AIRPORT_STAFF]: values(notify),
  [ROLES.HOSPITAL_STAFF]: values(notify),
}

const STATUSES = {
  Normal: statuses.Normal,
  Fever: statuses.Fever,
  LeaveFence: statuses.LeaveFence,
  RemoveBracelte: statuses.RemoveBracelte,
  LowBattery: statuses.LowBattery,
  OnTheWay: statuses.OnTheWayHome,
  HighBloodPressure: statuses.HighBloodPressure,
  LowBloodOxygen: statuses.LowBloodOxygen,
  LeaveHome: statuses.LeaveHome,
  DelayToHome: statuses.DelayToHome,
}

const excludeStatuses = {
  [ROLES.MEDICAL_STAFF]: [
    notify.LeaveFence,
    notify.RemoveBracelte,
    notify.LowBattery,
    notify.DelayToHome,
    notify.LeaveHome,
    notify.OnTheWayHome,
    notify.FanceOff,
  ],
  [ROLES.HOSPITAL_STAFF]: [STATUSES.Normal, STATUSES.Fever],
  [ROLES.IPD]: [STATUSES.Fever, STATUSES.HighBloodPressure, STATUSES.LowBloodOxygen],
  [ROLES.IPK]: [STATUSES.Fever, STATUSES.HighBloodPressure, STATUSES.LowBloodOxygen],
  [ROLES.CONTROL_CENTER]: [STATUSES.Fever, STATUSES.HighBloodPressure, STATUSES.LowBloodOxygen],
  [ROLES.ENFORCEMENT_TEAM]: [STATUSES.Fever, STATUSES.HighBloodPressure, STATUSES.LowBloodOxygen],
  [ROLES.AIRPORT_STAFF]: values(STATUSES),
}

export default function useAuth() {
  const { user } = useUser()

  return {
    user,
    excludePermissions,
    checkPermission: (statusId) =>
      compose(not, includes(statusId), pathOr([], [user.memberRole]))(excludePermissions),
    defaultNotifyTypes: compose(
      filter((x) => x >= 0),
      filter((x) => !excludePermissions[user.memberRole]?.includes(x)),
      pluck('value'),
    )(cardStatus),
    statuses: compose(
      filter((x) => !excludeStatuses[user.memberRole]?.includes(x)),
      values(),
    )(STATUSES),
  }
}
