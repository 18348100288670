import React from 'react'
import { Form, Row, Col, Modal, Select, Input } from 'antd'
import { compose, pathOr, head, filter, propEq, isNil } from 'ramda'
import { useSelector } from 'react-redux'

import RoleSelect from 'components/RoleSelect'
import { ROLES } from 'constants/roles'
import useQueryAreaBySeq from 'hooks/useAllAreaBySeq'
import useAuth from 'hooks/useAuth'
import { GUARD_AREA } from 'constants/permissions'

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

const formItemLayout_2 = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

const FormItem = Form.Item
const Option = Select.Option

function AccountModal({ form, member, onCancel, onOk }) {
  const { user } = useAuth()

  const { states, byId, isLoading } = useSelector((state) => state.app.allArea)

  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const [isEdit] = React.useState(!isNil(member))
  const [currentCountries, setCurrentCountries] = React.useState([])
  const [currentCountry, setCurrentCountry] = React.useState({})
  const [currentSelectedRole, setCurrentSelectedRole] = React.useState(ROLES.Admin)

  const { currentState, currentCountry: currentAreaCountry, currentDist } = useQueryAreaBySeq({
    districtSetSeq: member?.districtSetSeq,
  })

  React.useEffect(() => {
    if (member) {
      setCurrentSelectedRole(pathOr(currentSelectedRole, ['memberRole'], member))
    }
  }, [member]) // eslint-disable-line

  React.useEffect(() => {
    if (currentState) {
      setCurrentCountries(compose(pathOr([], ['childs']))(currentState))
      setCurrentCountry(currentAreaCountry)
      return
    }

    // if (states) {
    //   setFieldsValue({ country: '', dist: '' })
    //   return
    // }
  }, [currentState, currentAreaCountry])

  const handleStateChange = (value) => {
    if (!value) {
      setFieldsValue({ country: '', dist: '' })
      return
    }

    const countries = pathOr([], [value, 'childs'], byId)
    const country = head(countries)
    const dist = head(pathOr([], ['childs'], country))

    setCurrentCountries(countries)
    setCurrentCountry(country)

    setFieldsValue({ country: country?.id, dist: dist?.id })
  }

  const handleCountryChange = (value) => {
    if (!value) {
      setFieldsValue({ dist: '' })
      setCurrentCountry({})
      return
    }

    const country = filter(propEq('id', value), currentCountries)[0]
    const dist = head(pathOr([], ['childs'], country))

    setCurrentCountry(country)
    setFieldsValue({ dist: dist?.id })
  }

  const handleOk = () => {
    validateFields((err, values) => {
      if (err) {
        return
      }

      const params = {
        ...values,
        id: member?.id,
        memberId: values.mbrId,
        districtSetSeq: values.area || values.dist || values.state || null,
      }

      delete params.state
      delete params.country
      delete params.dist

      onOk(params)
      onCancel()
    })
  }

  return (
    <Modal
      visible
      title={member ? 'Edit Account' : 'Add Account'}
      width={650}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{ disabled: !user.isAdmin }}
      okText="Confirm">
      <Form colon={false} labelAlign="left">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Account">
              {isEdit
                ? pathOr(undefined, ['memberId'], member)
                : getFieldDecorator('mbrId', {
                    rules: [{ required: true }],
                  })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator('password', {
                rules: isEdit ? null : [{ required: true }],
                initialValue: pathOr(undefined, ['password'], member),
              })(<Input type="password" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Role">
              <RoleSelect
                onChange={setCurrentSelectedRole}
                getFieldDecorator={getFieldDecorator}
                initialValue={currentSelectedRole}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout_2} label="State">
              {getFieldDecorator('state', {
                initialValue: pathOr('', ['id'], currentState),
                rules: [
                  {
                    required: currentSelectedRole
                      ? GUARD_AREA[currentSelectedRole].state.required
                      : false,
                  },
                ],
              })(
                <Select
                  loading={isLoading}
                  onChange={handleStateChange}
                  disabled={!currentSelectedRole}>
                  {currentSelectedRole && !GUARD_AREA[currentSelectedRole].state.required && (
                    <Option key="all" value="">
                      All
                    </Option>
                  )}
                  {states &&
                    states.map((x) => (
                      <Option key={x.id} value={x.id}>
                        {x.name}
                      </Option>
                    ))}
                </Select>,
              )}
            </FormItem>
            <FormItem {...formItemLayout_2} label="District">
              {getFieldDecorator('district', {
                initialValue: pathOr('', ['id'], currentCountry),
                rules: [
                  {
                    required: currentSelectedRole
                      ? GUARD_AREA[currentSelectedRole].dist.required
                      : false,
                  },
                ],
              })(
                <Select
                  loading={isLoading}
                  onChange={handleCountryChange}
                  disabled={!currentSelectedRole}>
                  {currentSelectedRole && !GUARD_AREA[currentSelectedRole].dist.required && (
                    <Option key="all" value="">
                      All
                    </Option>
                  )}
                  {currentCountries.map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem {...formItemLayout_2} label="Area">
              {getFieldDecorator('area', {
                initialValue: pathOr('', ['id'], currentDist),
                rules: [
                  {
                    required: currentSelectedRole
                      ? GUARD_AREA[currentSelectedRole].area.required
                      : false,
                  },
                ],
              })(
                <Select loading={isLoading} disabled={!currentSelectedRole}>
                  {currentSelectedRole && !GUARD_AREA[currentSelectedRole].area.required && (
                    <Option key="all" value="">
                      All
                    </Option>
                  )}
                  {compose(pathOr([], ['childs']))(currentCountry).map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Form.create()(AccountModal)
