const { rgba } = require('polished')

const primary = '#2c343f'
const secondary = '#f5f5f5'
const white = '#fff'
const blue = '#5C90D2';

const theme = {
  secondary,
  greyishbrown: '#4a4a4a',
  perrywinkle: '#79abe5',
  darkbluegrey: '#1e3954',
  font: [14, 20],
  light: white,
  blue: blue,
  lightblue: '#70b8eb',
  header: {
    bg: primary,
    textColor: '#AAAAAA',
  },
  menu: {
    bg: primary,
    textColor: '#AAAAAA',
    hoverBg: rgba(0, 0, 0, 0.2),
    activeBg: primary,
    activeColor: blue,
    logoBg: rgba(255, 255, 255, 0.075),
  },
  actionBar: { bg: white, borderBottomLine: '#e9e9e9' },
  card: {
    red: '#b52615',
    orange: '#e38141',
    yellow: '#fac03f',
    lightblue: '#4672be',
    deepwine: 'rgb(99, 16, 19)',
    green: '#7aa953',
    lightorange: 'rgb(174, 118, 49)',
    blue: 'rgb(13, 0, 188)',
    purple: 'rgb(119, 45, 245)',
    pink: '#f277f8',
    lightgray: '#dfeaf5',
    darkblue: '#34404e',
  }
}

module.exports = theme
