import styled from 'styled-components'

export const StyleFieldsWrap = styled.div`
  position: absolute;
  top: 3px;
  right: 0px;
  z-index: 0;
  opacity: 0;
  width: 150px;
  background: white;

  &.fold {
    z-index: 10;
    opacity: 1;
    transition: all 200ms;
  }

  .ant-checkbox-group .ant-checkbox-wrapper {
    margin-left: 0px;
    margin-bottom: 8px;
    display: block;
  }
`
