import React from 'react'

let hidden = null
let visibilityChange = null

;(() => {
  try {
    const _document = document
    if (typeof _document.hidden !== 'undefined') {
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    }
    if (typeof _document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    }
    if (typeof _document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }
  } catch (error) {
    return undefined
  }
})()

export default function useVisiblityChange() {
  const [visible, setIsVisible] = React.useState(true)

  const handleVisiblityChange = () => {
    setIsVisible(!!!document[hidden])
  }

  React.useEffect(() => {
    document.addEventListener(visibilityChange, handleVisiblityChange)

    return function cleanup() {
      document.removeEventListener(visibilityChange, handleVisiblityChange)
    }
  }, [visible]) // eslint-disable-line

  return visible
}
