import { message } from 'antd'
import { createAction, handleActions, combineActions } from 'redux-actions'
import { pipe } from 'ramda'
import { switchMap, map, tap } from 'rxjs/operators'
import { createRequestTypes } from 'actions/Types'
import { ofType, catchRequestError } from '../utils/extendOperators'
import { addHospitalAPI, updateHospitalAPI } from '../apis'

/**
 * Action Types
 */
const ADD_HOSPITAL = createRequestTypes('ADD_HOSPITAL')
const UPDATE_HOSPITAL = createRequestTypes('UPDATE_HOSPITAL')
/**
 * Action Creator
 */
export const addHospital = createAction(ADD_HOSPITAL.REQUEST)
export const updateHospital = createAction(UPDATE_HOSPITAL.REQUEST)
/**
 * Epics
 */

export const addHospitalEpic = pipe(
  ofType(ADD_HOSPITAL.REQUEST),
  switchMap(({ payload = '' }) =>
    addHospitalAPI(payload).pipe(
      tap(() => message.success('created successfull')),
      map(createAction(ADD_HOSPITAL.SUCCESS)),
      catchRequestError((e) => {
        if (e.status === 400) {
          message.error(e.response.error.message)
          return createAction(ADD_HOSPITAL.FAILURE)()
        }

        message.error(`created failed for (${e.message})`)
        return createAction(ADD_HOSPITAL.FAILURE)()
      }),
    ),
  ),
)

export const updateHospitalEpic = pipe(
  ofType(UPDATE_HOSPITAL.REQUEST),
  switchMap(({ payload = '' }) =>
    updateHospitalAPI(payload).pipe(
      tap(() => message.success('updated successfull')),
      map(createAction(UPDATE_HOSPITAL.SUCCESS)),
      catchRequestError((e) => {
        if (e.status === 400) {
          message.error(e.response.error.message)
          return createAction(UPDATE_HOSPITAL.FAILURE)()
        }

        message.error(`updated failed for (${e.message})`)
        return createAction(UPDATE_HOSPITAL.FAILURE)()
      }),
    ),
  ),
)

const initialState = {
  isLoading: false,
}

export default handleActions(
  {
    [combineActions(ADD_HOSPITAL.REQUEST, UPDATE_HOSPITAL.REQUEST)]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [combineActions(ADD_HOSPITAL.SUCCESS, UPDATE_HOSPITAL.SUCCESS)]: (
      state,
      { payload: { data } = {} },
    ) => ({
      ...state,
      isLoading: false,
    }),
    [combineActions(ADD_HOSPITAL.FAILURE, UPDATE_HOSPITAL.FAILURE)]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
)
