import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { includes } from 'ramda'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BasicLayout from 'components/BasicLayout'
import { ROLES } from 'constants/roles'
import StatusLayout from './StatusLayout'
import useSWR from '@/hooks/useSWR'
import { find, propEq, pathOr } from 'ramda'

import {
  handleTotalRegisterPie,
  handleQuarantineStatusPie,
  handleQuarantineStatusSpline,
} from './charts'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function Home(props) {
  const [totalPie, setTotalPie] = useState(handleTotalRegisterPie({}))
  const [quarantineStatusPie, setQuarantineStatusPie] = useState(handleQuarantineStatusPie({}))
  const [quarantineStatusSpline, setQuarantineStatusSpline] = useState(
    handleQuarantineStatusSpline({}),
  )

  const { data: res } = useSWR('/v1/statistics')

  useEffect(() => {
    if (props.currentUser.memberRole && res) {
      handlePieCharts(res.data)
      handleSplineChart(res.data)
    }
  }, [props.currentUser.memberRole, res]) // eslint-disable-line

  const _getQuarantineStatusPieByRole = (val) => {
    if (includes(props.currentUser.memberRole, [ROLES.MEDICAL_STAFF, ROLES.HOSPITAL_STAFF])) {
      return [
        {
          name: 'normal',
          // Covid test
          y:
            Number(find(propEq('notifyState', -1))(val)?.total || 0) + // Normal
            Number(find(propEq('notifyState', -2))(val)?.total || 0) + // On the way home
            Number(find(propEq('notifyState', 9))(val)?.total || 0) + // Delay to home
            Number(find(propEq('notifyState', -3))(val)?.total || 0) + // Fence off
            Number(find(propEq('notifyState', -4))(val)?.total || 0) +
            Number(find(propEq('notifyState', 5))(val)?.total || 0) +
            Number(find(propEq('notifyState', 4))(val)?.total || 0) +
            Number(find(propEq('notifyState', 6))(val)?.total || 0),
          color: '#45c37a',
        },
        {
          name: 'fever',
          y: find(propEq('notifyState', 7))(val)?.total || 0,
          color: '#d26a5c',
        },
      ]
    } else {
      return [
        {
          name: 'normal',
          // Covid test
          y:
            Number(find(propEq('notifyState', -1))(val)?.total || 0) + // Normal
            Number(find(propEq('notifyState', -2))(val)?.total || 0) + // On the way home
            Number(find(propEq('notifyState', 9))(val)?.total || 0) + // Delay to home
            Number(find(propEq('notifyState', -3))(val)?.total || 0) + // Fence off
            Number(find(propEq('notifyState', -4))(val)?.total || 0),
          color: '#45c37a',
        },
        {
          name: 'fever',
          y: find(propEq('notifyState', 7))(val)?.total || 0,
          color: '#d26a5c',
        },
        {
          name: 'leave fence',
          y: find(propEq('notifyState', 5))(val)?.total || 0,
          color: '#f3b660',
        },
        {
          name: 'remove bracelte',
          y: find(propEq('notifyState', 4))(val)?.total || 0,
          color: '#f4ea29',
        },
        {
          name: 'no power',
          y: find(propEq('notifyState', 6))(val)?.total || 0,
          color: '#5c90d2',
        },
      ]
    }
  }

  const handlePieCharts = (data) => {
    const _data = pathOr([], ['childs'], data)
    const onTheWayHome = find(propEq('notifyState', -2))(_data)?.total || 0
    const total = data.total
    setTotalPie(
      handleTotalRegisterPie([
        {
          name: 'on the way home',
          y: onTheWayHome,
          percent: (onTheWayHome / total) * 100,
          color: '#5c90d2',
        },
        {
          name: 'quarantine activated',
          y: total - onTheWayHome,
          percent: ((total - onTheWayHome) / total) * 100,
          color: '#d26a5c',
        },
      ]),
    )

    const statusList = _getQuarantineStatusPieByRole(_data)
    setQuarantineStatusPie(handleQuarantineStatusPie(statusList))
  }

  const handleSplineChart = (data) => {
    const _data = pathOr([], ['childs'], data)
    const categories = [..._data[0].childs.map((i) => i.displayName)]
    setQuarantineStatusSpline(
      handleQuarantineStatusSpline(categories, [
        {
          name: 'normal',
          marker: {
            symbol: 'square',
          },
          color: '#45c37a',
          data: [...find(propEq('notifyState', -1))(_data).childs.map((i) => i.total)],
        },
        {
          name: 'fever',
          marker: {
            symbol: 'square',
          },
          color: '#d26a5c',
          data: [...find(propEq('notifyState', 7))(_data).childs.map((i) => i.total)],
        },
        {
          name: 'leave fence',
          marker: {
            symbol: 'square',
          },
          color: '#f3b660',
          data: [...find(propEq('notifyState', 5))(_data).childs.map((i) => i.total)],
        },
        {
          name: 'remove',
          marker: {
            symbol: 'square',
          },
          color: '#f4ea29',
          data: [...find(propEq('notifyState', 4))(_data).childs.map((i) => i.total)],
        },
        {
          name: 'no power',
          marker: {
            symbol: 'square',
          },
          color: '#5c90d2',
          data: [...find(propEq('notifyState', 6))(_data).childs.map((i) => i.total)],
        },
      ]),
    )
  }

  return (
    <BasicLayout title="Statistics" style={{ padding: 0 }}>
      <Row style={{ backgroundColor: '#fff' }}>
        <Col span={11}>
          <HighchartsReact
            highcharts={Highcharts}
            options={totalPie}
            containerProps={{ style: { height: '400px' } }}
            allowChartUpdate
          />
        </Col>
        <Col span={11} offset={2}>
          <HighchartsReact
            highcharts={Highcharts}
            options={quarantineStatusPie}
            containerProps={{ style: { height: '400px' } }}
            allowChartUpdate
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HighchartsReact
            highcharts={Highcharts}
            options={quarantineStatusSpline}
            containerProps={{ style: { height: '400px' } }}
            allowChartUpdate
          />
        </Col>
      </Row>
      <StatusLayout data={res} />
    </BasicLayout>
  )
}
const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
})

export default compose(connect(mapStateToProps))(Home)
