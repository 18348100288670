import React from 'react'
import ReactDOM from 'react-dom'
import Cookies from 'js-cookie'
import App from './App'
import { Provider } from 'react-redux'
import { loginInit } from './reducers/auth'
import renderer from '@/utils/renderer'
import { queryString } from '@/utils/webHelper'
import history from '@/utils/history'
import { isProd, mobileAppUrl } from 'constants/endpoint'
import { unregister } from './registerServiceWorker'
import configureStore from './store'

const isMobile = false
const shouldRedirect = isMobile && isProd

if (shouldRedirect) {
  window.location = mobileAppUrl
}

if (!shouldRedirect) {
  const { token, url, loginId } = queryString(history.search)
  const userModalVisible = url === 'edit-pass'

  if (token && loginId) {
    Cookies.set('_dplusToken', token)
    Cookies.set('_dplusUserId', loginId)
  }

  if (url) {
    history.replace(userModalVisible ? '/' : url)
  }

  const store = configureStore()

  store.dispatch(loginInit())

  const app = renderer()(App)

  ReactDOM.render(<Provider store={store}>{app}</Provider>, document.getElementById('root'))

  unregister()
}
