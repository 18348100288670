import React from 'react'
import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

function useQueryAreaBySeq({ districtSetSeq }) {
  const byId = useSelector((state) => state.app.allArea.byId)

  const [currentState, setCurrentState] = React.useState()
  const [currentCountry, setCurrentCountry] = React.useState()
  const [currentDist, setCurrentDist] = React.useState()

  React.useEffect(() => {
    if (byId && districtSetSeq) {
      const area = pathOr(undefined, [districtSetSeq], byId)
      const level = pathOr(undefined, ['level'], area)

      if (level === 1) {
        const stateName = pathOr(undefined, ['name'], area)
        const stateChilds = pathOr(undefined, ['childs'], area)

        setCurrentState({ id: districtSetSeq, name: stateName, childs: stateChilds })
        setCurrentCountry({ id: '', name: 'ALL', childs: [] })
        setCurrentDist({ id: '', name: 'ALL' })

        return
      }

      if (level === 2) {
        const countryName = pathOr(undefined, ['name'], area)
        const countryChilds = pathOr(undefined, ['childs'], area)

        const stateSeq = pathOr(undefined, ['parentSeq'], area)
        const state = pathOr(undefined, [stateSeq], byId)
        const stateName = pathOr(undefined, ['name'], state)
        const stateChilds = pathOr(undefined, ['childs'], state)

        setCurrentState({ id: stateSeq, name: stateName, childs: stateChilds })
        setCurrentCountry({ id: districtSetSeq, name: countryName, childs: countryChilds })
        setCurrentDist({ id: '', name: 'ALL' })

        return
      }

      const distName = pathOr(undefined, ['name'], area)

      const countrySeq = pathOr(undefined, ['parentSeq'], area)
      const country = pathOr(undefined, [countrySeq], byId)
      const countryName = pathOr(undefined, ['name'], country)
      const countryChilds = pathOr(undefined, ['childs'], country)

      const stateSeq = pathOr(undefined, ['parentSeq'], country)
      const state = pathOr(undefined, [stateSeq], byId)
      const stateName = pathOr(undefined, ['name'], state)
      const stateChilds = pathOr(undefined, ['childs'], state)

      setCurrentState({ id: stateSeq, name: stateName, childs: stateChilds })
      setCurrentCountry({ id: countrySeq, name: countryName, childs: countryChilds })
      setCurrentDist({ id: districtSetSeq, name: distName })
    }
  }, [districtSetSeq, byId])

  return {
    currentState,
    currentCountry,
    currentDist,
  }
}

export default useQueryAreaBySeq

export function useQueryAreaByLevel3Seq({ districtSetSeq }) {
  const byId = useSelector((state) => state.app.allArea.byId)

  const [currentState, setCurrentState] = React.useState()
  const [currentCountry, setCurrentCountry] = React.useState()
  const [currentDist, setCurrentDist] = React.useState()

  React.useEffect(() => {
    if (byId && districtSetSeq) {
      const area = pathOr(undefined, [districtSetSeq], byId)

      const distName = pathOr(undefined, ['name'], area)

      const countrySeq = pathOr(undefined, ['parentSeq'], area)
      const country = pathOr(undefined, [countrySeq], byId)
      const countryName = pathOr(undefined, ['name'], country)
      const countryChilds = pathOr(undefined, ['childs'], country)

      const stateSeq = pathOr(undefined, ['parentSeq'], country)
      const state = pathOr(undefined, [stateSeq], byId)
      const stateName = pathOr(undefined, ['name'], state)
      const stateChilds = pathOr(undefined, ['childs'], state)

      setCurrentState({ id: stateSeq, name: stateName, childs: stateChilds })
      setCurrentCountry({ id: countrySeq, name: countryName, childs: countryChilds })
      setCurrentDist({ id: districtSetSeq, name: distName })
    }
  }, [districtSetSeq, byId])

  return {
    currentState,
    currentCountry,
    currentDist,
  }
}
