import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { compose, pathOr } from 'ramda'
import { Row, Col, Button } from 'antd'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'

import useVisible from '@/hooks/useVisible'
import useSWR from '@/hooks/useSWR'

import { addHospital, updateHospital } from 'reducers/setting'
import useAuth from 'hooks/useAuth'
import Table from './EditableTable'
import HospitalModal from './HospitalModal'
import AllAreaSearchForm from 'components/AllAreaSearchForm'

const PAGE_SIZE = 10
let i = 0

function Tab2({ addHospital, updateHospital }) {
  const { t } = useTranslation()

  const { user } = useAuth()
  const { byId } = useSelector((state) => state.app.allArea)

  const [page, setPage] = React.useState(0)
  const [hospital, setHospital] = React.useState()

  const { data: dataSource, loading, refetch } = useSWR(`/v1/hospital/list/${page}/${PAGE_SIZE}`, {
    lazy: true,
  })

  const [newModalVisible, handleNewModalVisible] = useVisible()

  const handleEditModalVisible = (val) => () => {
    setHospital(val)
    handleNewModalVisible()
  }

  const handleSearch = (val) => {
    refetch({
      level3seq: val.area || val.dist || val.state,
    })
    setPage(0)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'State',
      width: '10%',
      render: (text, r) => {
        const area = pathOr(undefined, [r.level3Seq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 1) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 2) {
          const stateSeq = pathOr(undefined, ['parentSeq'], area)
          const state = pathOr(undefined, [stateSeq], byId)
          return pathOr(undefined, ['name'], state)
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)

        const stateSeq = pathOr(undefined, ['parentSeq'], country)
        const state = pathOr(undefined, [stateSeq], byId)
        const stateName = pathOr(undefined, ['name'], state)

        return stateName
      },
    },
    {
      title: 'District',
      width: '10%',
      render: (v, r) => {
        const area = pathOr(undefined, [r.level3Seq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 2) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 1) {
          return 'ALL'
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)
        const countryName = pathOr(undefined, ['name'], country)

        return countryName
      },
    },
    {
      title: 'Area',
      width: '10%',
      render: (v, r) => {
        const area = pathOr(undefined, [r.level3Seq], byId)
        const level = pathOr(undefined, ['level'], area)
        if (level === 1 || level === 2) {
          return 'ALL'
        }

        return pathOr('ALL', ['name'], area)
      },
    },
    {
      title: 'Action',
      align: 'center',
      dataIndex: 'action',
      render: (text, row) => (
        <StyledActionButton
          type="primary"
          size="small"
          icon="edit"
          onClick={handleEditModalVisible(row)}
        />
      ),
    },
  ]

  const handleTableChange = ({ current }) => {
    setPage(current - 1)
  }

  const handleOk = (val) => {
    const handler = val.id ? updateHospital : addHospital
    handler(val)
    refetch({ refetch: i++ })
  }

  return (
    <>
      {newModalVisible && (
        <HospitalModal onOk={handleOk} hospital={hospital} onCancel={handleNewModalVisible} />
      )}
      <Row className="ml-24 mt-24">
        <Col>
          <AllAreaSearchForm onSearch={handleSearch} />
        </Col>
        <Col className="flex justify-end my-16">
          {user.isAdmin && (
            <Button type="primary" className="w-96" onClick={handleEditModalVisible()}>
              {t('all:add clinic')}
            </Button>
          )}
        </Col>
        <Col>
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={dataSource?.data}
            editable={user.isAdmin}
            size="small"
            pagination={{
              current: page + 1,
              pageSize: PAGE_SIZE,
              total: dataSource?.total,
            }}
            // onSave={handleUpdateTbCell}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </>
  )
}

const StyledActionButton = styled(Button)`
  border: 1px solid #50a1da;
  background: ${({ theme }) => theme.lightblue};
  i {
    vertical-align: text-top;
  }
`

const mapDispatchToProps = {
  addHospital,
  updateHospital,
}

export default compose(connect(null, mapDispatchToProps))(Tab2)
