import React from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import useVisible from '@/hooks/useVisible'
import useSWR from '@/hooks/useSWR'

import { ACTION_NAME } from 'constants/action'
import { Flex } from 'components/common/Flex'
import BasicLayout from 'components/BasicLayout'
import AllAreaSearchForm from 'components/AllAreaSearchForm'
import ClearedAlertHistoryModal from './ClearedAlertHistoryModal'
import { useColumns, ColumnSelection } from 'components/columnSelection'
import { Block } from './style'

const ButtonGroup = Button.Group

const STATUS = {
  Fever: 7,
  LeaveFence: 5,
  RemoveBracelte: 4,
  LowBattery: 6,
  DelayToHome: 9,
}

const STATUS_NAME = {
  [STATUS.Fever]: 'fever',
  [STATUS.LeaveFence]: 'leave fence',
  [STATUS.RemoveBracelte]: 'remove',
  [STATUS.LowBattery]: 'no power',
  [STATUS.DelayToHome]: 'delay to home',
}

function ClearedAlertHistory() {
  const [page, setPage] = React.useState(0)

  const { data: historyData, loading, refetch } = useSWR(`/v1/notify/list/${page}/10`, {
    lazy: true,
  })

  const [item, setCurrentItem] = React.useState()

  const [detailModalVisible, _handleDetailModalVisible] = useVisible()

  const handleDetailModalVisible = (item) => () => {
    setCurrentItem(item)
    _handleDetailModalVisible()
  }

  const columns = [
    {
      title: 'Time',
      dataIndex: 'createTime',
      type: 'checkbox',
      checked: true,
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Bracelet ID',
      dataIndex: 'major',
      type: 'checkbox',
      checked: true,
    },
    {
      title: 'Status',
      dataIndex: 'notifyType',
      type: 'checkbox',
      checked: true,
      render: (v) => STATUS_NAME[v],
    },
    {
      title: 'Action',
      dataIndex: 'status',
      type: 'checkbox',
      checked: true,
      render: (v) => ACTION_NAME[v],
    },
    {
      title: 'History',
      dataIndex: 'x',
      type: 'checkbox',
      checked: true,
      render: (_, r) => (
        <Button type="primary" size="small" onClick={handleDetailModalVisible(r)}>
          Detail
        </Button>
      ),
    },
  ]

  const { tbColumn, dynamicColumn, handleChange } = useColumns({
    columns,
  })

  const handleTableChange = ({ current }) => {
    setPage(current - 1)
  }

  const handleSearch = (values) => {
    refetch({ level3seq: values.area || values.dist || values.state, major: values.braceletId })
    setPage(0)
  }

  return (
    <>
      {detailModalVisible && (
        <ClearedAlertHistoryModal item={item} onCancel={_handleDetailModalVisible} />
      )}
      <BasicLayout
        title="Alerts history"
        renderActionBar={() => (
          <AllAreaSearchForm label="ID" searchFieldName="braceletId" onSearch={handleSearch} />
        )}>
        <Block>
          <Flex className="mb-10" style={{ justifyContent: 'flex-end' }}>
            <ButtonGroup>
              <ColumnSelection tbColumn={tbColumn} onChange={handleChange} />
            </ButtonGroup>
          </Flex>

          <Table
            rowKey="id"
            size="small"
            bordered
            loading={loading}
            columns={dynamicColumn}
            dataSource={historyData?.data}
            pagination={{ current: page + 1, pageSize: 10, total: historyData?.total }}
            onChange={handleTableChange}
          />
        </Block>
      </BasicLayout>
    </>
  )
}

export default ClearedAlertHistory
