/**
 * 生理資訊 : [VIEW: ROLES.Admin,ROLES.MEDICAL_STAFF]
 * Statistic / Account activities /AlertHistory / Report : [ALL]
 *
 * Solved, Pending:[ENFORCEMENT_TEAM ,ROLES.MEDICAL_STAFF]
 * Account Mgmt: [EDIT: ADMIN, VIEW: OTHERS]
 * notifyType:[] // 勾選清單不在這裡處理
 */

export const ROLES = {
  Admin: 1,
  AIRPORT_STAFF: 2,
  HOSPITAL_STAFF: 3,
  CONTROL_CENTER: 4,
  IPD: 5,
  ENFORCEMENT_TEAM: 6,
  MEDICAL_STAFF: 7,
  IPK: 8,
  1: 'Admin',
  2: 'AIRPORT_STAFF',
  3: 'HOSPITAL_STAFF',
  4: 'CONTROL_CENTER',
  5: 'IPD',
  6: 'ENFORCEMENT_TEAM',
  7: 'MEDICAL_STAFF',
  8: 'IPK',
}

export const ROLE_NAME = {
  [ROLES.Admin]: 'Administrator',
  [ROLES.AIRPORT_STAFF]: 'Airport binding Staff',
  [ROLES.HOSPITAL_STAFF]: 'Medical binding Staff',
  [ROLES.CONTROL_CENTER]: 'Control Centre',
  [ROLES.IPD]: 'IPD',
  [ROLES.IPK]: 'IPK',
  [ROLES.ENFORCEMENT_TEAM]: 'Enforcement Team',
  [ROLES.MEDICAL_STAFF]: 'Medical Staff',
}
