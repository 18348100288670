import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const mappedState = createSelector(
  (state) => state.auth,
  (auth) => ({
    user: auth.user,
    isAuthenticated: auth.isAuthenticated,
  }),
)

export default function useAuth() {
  return useSelector(mappedState)
}
