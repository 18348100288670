import { css, createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import { map } from 'ramda'

export const utils = css`
  .text--default {
    font-size: 14px !important;
  }

  .text-500 {
    font-weight: 500 !important;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }
`
export const components = css`
  .anticon {
    ${(p) =>
      map(
        (x) => css`
          &.text-${x} {
            svg {
              font-size: ${x}px !important;
            }
          }
        `,
        p.theme.font,
      )};
  }
`

export const content = css`
  .content {
    margin: 0 auto;
    padding: 16px 14px 1px;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    .content {
      padding: 30px 30px 1px;
      max-width: 100%;
    }
  }
`

export const sideContent = css`
  .side-content {
    padding: 13px 20px 1px !important;
    max-width: 100%;
  }
`

export default createGlobalStyle`
  ${normalize()};
  ${utils};
  ${components};
  ${content};
  ${sideContent};

  * {
    font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  a[href^="http://maps.google.com/maps"],
  a[href^="https://maps.google.com/maps"],
  .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none !important;
  }

  .gm-control-active.gm-fullscreen-control {
    right: -10px !important;
  }
`
