import styled from 'styled-components'

export const StatusLayout = styled.div`
  padding: 30px 0px 20px 30px;
  background-color: ${(p) => p.theme.secondary};
`
export const Block = styled.div`
  margin: 30px 30px 30px 0px;
`

export const BlockHeader = styled.div`
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
  background-color: #fff;
`
export const BlockContent = styled.div`
  padding: 20px 20px 20px;
  background-color: #fff;

  span:first-child {
    font-weight: 600;
  }
  span:last-child {
    &:hover {
      color: ${(p) => p.theme.blue};
    }
  }
`
export const StateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  padding: 12px 10px;

  border-top: 1px solid #f0f0f0;

  :nth-child(odd) {
    background-color: #f5f5f5;
  }
`
