import { createAction, handleActions, combineActions } from 'redux-actions'
import { pipe } from 'ramda'
import { message } from 'antd'
import { switchMap, map, tap } from 'rxjs/operators'
import { createRequestTypes } from 'actions/Types'
import { ofType, catchRequestError } from '../utils/extendOperators'
import { addAccountAPI, updateAccountAPI } from '../apis'

/**
 * Action Types
 */
const ADD_ACCOUNT = createRequestTypes('ADD_ACCOUNT')
const UPDATE_ACCOUNT = createRequestTypes('UPDATE_ACCOUNT')
/**
 * Action Creator
 */
export const addAccount = createAction(ADD_ACCOUNT.REQUEST)
export const updateAccount = createAction(UPDATE_ACCOUNT.REQUEST)
/**
 * Epics
 */

export const addAccountEpic = pipe(
  ofType(ADD_ACCOUNT.REQUEST),
  switchMap(({ payload = '' }) =>
    addAccountAPI(payload).pipe(
      tap(() => message.success('created successfull')),
      map(createAction(ADD_ACCOUNT.SUCCESS)),
      catchRequestError((e) => {
        if (e.status === 400) {
          message.error(e.response.error.message)
          return createAction(ADD_ACCOUNT.FAILURE)()
        }

        message.error(`created failed for (${e.message})`)
        return createAction(ADD_ACCOUNT.FAILURE)()
      }),
    ),
  ),
)

export const updateAccountEpic = pipe(
  ofType(UPDATE_ACCOUNT.REQUEST),
  switchMap(({ payload = '' }) =>
    updateAccountAPI(payload).pipe(
      tap(() => message.success('updated successfull')),
      map(createAction(UPDATE_ACCOUNT.SUCCESS)),
      catchRequestError((e) => {
        if (e.status === 400) {
          message.error(e.response.error.message)
          return createAction(UPDATE_ACCOUNT.FAILURE)()
        }

        message.error(`updated failed for (${e.message})`)
        return createAction(UPDATE_ACCOUNT.FAILURE)()
      }),
    ),
  ),
)

const initialState = {
  isLoading: false,
}

export default handleActions(
  {
    [combineActions(ADD_ACCOUNT.REQUEST, UPDATE_ACCOUNT.REQUEST)]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [combineActions(ADD_ACCOUNT.SUCCESS, UPDATE_ACCOUNT.SUCCESS)]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [combineActions(ADD_ACCOUNT.FAILURE, UPDATE_ACCOUNT.FAILURE)]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
)
