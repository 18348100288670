import i18n from 'i18next'

export const createFormItemLayout = (label = 3, wrapper = 21) => ({
  labelCol: {
    xs: { span: label },
    sm: { span: label },
  },
  wrapperCol: {
    xs: { span: wrapper },
    sm: { span: wrapper },
  },
})

export const thosandSeprartor = {
  formatter: (value) => {
    if (!value) {
      return value
    }
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  parser: (value) => (value ? value.replace(/\$\s?|(,*)/g, '') : value),
}

export const currency = {
  formatter: (value) => {
    if (!value) {
      return value
    }
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  parser: (value) => (value ? value.replace(/\$\s?|(,*)/g, '') : value),
}

export const percent = {
  // min: 0,
  max: 100,
  formatter: (value) => {
    if (!value) {
      return value
    }
    return `${value}%`
  },
  parser: (value) => (value ? value.replace('%', '') : value),
}

export let rules = {
  required: { required: true, message: i18n.t('common:required') },
  number: {
    type: 'number',
    message: i18n.t('common:number validate'),
    transform(value) {
      if (!value) {
        return value
      }
      return Number(value)
    },
  },
  email: {
    required: true,
    message: i18n.t('common:email validate'),
    pattern: new RegExp(/^.{8,128}$/),
  },
  account: {
    required: true,
    message: i18n.t('common:account validate'),
    pattern: new RegExp(/^.{8,128}$/),
  },
  password: {
    required: true,
    message: i18n.t('common:password validate'),
    pattern: new RegExp(/^.{6,128}$/),
  },
  min: (limit) => ({
    validator: (_, value, callback) => {
      if (value < limit) {
        callback(i18n.t('common:min validate', { num: limit }))
      } else {
        callback()
      }
    },
  }),
  max: (limit) => ({
    validator: (_, value, callback) => {
      if (limit === 0) {
        return callback(i18n.t('common:max validate 0'))
      }

      if (value > limit) {
        callback(i18n.t('common:max validate 1', { num: limit }))
      } else {
        callback()
      }
    },
  }),
}

i18n.on('languageChanged', function () {
  rules = {
    required: { required: true, message: i18n.t('common:required') },
    number: {
      type: 'number',
      message: i18n.t('common:number validate'),
      transform(value) {
        if (!value) {
          return value
        }
        return Number(value)
      },
    },
    email: {
      required: true,
      message: i18n.t('common:email validate'),
      pattern: new RegExp(/^.{8,128}$/),
    },
    account: {
      required: true,
      message: i18n.t('common:account validate'),
      pattern: new RegExp(/^.{8,128}$/),
    },
    password: {
      required: true,
      message: i18n.t('common:password validate'),
      pattern: new RegExp(/^.{6,128}$/),
    },
    min: (limit) => ({
      validator: (_, value, callback) => {
        if (value < limit) {
          callback(i18n.t('common:min validate', { num: limit }))
        } else {
          callback()
        }
      },
    }),
    max: (limit: number) => ({
      validator: (_, value, callback) => {
        if (limit === 0) {
          return callback(i18n.t('common:max validate 0'))
        }

        if (value > limit) {
          callback(i18n.t('common:max validate 1', { num: limit }))
        } else {
          callback()
        }
      },
    }),
  }
})
