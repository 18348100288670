import React from 'react'
import { Select } from 'antd'

import useFieldDecorator from '@/hooks/useFieldDecorator'
import { ROLES, ROLE_NAME } from 'constants/roles'

function RoleSelect({ getFieldDecorator, initialValue = ROLES.Admin, onChange }) {
  let wrapper = useFieldDecorator({
    field: 'memberRole',
    getFieldDecorator,
    initialValue,
  })

  return wrapper(
    <Select onChange={onChange}>
      {Object.keys(ROLE_NAME).map((i) => (
        <Select.Option key={Number(i)} value={Number(i)}>
          {ROLE_NAME[i]}
        </Select.Option>
      ))}
    </Select>,
  )
}

export default RoleSelect
