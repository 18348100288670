import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import initialI18nInstance from '@/locales/i18n'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Layout, Modal } from 'antd'
import { logout } from 'reducers/auth'
import { listAreas } from 'reducers/app'
import { AppHeader } from 'components'
import { NotFound, CheckAuth } from 'containers'
import ClearedAlertHistory from 'src/containers/ClearedAlertHistory'
import AutoSolvedAlertHistory from 'containers/ActivityManagement/AutoSolvedAlertHistory'
import ActivityManagement from 'containers/ActivityManagement'
import Setting from 'containers/Setting'
import Users from 'containers/Users'
import Accounts from 'containers/Accounts'
import Activities from 'containers/Activities'
import Report from 'containers/Report'
import { shouldDisplayError } from 'utils/NormalError'
import {
  CLEARED_ALERT_HISTORY,
  AUTO_SOLVED_ALERT_HISTORY,
  SETTING,
  MAP,
  USERS,
  ACCOUNTS,
  ACTIVITIES,
  REPORT,
} from 'constants/routes'
import { APP_TITLE } from 'constants/endpoint'
import GlobalStyles from './GlobalStyles'
import Home from 'containers/Home'

const { Content } = Layout

const mapStateToProps = (state) => ({
  isServerError: state.views.isServerError,
  error: state.views.error,
})

const mapDispatchToProps = {
  logout,
  listAreas,
}

@CheckAuth
@connect(mapStateToProps, mapDispatchToProps)
class App extends React.Component {
  static propTypes = {
    isServerError: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.title = APP_TITLE
    this.props.listAreas()
  }

  handleApiRequestError = (prevProps) => {
    const {
      logout,
      error: {
        timestamp: prevOccurTime,
        response: { message: prevErrMsg },
      },
    } = prevProps
    const {
      error: {
        timestamp: currOccurTime,
        status: currStatusCode,
        response: { message: currErrMsg },
      },
    } = this.props

    if (currErrMsg === 'Unauthorized') {
      if (currErrMsg !== prevErrMsg) {
        Modal.error({ content: i18n.t('common:Login has expired, please login again') })
        logout()
      }
    } else if (
      shouldDisplayError(currStatusCode) &&
      (currErrMsg !== prevErrMsg ||
        (currErrMsg === prevErrMsg && currOccurTime - prevOccurTime > 1500))
    ) {
      Modal.error({
        title: 'Server Error',
        content: currErrMsg,
      })
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.handleApiRequestError(prevProps)
  }

  render() {
    const { pageProps } = this.props
    const { i18n } = pageProps || {}

    // const {
    //   history: {
    //     location: { pathname },
    //   },
    // } = this.props

    return (
      <>
        <GlobalStyles />
        <I18nextProvider i18n={i18n || initialI18nInstance}>
          <Layout style={{ minHeight: '100vh' }}>
            <AppHeader />
            <Content>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path={MAP} component={ActivityManagement} />
                <Route path={CLEARED_ALERT_HISTORY} component={ClearedAlertHistory} />
                <Route path={SETTING} component={Setting} />
                <Route path={ACCOUNTS} component={Accounts} />
                <Route path={ACTIVITIES} component={Activities} />
                <Route path={USERS} component={Users} />
                <Route path={REPORT} component={Report} />
                <Route path={AUTO_SOLVED_ALERT_HISTORY} component={AutoSolvedAlertHistory} />
                <Route component={NotFound} />
              </Switch>
            </Content>
          </Layout>
        </I18nextProvider>
      </>
    )
  }
}

export default App
