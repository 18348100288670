import React from 'react'
import { Form, Modal, Input, Row, Col, Select, InputNumber } from 'antd'
import { compose, pathOr, head, filter, propEq } from 'ramda'
import { useSelector } from 'react-redux'

import useQueryAreaBySeq from 'hooks/useAllAreaBySeq'

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

const FormItem = Form.Item

function HospitalModal({ form, onCancel, onOk, hospital }) {
  const { getFieldDecorator, validateFields, setFieldsValue } = form
  const { states, byId, isLoading } = useSelector((state) => state.app.allArea)

  const [currentCountries, setCurrentCountries] = React.useState([])
  const [currentCountry, setCurrentCountry] = React.useState({})

  const { currentState, currentCountry: currentAreaCountry, currentDist } = useQueryAreaBySeq({
    districtSetSeq: hospital?.level3Seq,
  })

  React.useEffect(() => {
    if (currentState) {
      setCurrentCountries(compose(pathOr([], ['childs']))(currentState))
      setCurrentCountry(currentAreaCountry)
      return
    }
  }, [currentState, currentAreaCountry])

  const handleOk = () => {
    validateFields((err, values) => {
      if (err) {
        return
      }

      const params = {
        ...values,
        id: hospital?.id,
        level3seq: values.dist,
      }

      delete params.state
      delete params.county

      onOk(params)
      onCancel()
    })
  }

  const handleStateChange = (value) => {
    if (!value) {
      setFieldsValue({ country: '', dist: '' })
      return
    }

    const countries = pathOr([], [value, 'childs'], byId)
    const country = head(countries)
    const dist = head(pathOr([], ['childs'], country))

    setCurrentCountries(countries)
    setCurrentCountry(country)

    setFieldsValue({ country: country?.id, dist: dist?.id })
  }

  const handleCountryChange = (value) => {
    if (!value) {
      setFieldsValue({ dist: '' })
      setCurrentCountry({})
      return
    }

    const country = filter(propEq('id', value), currentCountries)[0]
    const dist = head(pathOr([], ['childs'], country))

    setCurrentCountry(country)
    setFieldsValue({ dist: dist?.id })
  }

  return (
    <Modal
      visible
      title={hospital ? 'Edit Clinic' : 'Add Clinic'}
      width={650}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Confirm">
      <Form colon={false} labelAlign="left">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name is required' }],
                initialValue: pathOr(undefined, ['name'], hospital),
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Range">
              {getFieldDecorator('radius', {
                rules: [{ required: true, message: 'Range is required' }],
                initialValue: pathOr(undefined, ['radius'], hospital),
              })(<InputNumber min={1} style={{ width: '100%' }} />)}
            </FormItem>
          </Col>
        </Row>
        <FormItem
          labelCol={{
            span: 3,
          }}
          wrapperCol={{
            span: 21,
          }}
          label="Address">
          {getFieldDecorator('address', {
            rules: [{ required: true, message: 'Address is required' }],
            initialValue: pathOr(undefined, ['address'], hospital),
          })(<Input />)}
        </FormItem>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Lat.">
              {getFieldDecorator('latitude', {
                rules: [{ required: true, message: 'Latitude is required' }],
                initialValue: pathOr(undefined, ['latitude'], hospital),
              })(<InputNumber style={{ width: '100%' }} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Lon.">
              {getFieldDecorator('longitude', {
                rules: [{ required: true, message: 'Longitude is required' }],
                initialValue: pathOr(undefined, ['longitude'], hospital),
              })(<InputNumber style={{ width: '100%' }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...formItemLayout} label="State">
              {getFieldDecorator('state', {
                rules: [{ required: true, message: 'State is required' }],
                initialValue: pathOr('', ['id'], currentState),
              })(
                <Select loading={isLoading} onChange={handleStateChange}>
                  {states.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="District">
              {getFieldDecorator('county', {
                rules: [{ required: true, message: 'District is required' }],
                initialValue: pathOr('', ['id'], currentCountry),
              })(
                <Select onChange={handleCountryChange}>
                  {currentCountries.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem {...formItemLayout} label="Area">
              {getFieldDecorator('dist', {
                rules: [{ required: true, message: 'Area is required' }],
                initialValue: pathOr('', ['id'], currentDist),
              })(
                <Select>
                  {compose(pathOr([], ['childs']))(currentCountry).map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Form.create()(HospitalModal)
