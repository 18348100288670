export const ACTION = { PASS: 0, SOLVED: 1, PENDING: 2, REASSIGN: 3, TAKECASE: 4 }

export const ACTION_NAME = {
  [ACTION.PASS]: 'Pass',
  [ACTION.SOLVED]: 'Solved',
  [ACTION.PENDING]: 'Pending',
  [ACTION.REASSIGN]: 'Re-Assign',
  [ACTION.TAKECASE]: 'Take Case',
}

export default ACTION
