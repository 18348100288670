import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'

import useFieldDecorator from '@/hooks/useFieldDecorator'
import { NOTIFY_STATE, NOTIFY_STATE_NAME } from 'constants/notifyState'
import useAuth from 'hooks/useAuth'

export const CheckboxListOrder = [
  NOTIFY_STATE.Fever,
  NOTIFY_STATE.HighBloodPressure,
  NOTIFY_STATE.LowBloodOxygen,
  NOTIFY_STATE.LeaveHome,
  NOTIFY_STATE.LeaveFence,
  NOTIFY_STATE.RemoveBracelte,
  NOTIFY_STATE.LowBattery,
  NOTIFY_STATE.DelayToHome,
]

// function _statusCheckbox(role) {
//   let list = []
//   switch (role) {
//     case ROLES.Admin:
//       list = [
//         NOTIFY_STATE.Fever,
//         // NOTIFY_STATE.HighBloodPressure,
//         // NOTIFY_STATE.LowBloodOxygen,
//         // NOTIFY_STATE.LeaveHome,
//         NOTIFY_STATE.LeaveFence,
//         NOTIFY_STATE.RemoveBracelte,
//         NOTIFY_STATE.LowBattery,
//         NOTIFY_STATE.DelayToHome,
//       ]
//       break
//     case ROLES.CONTROL_CENTER:
//     case ROLES.IPD:
//     case ROLES.IPK:
//     case ROLES.ENFORCEMENT_TEAM:
//       list = [
//         // NOTIFY_STATE.LeaveHome,
//         NOTIFY_STATE.LeaveFence,
//         NOTIFY_STATE.RemoveBracelte,
//         NOTIFY_STATE.LowBattery,
//         NOTIFY_STATE.DelayToHome,
//       ]
//       break
//     case ROLES.MEDICAL_STAFF:
//       list = [
//         NOTIFY_STATE.Fever,
//         //  NOTIFY_STATE.HighBloodPressure, NOTIFY_STATE.LowBloodOxygen
//       ]
//       break
//     default:
//   }

//   return list
// }

function CheckboxList({ getFieldDecorator }) {
  const { statuses } = useAuth()
  const [checkList, setCheckList] = useState([])

  useEffect(() => {
    setCheckList([
      ...statuses.filter((x) => x !== NOTIFY_STATE.Normal && x !== NOTIFY_STATE.OnTheWayHome),
    ])
  }, [statuses.length]) // eslint-disable-line

  let wrapper = useFieldDecorator({
    field: 'notifyTypes',
    getFieldDecorator,
    initialValue: checkList,
  })

  return wrapper(
    <Checkbox.Group>
      {checkList.map((i, idx) => (
        <Checkbox key={idx} value={i}>
          {NOTIFY_STATE_NAME[i]}
        </Checkbox>
      ))}
    </Checkbox.Group>,
  )
}

export default CheckboxList
