import { useDispatch } from 'react-redux'
import { latestStatus } from 'reducers/guardAreas'

let latestStatusInteval = null

export default function useInterval({ onCompleted }) {
  const dispatch = useDispatch()

  const _latestStatus = (values) => dispatch(latestStatus(values))

  const startInterval = () => {
    if (latestStatusInteval) {
      stopInterval()
    }

    latestStatusInteval = setInterval(() => _latestStatus({ onCompleted }), 8000)
  }

  const stopInterval = () => {
    if (latestStatusInteval) {
      clearInterval(latestStatusInteval)
    }
  }

  const resetInterval = () => {
    stopInterval()
    startInterval()
  }

  return {
    _latestStatus,
    resetInterval,
    startInterval,
    stopInterval,
  }
}
