import React from 'react'
import styled from 'styled-components'

function BasicLayout({ title, renderActionBar, children, className, style = { padding: 24 } }) {
  return (
    <Layout>
      {title && <h2>{title}</h2>}
      {renderActionBar && <Card>{renderActionBar()}</Card>}
      <Content className={className} style={style}>
        {children}
      </Content>
    </Layout>
  )
}

export const Layout = styled.div`
  background: ${({ theme }) => theme.secondary};
  h2 {
    padding: 24px;
    height: 82px;
    font-size: 30px;
    color: #646464;
  }
`

export const Card = styled.div`
  position: relative;
  padding: 24px;
  background-color: ${({ theme }) => theme.actionBar.bg};
  // min-height: 96px;
  border-bottom: 1px solid ${({ theme }) => theme.actionBar.borderBottomLine};
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
`

export default React.memo(BasicLayout)
