import React from 'react'
import { Table } from 'antd'

// import history from '@/utils/history'
// import { USERS } from 'constants/routes'
import { StateHeader } from './style'

function State({ data }) {
  const [collapse, setCollapse] = React.useState(false)

  const handleCollapse = (e) => {
    setCollapse((c) => !c)
  }

  const handleClickCount = (e) => {
    e.stopPropagation()
    // history.push(`${USERS}/${data.id}`) //TODO:先傳 id 之後修改
  }

  const expandedRowRender = (record) => {
    const columns = [{ dataIndex: 'name' }, { dataIndex: 'total', align: 'right' }]

    return (
      <Table
        rowKey="id"
        columns={columns}
        showHeader={false}
        dataSource={record.childs}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'total',
      align: 'right',
    },
  ]

  return (
    <>
      <StateHeader style={{ cursor: 'pointer' }} onClick={handleCollapse}>
        <span>{data.name}</span>
        <span onClick={handleClickCount}>{data.total}</span>
      </StateHeader>
      {collapse && (
        <Table
          rowKey="id"
          expandedRowRender={expandedRowRender}
          showHeader={false}
          columns={columns}
          dataSource={data.childs}
          pagination={false}
        />
      )}
    </>
  )
}

export default State
