import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Form, Table, Modal, Row, Col, DatePicker, Button } from 'antd'
import useSWR from '@/hooks/useSWR'
import styled from 'styled-components'
import { pathOr } from 'ramda'

function PassengerHealthModal({ passenger, onCancel, form, ...props }) {
  const { getFieldDecorator } = form
  const [page, setPage] = React.useState(0)
  const [range, setRange] = React.useState([moment().subtract(7, 'd'), moment()])

  const { data, loading, refetch } = useSWR(`/v1/passengerHealthData/list`, {
    variables: {
      passengerSeq: passenger.id,
      pageNum: page,
      pageSize: 10,
      start: moment(range[0]).startOf('day').valueOf(),
      end: moment(range[1]).endOf('day').valueOf(),
    },
  })

  const handleSearch = (e) => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        refetch({
          pageNum: 0,
          start: moment(range[0]).startOf('day').valueOf(),
          end: moment(range[1]).endOf('day').valueOf(),
        })
        setPage(0)
      }
    })
  }

  const handleTableChange = ({ current }) => {
    refetch({
      pageNum: current - 1,
    })
    setPage(current)
  }

  const { t } = useTranslation()
  const columns = [
    {
      title: t('all:time'),
      dataIndex: 'createAt',
      width: 180,
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Temperature',
      dataIndex: 'temperature',
      align: 'center',
      render: (v) => (v <= 0 ? '' : v),
    },
    {
      title: 'Heart rate',
      dataIndex: 'heartrate',
      align: 'center',
    },
    {
      title: 'Blood pressure',
      align: 'center',
      render: (v, row) => `${row.sbp}/${row.dbp}`,
    },
    {
      title: 'Oxygen',
      dataIndex: 'oxigen',
      align: 'center',
    },
  ]

  return (
    <StyleModal
      {...props}
      visible
      title={passenger.name}
      // title={`${passenger.major}(${passenger.major})(${passenger.minor})`}
      width={750}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary' }}>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Form onSubmit={handleSearch}>
            {getFieldDecorator('range', {
              initialValue: [moment(range[0]), moment(range[1])],
            })(
              <DatePicker.RangePicker
                style={{ marginRight: 10 }}
                onChange={setRange}
                disabledDate={(current) => current && current > moment().endOf('day')}
              />,
            )}
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={pathOr([], ['data'], data)}
            pagination={{ pageSize: 10, total: pathOr([], ['total'], data), current: page }}
            scroll={{ y: 500 }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)`
  .ant-modal-footer button + button {
    display: none;
  }
`
export default Form.create()(PassengerHealthModal)
