import React from 'react'
import { Table, Button } from 'antd'
import styled from 'styled-components'
import { compose, pathOr } from 'ramda'
import { connect, useSelector } from 'react-redux'
import useSWR from '@/hooks/useSWR'
import useVisible from '@/hooks/useVisible'
import useAuth from 'hooks/useAuth'
import { Flex } from 'components/common/Flex'
import BasicLayout from 'components/BasicLayout'
import AllAreaSearchForm from 'components/AllAreaSearchForm'
import { ROLE_NAME } from 'constants/roles'
import { addAccount, updateAccount } from 'reducers/account'
import AccountModal from './AccountModal'
let i = 0

function Accounts({ addAccount, updateAccount }) {
  const { byId } = useSelector((state) => state.app.allArea)
  const [page, setPage] = React.useState(0)
  const { user } = useAuth()

  const { data: accountData, loading, refetch } = useSWR('/v1/member/fullList', {
    lazy: true,
    variables: {
      pageSize: 10,
    },
  })

  const [member, setMember] = React.useState()

  const [editModalVisible, handleEditModalVisible] = useVisible()

  const handleSearch = (values) => {
    refetch({
      pageNum: 0,
      memberId: values.memberId,
      districtSetSeq: values.area || values.dist || values.state,
    })
    setPage(0)
  }

  const handleTableChange = ({ current }) => {
    refetch({ pageNum: current - 1 })
    setPage(current - 1)
  }

  const _handleEditModalVisible = (val) => () => {
    setMember(val)
    handleEditModalVisible()
  }

  const handleSubmit = (values) => {
    const handler = values.id ? updateAccount : addAccount
    handler(values)
    refetch({ refetch: i++ })
  }

  const columns = [
    {
      title: 'Account',
      dataIndex: 'memberId',
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'memberRole',
      render: (v) => ROLE_NAME[v],
    },
    {
      title: 'State',
      align: 'center',
      dataIndex: 'state',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 1) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 2) {
          const stateSeq = pathOr(undefined, ['parentSeq'], area)
          const state = pathOr(undefined, [stateSeq], byId)
          return pathOr(undefined, ['name'], state)
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)

        const stateSeq = pathOr(undefined, ['parentSeq'], country)
        const state = pathOr(undefined, [stateSeq], byId)
        const stateName = pathOr(undefined, ['name'], state)

        return stateName
      },
    },
    {
      title: 'District',
      align: 'center',
      dataIndex: 'district',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 2) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 1) {
          return 'ALL'
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)
        const countryName = pathOr(undefined, ['name'], country)

        return countryName
      },
    },
    {
      title: 'Area',
      align: 'center',
      dataIndex: 'districtSetSeq',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)
        if (level === 1 || level === 2) {
          return 'ALL'
        }

        return pathOr(undefined, ['name'], area)
      },
    },
    {
      align: 'center',
      key: 'action',
      render: (v, r) => (
        <StyledActionButton
          type="primary"
          size="small"
          icon="edit"
          onClick={_handleEditModalVisible(r)}
        />
      ),
    },
  ]

  return (
    <>
      {editModalVisible && (
        <AccountModal member={member} onOk={handleSubmit} onCancel={handleEditModalVisible} />
      )}
      <BasicLayout
        title="Account management"
        renderActionBar={() => (
          <AllAreaSearchForm
            labelWidth={90}
            label="Account"
            searchFieldName="memberId"
            onSearch={handleSearch}
          />
        )}>
        <Block>
          <Flex className="mb-10" style={{ justifyContent: 'flex-end' }}>
            {user.isAdmin && (
              <Button type="primary" onClick={_handleEditModalVisible()}>
                Add Account
              </Button>
            )}
          </Flex>
          <Table
            rowKey="id"
            size="small"
            bordered
            loading={loading}
            columns={columns}
            dataSource={accountData?.data}
            pagination={{ current: page + 1, pageSize: 10, total: accountData?.total }}
            onChange={handleTableChange}
          />
        </Block>
      </BasicLayout>
    </>
  )
}

const StyledActionButton = styled(Button)`
  border: 1px solid #50a1da;
  background: ${({ theme }) => theme.lightblue};
  i {
    vertical-align: text-top;
  }
`

export const Block = styled.div`
  background-color: #f9f9f9;
  padding: 20px 20px 1px;
`

const mapDispatchToProps = {
  addAccount,
  updateAccount,
}

export default compose(connect(null, mapDispatchToProps))(Accounts)
