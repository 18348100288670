import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { isNil, compose, pathOr } from 'ramda'
import { Drawer, Row, Col, Button, Select, DatePicker, Form } from 'antd'
import { withI18next } from '@/locales/withI18next'
import useViewport from '@/hooks/useViewport'
import { listCardActivities, clearCardActivities } from 'reducers/cards'

const formItemLayout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 12,
  },
}

const FormItem = Form.Item
const DrawerWithoutMask = styled(Drawer)`
  width: 0 !important;

  .ant-drawer-header {
    background-color: #eee;
  }

  .ant-drawer-content {
    /* padding-top: 70px; */
    background-color: #eee;
  }
`
const HoverableRow = styled(Row)`
  height: 30px;
  &:hover {
    background-color: #79abe5;
    cursor: pointer;
  }
  &.isFocused {
    background-color: #79abe5;
  }
`

const mapStateToProps = (state) => ({
  cards: state.cards,
})

const mapDispatchToProps = {
  listCardActivities,
  clearCardActivities,
}

function DetailSearchDrawer(props) {
  const [intervalMin, setIntervalMin] = useState(1)
  const [startTime, setStartTime] = useState(moment().subtract(1, 'hours').valueOf())
  const [endTime, setEndTime] = useState(moment().valueOf())
  const [defaultTimePeriod] = useState(props.defaultStartTime ? false : true)

  const { height } = useViewport()

  const {
    card,
    currentCardId,
    onSearchCompleted,
    cards,
    cards: { activities, isLoading },
    focusedMarker,
    clearCardActivities,
    onFocusMarker,
    clearFocusMarker,
    listCardActivities,
    form: { validateFields, getFieldDecorator },
    onClose,
    t,
    stopRefetch,
  } = props

  const handlePosition = (idx, pos) => {
    onFocusMarker({
      idx,
      card: pos,
      mapCenter: {
        lat: pos.latitude,
        lng: pos.longitude,
      },
    })
  }

  const handleSearch = () => {
    validateFields((err, values) => {
      if (err) {
        return
      }
      clearCardActivities()
      clearFocusMarker()
      setStartTime(moment(values.startTime).valueOf())
      setEndTime(moment(values.endTime).valueOf())
      setIntervalMin(values.intervalMin)
      listCardActivities({
        body: {
          id: currentCardId,
          startTime: moment(values.startTime).valueOf(),
          endTime: moment(values.endTime).valueOf(),
          intervalMin: values.intervalMin,
        },
        page: 0,
        size: 100,
        defaultTimePeriod: false,
        onCompleted: onSearchCompleted,
      })
      stopRefetch()
    })
  }

  useEffect(() => {
    clearCardActivities()
    listCardActivities({
      defaultTimePeriod,
      body: {
        id: currentCardId,
        startTime,
        endTime,
        intervalMin,
      },
      page: 0,
      size: 100,
      onCompleted: onSearchCompleted,
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!isLoading && activities.page < activities.totalPages - 1) {
      listCardActivities({
        body: {
          id: currentCardId,
          startTime,
          endTime,
          intervalMin,
        },
        page: activities.page + 1,
        size: 100,
        defaultTimePeriod: startTime ? false : true,
        onCompleted: onSearchCompleted,
      })
    }
  }, [cards.isLoading]) // eslint-disable-line

  useEffect(() => {
    if (!isNil(focusedMarker) && focusedMarker?.id) {
      const timeLog = window.document.getElementById(`time-log-${focusedMarker.id}`)
      timeLog?.scrollIntoView()
    }
  }, [focusedMarker])

  return (
    <DrawerWithoutMask
      width={376}
      title={
        <>
          {card?.cardName}'s {t('search trace')}
        </>
      }
      placement="right"
      mask={false}
      visible={true}
      getContainer={false}
      style={{ position: 'absolute' }}
      onClose={onClose}>
      <FormItem labelAlign="left" label={t('datetime-from')} {...formItemLayout}>
        {getFieldDecorator('startTime', {
          initialValue: moment(startTime),
          rules: [{ required: true, message: 'Required' }],
        })(<DatePicker style={{ width: 210 }} showTime format="YYYY-MM-DD HH:mm:ss" />)}
      </FormItem>
      <FormItem labelAlign="left" label={t('datetime-to')} {...formItemLayout}>
        {getFieldDecorator('endTime', {
          initialValue: moment(endTime),
          rules: [{ required: true, message: 'Required' }],
        })(<DatePicker style={{ width: 210 }} showTime format="YYYY-MM-DD HH:mm:ss" />)}
      </FormItem>
      <Row type="flex" align="middle" gutter={8}>
        <Col span={5}>{t('datetime-per')}</Col>
        <Col span={9}>
          <FormItem style={{ marginBottom: 0 }}>
            {getFieldDecorator('intervalMin', {
              initialValue: intervalMin,
            })(
              <Select style={{ width: '100%' }}>
                <Select.Option value="1">1 min</Select.Option>
                <Select.Option value="5">5 min</Select.Option>
                <Select.Option value="10">10 min</Select.Option>
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={9}>{t('show the data')}</Col>
      </Row>
      <Button className="w-full mt-12" type="primary" onClick={handleSearch}>
        {t('start')}
      </Button>
      <Row className="mt-36" style={{ borderBottom: '1px solid #1e3954' }}>
        <Col span={8}>#</Col>
        <Col span={16}>Time</Col>
      </Row>
      <div className="mt-12 overflow-y-scroll" css={{ height: height - 459 }}>
        {pathOr([], ['content', 0, 'cardPositions'], activities).map((pos, idx) => {
          // todo: hover style & onclick function
          return (
            <HoverableRow
              key={idx}
              id={`time-log-${pos.id}`}
              className={focusedMarker?.id === pos.id ? 'isFocused' : ''}
              onClick={() => handlePosition(idx, pos)}>
              <Col span={8}>{idx + 1}</Col>
              <Col span={16}>{moment(pos.createAt).format('YYYY.MM.DD HH:mm:ss')}</Col>
            </HoverableRow>
          )
        })}
      </div>
    </DrawerWithoutMask>
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withI18next(['all']), Form.create())(DetailSearchDrawer)
