import React, { useState } from 'react'
import { Row, Col, Tabs } from 'antd'
import styled from 'styled-components'
import { includes } from 'ramda'
import { useSelector } from 'react-redux'

import BasicLayout from 'components/BasicLayout'
import { ROLES } from 'constants/roles'

import AlertReport from './AlertReport'
import ActionReport from './ActionReport'
import BindingReport from './BindingReport'

const { TabPane } = Tabs

export default function Report() {
  const { memberRole } = useSelector((state) => state.users.currentUser)
  const [isBindingRpt, setIsBindingRpt] = useState(false)

  React.useEffect(() => {
    if (!memberRole) {
      return
    }

    setIsBindingRpt(includes(memberRole, [ROLES.AIRPORT_STAFF, ROLES.HOSPITAL_STAFF]))
  }, [memberRole])

  return (
    <BasicLayout title="Report">
      <Row>
        <Col className="mt-3">
          {isBindingRpt ? (
            <StyledTabs type="card">
              <TabPane tab="Binding" key="3" style={{ padding: '30px 24px' }}>
                <BindingReport />
              </TabPane>
            </StyledTabs>
          ) : (
            <StyledTabs type="card">
              <TabPane tab="Alert" key="1" style={{ padding: '30px 24px' }}>
                <AlertReport />
              </TabPane>
              <TabPane tab="Action" key="2" style={{ padding: '30px 24px' }}>
                <ActionReport />
              </TabPane>
            </StyledTabs>
          )}
        </Col>
      </Row>
    </BasicLayout>
  )
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    min-width: 180px;
    text-align: center;
  }

  .ant-tabs-nav {
    margin-left: 18px;
  }

  .ant-tabs-nav.ant-tabs-tab-active {
    font-weight: normal;
  }
`
