import React, { useEffect } from 'react'
import { Row, Col, Tabs } from 'antd'
import styled from 'styled-components'
import BasicLayout from 'components/BasicLayout'
import Tab1 from './Tab1'
import Tab2 from './Tab2'
import { getFenceOffSettingAPI, setFenceOffSettingAPI } from 'apis/'
import { message } from 'antd'

const { TabPane } = Tabs

export default function Setting() {
  const [fenceOffSetting, setFenceOffSetting] = React.useState()

  useEffect(() => {
    getFenceOffSettingAPI().subscribe(
      (res) => setFenceOffSetting(res.data),
      (error) => {},
    )
  }, [])

  const updateFenceOffSetting = (body) => {
    setFenceOffSettingAPI(body).subscribe(
      (res) => message.success('Successfully updated.'),
      (error) => message.error('Update failed.'),
    )
  }

  return (
    <BasicLayout title="Setting" className="h-auto">
      <Row>
        <Col className="mt-3">
          <StyledTabs type="card">
            <TabPane tab="Time of fence off" key="1">
              <Tab1 data={fenceOffSetting} update={updateFenceOffSetting} />
            </TabPane>
            <TabPane tab="COVID-19 test clinic" key="2">
              <Tab2 />
            </TabPane>
          </StyledTabs>
        </Col>
      </Row>
    </BasicLayout>
  )
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-left: 18px;
  }

  .ant-tabs-nav.ant-tabs-tab-active {
    font-weight: normal;
  }
`
