import React from 'react'
import { is, pathOr } from 'ramda'
import _useSWR from 'swr'
import toQueryString from '@/utils/toQueryString'
import { request } from '@/services/request'

function format(url, params = {}) {
  const regex = /(\{.+?\})/gi
  const _params = { ...params }

  const _url = url.replace(regex, (v) => {
    const replacable = v[0] === '{'

    if (!replacable) {
      return v
    }

    const propName = v.slice(1, -1)
    const replacedValue = _params[propName]

    _params[propName] = undefined

    return replacedValue
  })

  return { fullUrl: _url, params: _params }
}

export default function useSWR(
  key,
  { variables, onCompleted, onError, lazy = false, ...config } = {},
  fn,
) {
  const [query, setQuery] = React.useState(variables)

  const [called, setIsCalled] = React.useState(!lazy)

  const val = React.useRef()

  const controller = new AbortController()
  React.useEffect(() => {
    return function cleanup() {
      if (controller) {
        controller.abort()
      }
    }
  }, []) // eslint-disable-line

  const _fn = fn ? fn : (url) => request(url, { throwable: true, signal: controller.signal })

  let _key = key
  if (is(String, key)) {
    const { fullUrl, params } = format(key, query)
    _key = `${fullUrl}?${toQueryString(params)}`
  }
  if (!called) {
    _key = null
  }

  const { data, ...rest } = _useSWR(_key, _fn, {
    ...config,
    dedupingInterval: 0,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    suspense: false,
    onSuccess: (data) => {
      if (onCompleted) {
        onCompleted(data)
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error)
      }
    },
  })

  const refetch = (variables = {}) => {
    setQuery((prev) => ({ ...prev, ...pathOr(variables, ['params'], variables) }))
    setIsCalled(true)

    return Promise.resolve({})
  }

  if (data !== undefined) {
    val.current = data
  }

  return {
    ...rest,
    loading: rest.isValidating,
    data: val.current,
    refetch,
    called,
  }
}
