import React from 'react'
import Cookies from 'js-cookie'
import { message } from 'antd'
import {
  merge,
  includes,
  isNil,
  always,
  T,
  path,
  ifElse,
  invoker,
  identity,
  mergeDeepRight,
  is,
  allPass,
  and,
  not,
  equals,
  __,
} from 'ramda'
import toQueryString from '@/utils/toQueryString'
// import message, { error } from '@/utils/message'
import { API_ROOT } from '@/constants/env'
import camelcase from '@/utils/camelcase'
const { compose, cond } = require('ramda')

/**
 * 替換Url中的變數，例如/v1/api/user/{id} 轉換成 /v1/api/user/5
 * @param url API Url
 * @param body Query Parameters
 */
export function format(url, params = {}) {
  const regex = /(\{.+?\})/gi
  const _params = { ...params }

  const _url = url.replace(regex, (v) => {
    const replacable = v[0] === '{'

    if (!replacable) {
      return v
    }

    const propName = v.slice(1, -1)
    const replacedValue = _params[propName]

    _params[propName] = undefined

    return replacedValue
  })

  return { fullUrl: _url, params: _params }
}

export function useMeta() {
  const [loading, setIsLoading] = React.useState(false)

  const withMeta = React.useCallback((handler: any) => {
    setIsLoading(true)

    return handler()
      .then((response: any) => response)
      .finally(() => setIsLoading(false))
  }, [])

  return [loading, withMeta]
}

export function useRequest(url, { lazy = true, variables = {}, ..._options } = {}) {
  const called = React.useRef(false)
  const controllerRef = React.useRef(null)

  const [data, setData] = React.useState()
  const [error, setError] = React.useState()

  const [loading, withMeta] = useMeta()

  React.useEffect(() => {
    return function cleanup() {
      if (controllerRef.current && loading) {
        controllerRef.current.abort()
      }
    }
  }, [loading])

  const _refetch = (data = {}) => {
    let { fullUrl, params } = format(url, mergeDeepRight(variables, data))

    if (_options.method === 'GET') {
      fullUrl = `${fullUrl}?${toQueryString(params)}`
    }

    if (controllerRef.current) {
      controllerRef.current.abort()
    }

    controllerRef.current = new AbortController()

    return withMeta(async () => {
      const response = await request(fullUrl, {
        ..._options,
        ...(_options.method !== 'GET' ? { body: data } : {}),
        signal: controllerRef.current?.signal,
        onCompleted: (response) => {
          if (_options.onCompleted) {
            _options.onCompleted({ data, response })
          }

          setData(response)
        },
        onError: (error) => {
          if (_options.onError) {
            _options.onError(error)
          }

          setError(error)
        },
      })

      controllerRef.current = null

      return response
    })
  }

  React.useEffect(() => {
    if (!lazy && !called.current) {
      _refetch()

      called.current = true
    }
  }, [lazy, called]) // eslint-disable-line

  return {
    loading,
    mutate: _refetch,
    data,
    error,
    called,
  }
}

const contentTypeIs = (s) => {
  return compose(
    ifElse(isNil, identity, (c = '') => c.includes(s)),
    invoker(1, 'get')('content-type'),
    path(['headers']),
  )
}

const convertObjectKeys = (object, keypath = []) => {
  if (!object || typeof object !== 'object') {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((o, index) => convertObjectKeys(o, [...keypath, String(index)]))
  }

  return Object.keys(object).reduce((acc, key) => {
    let value = object[key]

    const nestedKeyPath = [...keypath, key]

    acc[camelcase(key)] = convertObjectKeys(value, nestedKeyPath)
    return acc
  }, {})
}

export function request(
  url,
  {
    body,
    headers,
    msg = true,
    errorMsg = true,
    withToken = true,
    throwable = false,
    successMessage,
    onCompleted,
    onError,
    ...options
  } = {},
) {
  const fullUrl = cond([
    [includes('http'), always(url)],
    [T, always(API_ROOT + url)],
  ])(url)

  return fetch(fullUrl, {
    ...options,
    body: body instanceof FormData ? body : JSON.stringify(body),
    headers: merge(
      {
        ...(withToken
          ? {
              Authorization: `Bearer ${Cookies.get('_dplusToken')}`,
            }
          : {}),
      },
      headers ? { ...headers } : { 'Content-Type': 'application/json', Accept: 'application/json' },
    ),
  })
    .then(async (response) => {
      if (
        compose(
          and(msg),
          allPass([compose(not, isNil), compose(not, equals(__, 'GET'))]),
          path(['method']),
        )(options)
      ) {
        message({
          content: successMessage || '資料已更新成功',
          type: 'success',
        })
      }

      let result: any

      if (response.status === 404) {
        return Promise.reject(response.statusText)
      }

      if (contentTypeIs('application/json')(response)) {
        result = await response.json()

        if (!response.ok) {
          return Promise.reject(result)
        }

        result = convertObjectKeys(result)
      }

      if (contentTypeIs('sheet')(response)) {
        result = await response.blob()
      }

      if (isNil(result)) {
        result = await response.text()
      }

      if (onCompleted) {
        onCompleted(result)
      }

      return result
    })
    .catch((err) => {
      if (errorMsg) {
        const msg = err?.message || err?.error?.message
        if (msg !== 'The user aborted a request.') {
          message.error(msg || err)
        }
      }

      if (onError) {
        onError(err)
      }

      if (throwable) {
        throw new Error(err.message || is(String, err) ? err : 'Server Error')
      }

      return err
    })
}

export default request
