import React from 'react'
import moment from 'moment'
// import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import styled from 'styled-components'
import { Drawer, List, Row, Col, Icon, Button } from 'antd'
import { filter, isNil } from 'ramda'
import { withI18next } from '@/locales/withI18next'
import useViewport from '@/hooks/useViewport'

const mappedStates = createSelector(
  (state) => state.guardAreas,
  (guardAreas) => ({
    latestStatuses: filter((x) => !isNil(x.notifySeq))(guardAreas.latestStatuses.cards),
    loading: guardAreas.isLoadingNotify,
  }),
)

function ViolationAlertsDrawer({
  t,
  onClick,
  onClose,
  tReady,
  onAutoSolvedAlertVisible,
  ...props
}) {
  const { latestStatuses } = useSelector(mappedStates)

  const { height } = useViewport()

  const handleClick = (card) => () => {
    if (onClick) {
      onClick({ card, showActions: false })
    }
  }

  return (
    <DrawerWithoutMask
      {...props}
      width={376}
      title={<div className="text-center">{t('violation alerts')}</div>}
      placement="right"
      mask={false}
      getContainer={false}
      style={{ position: 'absolute' }}
      bodyStyle={{ paddingTop: 12 }}
      onClose={onClose}>
      <List
        split={false}
        itemLayout="horizontal"
        className="overflow-y-scroll"
        style={{ height: height - 175 }}
        dataSource={latestStatuses}
        // loading={loading}
        renderItem={(card) => (
          <ListItem key={card.id}>
            <Row>
              <Col>
                <div
                  className="flex items-center cursor-pointer border-transparent border-b-2 hover:border-blue-300"
                  onClick={handleClick(card)}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(`violation-alert-${card.notifyState}`, {
                        name: card.cardName,
                        position: card.notifyGuardareaName || '',
                        time: moment(card.notifyCreateTime).format('YYYY.MM.DD HH:mm:ss'),
                      }),
                    }}
                  />
                  <span>
                    <Icon type="right" className="relative -top-3 ml-2" style={{ fontSize: 12 }} />
                  </span>
                </div>
              </Col>
            </Row>
          </ListItem>
        )}
      />
      <div className="flex justify-center">
        <Button type="link" className="absolute bottom-10" onClick={onAutoSolvedAlertVisible}>
          Auto Solved Alert History
        </Button>
      </div>
    </DrawerWithoutMask>
  )
}

const DrawerWithoutMask = styled(Drawer)`
  width: 0 !important;

  .ant-drawer-header {
    background-color: #eee;
  }

  .ant-drawer-content {
    background-color: #eee;
  }

  .ant-drawer-close > i {
    position: relative;
    top: -3px;
  }

  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0,0,0,.08), -9px 0 28px 0 rgba(0,0,0,.05), -12px 0 48px 16px rgba(0,0,0,.03);
  }

  span.lightblue-font {
    font-size: 14px;
    font-weight: 500;
    color: #79abe5;
  }

  span.lightred-font {
    font-size: 14px;
    font-weight: 500;
    color: #e57992;
  }
}
`

const ListItem = styled(List.Item)`
  padding-top: 18px !important;
  padding-bottom: 0px !important;
`

export default withI18next(['all'])(ViolationAlertsDrawer)
