import React from 'react'
import { Row } from 'antd'
import { pathOr } from 'ramda'
import { hasPath } from '@/utils/webHelper'
import { API_ROOT } from 'constants/endpoint'

export default function CardTraceInfoWindow({ data }) {
  return (
    <div className="w-244">
      <Row type="flex" align="middle" className="mt-12">
        <img
          src={
            hasPath(['cardOwner', 'avatar'])(data)
              ? `${API_ROOT}/v1/file/${data.cardOwner.avatar}`
              : '/img/avatar-pic.png'
          }
          className="w-67 h-67 mr-12 rounded-full"
          alt="avatar"
        />
        <div>
          <h3>{pathOr(data.cardName, ['cardOwner', 'name'], data)}</h3>
          {data?.latitude && data?.longitude && (
            <h3>
              {data.latitude}, {data.longitude}
            </h3>
          )}
        </div>
      </Row>
    </div>
  )
}
