import React from 'react'
import { Form, Row, Col, Input, Select, Button, Radio } from 'antd'
import { pathOr, filter, propEq, isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Flex } from 'components/common/Flex'
import useAuth from 'hooks/useAuth'
import { GUARD_AREA } from 'constants/permissions'
import { NOTIFY_STATE_NAME } from 'constants/notifyState'

const Option = Select.Option

function SearchForm({ onSearch, form }) {
  const { states, byId, isLoading } = useSelector((state) => state.app.allArea)
  const { user, statuses } = useAuth()

  const { getFieldDecorator, validateFields, setFieldsValue, getFieldsValue } = form

  const [currentDist, setCurrentDist] = React.useState([])
  const [currentArea, setCurrentArea] = React.useState([])

  const options = [
    { value: '', name: 'All' },
    ...statuses.map((x) => ({
      value: x,
      name: NOTIFY_STATE_NAME[x],
    })),
  ]

  React.useEffect(() => {
    if (!states || !states.length) {
      return
    }

    if (states.length === 1) {
      const userState = states[0]?.id
      setFieldsValue({ state: userState })
      const districts = pathOr([], [userState, 'childs'], byId)
      if (districts.length === 1) {
        setCurrentDist(districts)
        setFieldsValue({ dist: districts[0]?.id })
        const areas = pathOr([], ['childs'], districts[0])
        if (areas.length === 1) {
          setCurrentArea(areas)
          setFieldsValue({ area: areas[0]?.id })
        }
      }
    }
    onSearch(getFieldsValue())
  }, [states]) // eslint-disable-line

  const handleStateChange = (value) => {
    if (!value) {
      setFieldsValue({ dist: '', area: '' })
      setCurrentDist([])
      setCurrentArea([])
      return
    }

    const districts = pathOr([], [value, 'childs'], byId)
    setCurrentDist(districts)

    setFieldsValue({ dist: '', area: '' })
    setCurrentArea([])
  }

  const handleDistChange = (value) => {
    if (!value) {
      setFieldsValue({ area: '' })
      setCurrentArea([])
      return
    }

    const area = filter(propEq('id', value), currentDist)[0]
    const areas = pathOr([], ['childs'], area)

    setCurrentArea(areas)
    setFieldsValue({ area: '' })
  }

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return
      }
      console.log(values)
      onSearch(values)
    })
  }

  return (
    <Form style={{ clear: 'both' }}>
      <Row gutter={[16, 32]}>
        <Col xl={3}>
          <Flex middle style={{ minHeight: 32 }}>
            <Label htmlFor="isBinding" style={{ textAlign: 'left', width: 60 }}>
              Binding
            </Label>
            {getFieldDecorator('isBinding', { initialValue: true })(
              <Radio.Group>
                <Radio value={true}>YES</Radio>
                <Radio value={false}>NO</Radio>
              </Radio.Group>,
            )}
          </Flex>
        </Col>
        <Col xl={4}>
          <Flex middle>
            <Label htmlFor="keyword">Keyword</Label>
            {getFieldDecorator('keyword', {
              initialValue: '',
            })(<Input allowClear />)}
          </Flex>
        </Col>
        <Col xl={3}>
          <Flex middle>
            <Label htmlFor="status">Status</Label>
            {getFieldDecorator('status', {
              initialValue: '',
            })(
              <Select>
                {options.map((i) => (
                  <Select.Option key={i.value} value={i.value}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Flex>
        </Col>
        <Col xl={3}>
          <Flex middle>
            <Label htmlFor="state">State</Label>
            {getFieldDecorator('state', {
              initialValue: '',
            })(
              <Select
                loading={isLoading}
                onChange={handleStateChange}
                disabled={GUARD_AREA[user.memberRole].state.disabled}>
                {states && states.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {states &&
                  states.map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
              </Select>,
            )}
          </Flex>
        </Col>
        <Col xl={4}>
          <Flex middle>
            <Label htmlFor="dist">District</Label>
            {getFieldDecorator('dist', {
              initialValue: '',
            })(
              <Select
                onChange={handleDistChange}
                disabled={GUARD_AREA[user.memberRole].dist.disabled ? true : isEmpty(currentDist)}>
                {currentDist && currentDist.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {currentDist.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Flex>
        </Col>
        <Col xl={3}>
          <Flex middle>
            <Label htmlFor="area">Area</Label>
            {getFieldDecorator('area', {
              initialValue: '',
            })(
              <Select
                disabled={GUARD_AREA[user.memberRole].area.disabled ? true : isEmpty(currentArea)}>
                {currentArea && currentArea.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {currentArea.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Flex>
        </Col>
        <Col xl={2}>
          <Button type="primary" className="w-96" onClick={handleSubmit}>
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const Label = styled.label.attrs({ className: 'label' })`
  width: 80px;
  text-align: center;
`

export default Form.create()(SearchForm)
