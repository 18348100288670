import React from 'react'
import { Row, Col, Icon } from 'antd'

export default function Loading({ className, style }) {
  return (
    <Row type="flex" justify="center" className={className} style={style}>
      <Col>
        <Icon type="loading-3-quarters" spin />
      </Col>
    </Row>
  )
}
