export const handleTotalRegisterPie = (data) => ({
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Total Register',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '{point.name}: {point.percent:.1f}%',
      },
    },
  },
  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
  },
  series: [
    {
      name: 'Total Register',
      colorByPoint: true,
      data: data,
    },
  ],
  credits: {
    enabled: false,
  },
})

export const handleQuarantineStatusPie = (data) => ({
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Quarantine Status',
  },
  plotOptions: {
    series: {
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '{point.name}: <span style="color:red;">{point.y}</span>',
      },
      events: {
        click: function (event) {
          console.log(event.point.name)
          window.location.hash = `#${event.point.name}`
        },
      },
    },
  },
  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
  },
  series: [
    {
      name: 'Quarantine Status',
      colorByPoint: true,
      data: data,
    },
  ],
  credits: {
    enabled: false,
  },
})

export const handleQuarantineStatusSpline = (categories, series) => ({
  chart: {
    type: 'spline',
  },
  title: {
    text: 'Quarantine Status',
  },
  xAxis: {
    categories: categories,
  },
  yAxis: {
    title: {
      text: 'Number of people',
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  plotOptions: {
    spline: {
      marker: {
        radius: 4,
        lineColor: '#666666',
        lineWidth: 1,
      },
    },
  },
  series: series,
  credits: {
    enabled: false,
  },
})
