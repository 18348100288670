import React, { useState } from 'react'
import { Table, Button, Badge } from 'antd'
import styled from 'styled-components'
import { pathOr, find, propEq, isEmpty, isNil } from 'ramda'

import { useCountryStates } from '@/data/countryStates'
import useVisible from '@/hooks/useVisible'
import useAuth from 'hooks/useAuth'
import useSWR from '@/hooks/useSWR'
import { NOTIFY_STATE_NAME, NOTIFY_STATE_COLOR } from 'constants/notifyState'
import BasicLayout from 'components/BasicLayout'
import SearchForm from './SearchForm'
import PassengerHealthModal from 'components/PassengerHealthModal'
import Board from './Board'
import PassengerDetailModal from './PassengerDetailModal'

function Users(props) {
  const { history } = props
  const pageSize = 10

  const { user, statuses } = useAuth()
  const { countryStates: countryData } = useCountryStates()
  const [detailModalVisible, _handleDetailModalVisible] = useVisible()
  const [healthModalVisible, _handleHealthModalVisible] = useVisible()

  const [page, setPage] = useState(0)
  const [passenger, setPassenger] = useState(null)
  const [isBinding, setIsBinding] = useState(true)

  const { data, loading, refetch } = useSWR(`/v1/passenger/list/${page}/${pageSize}`, {
    lazy: true,
  })

  const handleDetailModalVisible = (item) => () => {
    setPassenger(item)
    _handleDetailModalVisible()
  }

  const handleHealthModalVisible = (item) => () => {
    setPassenger(item)
    _handleHealthModalVisible()
  }

  const handleTableChange = ({ current }) => {
    setPage(current - 1)
  }

  const handleSearch = (values) => {
    setPage(0)
    setIsBinding(values.isBinding)
    refetch({
      keyword: values.keyword,
      notifyTypes: !isEmpty(values.status) ? values.status : statuses,
      level3seq: values.area || values.dist || values.state,
      isBinding: values.isBinding,
    })
  }

  const columns = [
    {
      title: 'IC/Passport',
      align: 'center',
      dataIndex: 'identityId',
    },
    {
      title: 'Name',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: 'Phone Number',
      align: 'center',
      dataIndex: 'contactMobile',
    },
    {
      title: 'State',
      align: 'center',
      dataIndex: 'level1seq',
      render: (text, row) => {
        if (countryData) {
          const state = !isNil(row.level1Seq)
            ? find(propEq('id', row.level1Seq))(countryData)
            : null
          return state ? state.displayName : ''
        }
      },
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'notifyState',
      render: (text) => {
        const status = NOTIFY_STATE_NAME[text]
        const color = NOTIFY_STATE_COLOR[text]
        return (
          <>
            <StyledBadge color={color} />
            <span>{status}</span>
          </>
        )
      },
    },
    {
      title: 'Bracelet ID',
      align: 'center',
      dataIndex: 'major',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'ACTION',
      align: 'center',
      dataIndex: 'action',
      render: (text, row) => (
        <>
          <StyledActionButton
            type="primary"
            size="small"
            style={{ marginRight: 5 }}
            onClick={handleDetailModalVisible(row)}>
            Detail
          </StyledActionButton>
          {(user.isAdmin || user.isTechSupport || user.isMed) && (
            <StyledActionButton
              type="primary"
              size="small"
              icon="profile"
              style={{ marginRight: 5 }}
              onClick={handleHealthModalVisible(row)}
            />
          )}
          {isBinding && (
            <StyledActionButton
              type="primary"
              size="small"
              icon="pushpin"
              onClick={() => history.push(`/map?cardSeq=${row.cardSeq}`)}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <>
      {detailModalVisible && passenger && (
        <PassengerDetailModal passenger={passenger} onCancel={_handleDetailModalVisible} />
      )}
      {healthModalVisible && passenger && (
        <PassengerHealthModal passenger={passenger} onCancel={_handleHealthModalVisible} />
      )}
      <BasicLayout
        title={`User list & condition`}
        renderActionBar={() => (
          <>
            <Board />
            <SearchForm onSearch={handleSearch} />
          </>
        )}>
        <Table
          rowKey="id"
          size="small"
          bordered
          loading={loading}
          columns={columns}
          dataSource={pathOr([], ['data'], data)}
          pagination={{ pageSize: pageSize, total: data?.total, current: page + 1 }}
          onChange={handleTableChange}
        />
      </BasicLayout>
    </>
  )
}

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
`

const StyledActionButton = styled(Button)`
  border: 1px solid #50a1da;
  background: ${({ theme }) => theme.lightblue};
  i {
    vertical-align: text-top;
  }
`

export default Users
