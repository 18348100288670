import { pipe, omit } from 'ramda'
import { Modal } from 'antd'
import Cookies from 'js-cookie'
import { pluck, switchMap, tap, map, mergeMap } from 'rxjs/operators'
import { push } from 'connected-react-router'
import { createAction } from 'redux-actions'
import { handleActions } from 'redux-actions'
import { isNil, compose } from 'ramda'
import * as Types from 'actions/Types'
import { ROLES } from 'constants/roles'
import fake from 'fake/func'
import { arrayToObject } from 'utils/webHelper'
import { setHeader, loginAPI, logoutAPI } from '../apis'
import { ofType, catchRequestError } from 'utils/extendOperators'
const { error } = Modal

/**
 * Action Creators
 */

export const login = createAction(Types.AUTH_LOGIN.REQUEST)
export const logout = createAction(Types.AUTH_LOGOUT.REQUEST)
export const loginInit = createAction(Types.AUTH_INIT.REQUEST)
const loginInitSuccess = createAction(Types.AUTH_INIT.SUCCESS)
const loginInitFailure = createAction(Types.AUTH_INIT.FAILURE)
const loginSuccess = createAction(Types.AUTH_LOGIN.SUCCESS)
const loginFailure = createAction(Types.AUTH_LOGIN.FAILURE)
const logoutSuccess = createAction(Types.AUTH_LOGOUT.SUCCESS)
const logoutFailure = createAction(Types.AUTH_LOGOUT.FAILURE)

/**
 * Epic
 */

const composeUser = (data) => {
  // NOTE: for test
  // data.memberRole = 5
  return {
    ...data,
    isAdmin: data.memberRole === ROLES.Admin,
    isIPD: data.memberRole === ROLES.IPD,
    isMed: data.memberRole === ROLES.MEDICAL_STAFF,
    isIPK: data.memberRole === ROLES.IPK,
    isEnforcement: data.memberRole === ROLES.ENFORCEMENT_TEAM,
  }
}

export const loginEpic = (actions, { dispatch }) =>
  actions.pipe(
    ofType(Types.AUTH_LOGIN.REQUEST),
    pluck('payload'),
    switchMap((payload) =>
      loginAPI(payload).pipe(
        tap((data) => {
          Cookies.set('_dplusUser', compose(omit(['token']), composeUser)(data))
          Cookies.set('_dplusToken', data.token)
          Cookies.set('_dplusUserId', payload.loginId)
          Cookies.set('_dplus-dashboard_UserId', 'my_demo')
          Cookies.set('_dplus-dashboard_Token', data.token)
          Cookies.set(
            '_dplus-dashboard_Permissions',
            arrayToObject(fake.managerFunctions, 'function'),
          )

          if (payload.rememberMe) {
            Cookies.set('_dplusRememberUserId', payload.loginId)
          }
          if (!payload.rememberMe) {
            Cookies.remove('_dplusRememberUserId')
          }

          setHeader({
            Authorization: `Bearer ${data.token}`,
          })

          if (payload.url) {
            dispatch(push(payload.url))
          }
        }),
        mergeMap((data) => [
          loginSuccess({
            ...composeUser(data),
            loginId: payload.loginId,
            rememberUserId: payload.rememberMe ? payload.loginId : '',
          }),
        ]),
        catchRequestError((err) => {
          if (err.status === 500) {
            error({ content: err.response.message })
          }
          return loginFailure(err)
        }),
      ),
    ),
  )

const removeUser = () => {
  Cookies.remove('_dplusToken')
  Cookies.remove('_dplusUserId')
  Cookies.remove('_dplus-dashboard_UserId')
  Cookies.remove('_dplus-dashboard_Token')
  Cookies.remove('_dplus-dashboard_Permissions')
  setHeader()
}

export const logoutEpic = pipe(
  ofType(Types.AUTH_LOGOUT.REQUEST),
  switchMap((payload) =>
    logoutAPI(payload).pipe(
      tap(removeUser),
      map(logoutSuccess),
      catchRequestError((err) => {
        if (err.status === 500) {
          error({ content: err.response.message })
        }
        removeUser()
        return logoutFailure(err)
      }),
    ),
  ),
)

export const loginInitEpic = pipe(
  ofType(Types.AUTH_INIT.REQUEST),
  map(() => {
    const { _dplusToken, _dplusUserId, _dplusRememberUserId, _dplusUser } = Cookies.getJSON()
    return {
      user: _dplusUser,
      token: _dplusToken,
      loginId: _dplusUserId,
      rememberUserId: _dplusRememberUserId,
      isAuthenticated: !isNil(_dplusToken),
    }
  }),
  tap(({ token, isAuthenticated }) => {
    if (isAuthenticated) {
      setHeader({
        Authorization: `Bearer ${token}`,
      })
    }
  }),
  map((payload) => {
    return loginInitSuccess(payload)
  }),
  catchRequestError(loginInitFailure),
)

/**
 * Reducer
 */

const initialState = {
  user: null,
  token: '',
  id: '',
  rememberUserId: '',
  isAuthenticated: false,
  isLoginFailed: false,
  isLoading: false,
}

export default handleActions(
  {
    [Types.AUTH_LOGIN.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [Types.AUTH_LOGIN.SUCCESS]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        id: action.payload.loginId,
        token: action.payload.token,
        loginId: action.payload.loginId,
        rememberUserId: action.payload.rememberUserId,
        isAuthenticated: true,
        isLoginFailed: false,
      }
    },
    [Types.AUTH_LOGIN.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isLoginFailed: true,
    }),
    [Types.AUTH_INIT.SUCCESS]: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        id: action.payload.loginId,
        token: action.payload.token,
        loginId: action.payload.loginId,
        rememberUserId: action.payload.rememberUserId,
        isAuthenticated: action.payload.isAuthenticated,
      }
    },
    [Types.AUTH_LOGOUT.SUCCESS]: (state, action) => ({
      ...initialState,
      isAuthenticated: false,
      rememberUserId: state.rememberUserId,
    }),
    [Types.AUTH_LOGOUT.FAILURE]: (state, action) => ({
      ...initialState,
      isAuthenticated: false,
    }),
  },
  initialState,
)
