import React from 'react'
import { Row, Button, Col, Input } from 'antd'
import { withI18next } from '@/locales/withI18next'
import { API_ROOT } from 'constants/endpoint'

function MapInfoBox(props) {
  const {
    data,
    read,
    pending,
    showTrace,
    showLogs,
    showHealth,
    t,
    showActions = true,
    canRead = true,
    currentUser,
  } = props

  const enableActions = !!data?.notifySeq

  const [isSolving, setIsSolving] = React.useState(false)
  const [isPending, setIsPending] = React.useState(false)
  const [description, setDescription] = React.useState()

  const handleRead = () => {
    if (read) {
      setIsSolving(true)
      read({
        description,
        notifyId: data.notifySeq,
        action: 1,
        onCompleted: () => setIsSolving(false),
        onError: () => setIsSolving(false),
      })
    }
  }

  const handlePending = () => {
    if (pending) {
      setIsPending(true)
      pending({
        description,
        notifyId: data.notifySeq,
        action: 2,
        onCompleted: () => setIsPending(false),
        onError: () => setIsPending(false),
      })
    }
  }

  const handleShowHealth = () => {
    if (showHealth) {
      showHealth(data)
    }
  }

  const handleShowLogs = () => showLogs && showLogs()

  const handleShowTrace = () => showTrace && showTrace()

  const hasHealthAuth = currentUser?.isAdmin || currentUser?.isTechSupport || currentUser?.isMed
  const hasEditAuth = hasHealthAuth || currentUser?.isEnforcement

  return (
    <div className="w-244 overflow-x-hidden">
      <Row type="flex" align="middle" className="mt-12" style={{ flexFlow: 'row' }}>
        <img
          src={
            data?.cardOwner?.avatar
              ? `${API_ROOT}/v1/file/${data.cardOwner.avatar}`
              : '/img/avatar-pic.png'
          }
          style={{
            width: 67,
            height: 67,
            borderRadius: 40,
            marginRight: 12,
          }}
          alt="avatar"
        />
        <div>
          <h3>{data?.cardOwner?.name ?? data?.cardName ?? ''}</h3>
          <h3>{data?.cardOwner?.identityId ?? ''}</h3>
          <h3>{data?.cardOwner?.contactMobile ?? ''}</h3>
        </div>
      </Row>
      <Row className="mt-24">
        <h4>{data?.cardOwner?.address ? `Address: ${data.cardOwner.address}` : ''}</h4>
      </Row>
      {showActions && (
        <>
          <Row className="mt-24">
            <Col>
              <Button
                type="primary"
                style={{ width: '100%', backgroundColor: '#79abe5' }}
                onClick={handleShowTrace}>
                {t('view trace')}
              </Button>
            </Col>
          </Row>
          <Row className="mt-12">
            <Col>
              <Button
                type="primary"
                className="w-full"
                style={{ backgroundColor: '#79abe5' }}
                onClick={handleShowLogs}>
                {t('view violation logs')}
              </Button>
            </Col>
          </Row>
          {hasHealthAuth && (
            <Row className="mt-12">
              <Col>
                <Button
                  type="primary"
                  className="w-full"
                  style={{ backgroundColor: '#79abe5' }}
                  onClick={handleShowHealth}>
                  {t('vital sign')}
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
      {canRead && hasEditAuth && (
        <>
          <Row className="mt-24 mb-8" gutter={8}>
            <Col span={24} className="mb-8">
              <Input.TextArea rows={3} onChange={(evt) => setDescription(evt.target.value)} />
            </Col>
            <Col span={12}>
              <Button
                block
                type="primary"
                style={{ float: 'right', backgroundColor: '#79abe5' }}
                onClick={handlePending}
                disabled={!enableActions}
                loading={isPending}>
                {t('pending')}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                type="primary"
                style={{ backgroundColor: '#79abe5' }}
                onClick={handleRead}
                disabled={data.readedTag || !enableActions}
                loading={isSolving}>
                {t('solved')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default withI18next(['all'])(MapInfoBox)
