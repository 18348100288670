export const initChart = ({ xAxisData, series, title = '' }) => {
  return {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'line',
    },
    colors: [
      '#4572A7',
      '#AA4643',
      '#89A54E',
      '#80699B',
      '#3D96AE',
      '#DB843D',
      '#92A8CD',
      '#A47D7C',
      '#B5CA92',
    ],
    title: {
      text: title,
    },

    yAxis: {
      tickInterval: 1,
      title: {
        text: 'Count',
      },
    },
    xAxis: {
      categories: xAxisData,
      accessibility: {
        rangeDescription: title,
      },
    },

    // plotOptions: {
    //   series: {
    //     label: {
    //       connectorAllowed: false,
    //     },
    //     pointStart: 2010,
    //   },
    // },

    series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
        },
      ],
    },
  }
}

export default initChart
