import React from 'react'
import { Form, Row, Col } from 'antd'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import useSWR from '@/hooks/useSWR'
import { NOTIFY_STATE_NAME } from 'constants/notifyState'
import { ACTION_NAME } from 'constants/action'
import SearchForm from './SearchForm'
import { CheckboxListOrder } from './CheckboxList'
import initChart from './chart-config'

function ActionReport() {
  const { data: dataSource, refetch } = useSWR('/v1/report/alertAction', {
    lazy: true,
  })

  const [charts, setCharts] = React.useState([])

  React.useEffect(() => {
    if (!dataSource) {
      return
    }

    let temp = {}

    dataSource.data.forEach((i) => {
      temp[i.notifyType] = {}
      temp[i.notifyType].name = NOTIFY_STATE_NAME[i.notifyType]
      temp[i.notifyType].xAxis = i.data.map((i) => i.date)

      let obj = {}

      i.data.forEach((x) => {
        x.data.forEach((j) => {
          if (obj[j.action]) {
            obj[j.action].data.push(j.count)
            return
          }

          obj[j.action] = {}
          obj[j.action].data = []

          obj[j.action].name = ACTION_NAME[j.action]
          obj[j.action].data.push(j.count)
        })
      })
      temp[i.notifyType].data = obj
    })

    let charts = []

    for (var k in temp) {
      let c = { title: temp[k].name, xAxis: temp[k].xAxis, series: [] }

      const actions = temp[k].data

      for (var y in actions) {
        c.series.push(actions[y])
      }

      const idx = CheckboxListOrder.indexOf(Number(k))
      charts[idx] = c
    }

    setCharts(charts.filter((i) => i))
  }, [dataSource])

  const handleSearch = ({ dateStr, ...values }) => {
    refetch(values)
  }

  return (
    <>
      <SearchForm onSearch={handleSearch} />
      <Row gutter={[16, 32]}>
        {charts.map((i, idx) => {
          const options = initChart({ series: i.series, title: i.title, xAxisData: i.xAxis })

          return (
            <Col xl={8} key={idx}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{
                  style: { width: '100%', height: '400px' },
                }}
                allowChartUpdate
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default Form.create()(ActionReport)
