/* global google */
import cx from 'classnames'
import React from 'react'
import { Icon, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {
  length,
  pluck,
  filter as Rfilter,
  compose,
  pathOr,
  path,
  forEach,
  tap,
  head,
  mergeRight,
} from 'ramda'
import useVisible from '@/hooks/useVisible'
import { useCountryStates } from '@/data/countryStates'
import { queryString } from '@/utils/webHelper'
import { useMarkers, clearMarkers, toObject } from 'hooks/useMarkers'
import GoogleMapComponent from 'components/GoogleMapWrapper'
import BasicLayout from 'components/BasicLayout'
import MapInfoBox from 'components/MapInfoBox'
import CardHealthModal from 'components/PassengerHealthModal'
import useAuth from 'hooks/useAuth'
import useVisiblityChange from 'hooks/useVisibilityChange'
import CardActivitiesSearchDrawer from './DetailSearchDrawer'
import ViolationListDrawer from './ViolationAlertsDrawer'
import CardViolationListDrawer from './CardViolationAlertsDrawer'
import AutoSolvedAlertHistoryModal from './AutoSolvedAlertHistoryModal'
import SearchDrawer from './SearchDrawer'
import DrawerHandle from './DrawerHandle'
import useCardTrace from './useCardTrace'
import useInterval from './useInterval'
import { getLevel3Seq } from './utils'

let googleMap = null

const mappedState = createSelector(
  (state) => state.guardAreas,
  (guardAreas) => ({
    _filter: guardAreas.latestStatusFilter,
  }),
)

function ActivityMap({ location: { search } }) {
  const { user, defaultNotifyTypes: notifyTypes } = useAuth()

  const { _filter } = useSelector(mappedState)

  const [mapLoaded, setIsMapLoaded] = React.useState(false)
  const [currentCard, setCurrentCard] = React.useState()

  const [filter, setFilter] = React.useState(mergeRight({ notifyTypes }, _filter))

  const [called, setIsCalled] = React.useState(false)
  const [filterInitialized, setFilterInitialized] = React.useState(false)

  const countryStates = useCountryStates({
    onCompleted: ({ states, byId, ...rest }) => {
      if (!filter?.state && !filter.level3seq) {
        const { districtSetSeq } = user

        let state
        let dist
        let country

        if (!districtSetSeq) {
          state = compose(path(['id']), head)(states)
        }
        if (rest.isLevel1(districtSetSeq)) {
          state = districtSetSeq
        }
        if (rest.isLevel2(districtSetSeq)) {
          state = path([districtSetSeq, 'parentSeq'], byId)
          country = districtSetSeq
        }
        if (rest.isLevel3(districtSetSeq)) {
          country = path([districtSetSeq, 'parentSeq'])(byId)
          state = path([country, 'parentSeq'])(byId)
          dist = districtSetSeq
        }

        setFilter((prev) => ({
          ...prev,
          state,
          dist,
          country,
          level3seq: getLevel3Seq({ dist, country, state }),
        }))
      }
      if (filter?.dist && !filter.level3seq) {
        setFilter((prev) => ({ ...prev, level3seq: filter.dist }))
      }
      setFilterInitialized(true)
    },
  })

  const [autoSolvedHistoryModalVisible, handleAutoSolvedHistoryModalVisible] = useVisible()
  const [cardHealthModalVisible, handleCardHealthModalVisible] = useVisible()
  const [alertDrawerOpen, handleAlertDrawerOpen, [, handleAlertDrawerClose]] = useVisible({
    visible: true,
  })
  const [searchDrawerOpen, handleSearchDrawerOpen] = useVisible({ visible: true })
  const [cardAlertDrawerVisible, handleCardAlertDrawerVisible, [handleToggleCardAlertDrawer, handleCardAlertDrawerClose]] = useVisible() // prettier-ignore

  const { _latestStatus, startInterval, stopInterval, resetInterval } = useInterval({
    filter,
    onCompleted: (cards) =>
      renderMarkersOfLatestStatus({ cards, autoFitBounds: false, shouldClear: false }),
  })

  const withMarker = useMarkers({
    refetchQueries: () => {
      _latestStatus(options)
    },
  })

  const options = {
    ...filter,
    onCompleted: (cards) => renderMarkersOfLatestStatus({ cards }),
  }

  const { cardSeq } = queryString(search)

  React.useEffect(() => {
    if (mapLoaded && !called && filterInitialized) {
      _latestStatus({
        ...options,
        onCompleted: (cards) => {
          renderMarkersOfLatestStatus({ cards })

          if (cardSeq) {
            const card = withMarker.findMarkerById(cardSeq)
            withMarker.handleToggleMarker({ card })
          }
        },
      })
      startInterval()
      setIsCalled(true)
    }
  }, [mapLoaded, called, filterInitialized]) // eslint-disable-line

  React.useEffect(() => {
    return () => {
      stopInterval()

      withMarker.clearAllMarkers()
      withMarker.closeAllCircles()
      withMarker.closeAllInfoWindows()
    }
  }, []) // eslint-disable-line

  const isPageVisible = useVisiblityChange()

  React.useEffect(() => {
    if (!isPageVisible) {
      stopInterval()
    } else {
      startInterval()
    }
  }, [isPageVisible]) // eslint-disable-line

  const mapOnLoaded = (map) => {
    googleMap = map
    withMarker.setMap(map)
    setIsMapLoaded(true)
  }

  const handleFitBounds = (bounds) => {
    googleMap.fitBounds(bounds)
    // googleMap.setZoom(13)
  }

  const {
    currentSearchCard,
    focusedMarker,
    cardTraceDrawerVisible,
    renderMarkersOfCardActivities,
    handleCardTraceDrawerClose,
    handleFocusMarker,
    handleClearFocusMarker,
    handleShowTrace,
  } = useCardTrace({
    handleFitBounds,
    googleMap,
  })

  const handleSearch = (values) => {
    setFilter(values)
    _latestStatus({ ...options, ...values })

    handleCardTraceDrawerClose()
    resetInterval()
  }

  const renderMapInfoBox = ({ card, infoWindow, data }) => {
    return (
      <MapInfoBox
        data={{ ...card, ...data }}
        currentUser={user}
        close={withMarker.closeInfoWindow(infoWindow)}
        read={(values) => {
          withMarker.handleReadNotify(values.notifyId)({
            ...values,
            onCompleted: () => {
              withMarker.closeAllCircles()
              withMarker.closeAllInfoWindows()

              if (values.onCompleted) {
                values.onCompleted()
              }
            },
          })
        }}
        pending={(values) => {
          withMarker.handleReadNotify(values.notifyId)({
            ...values,
            onCompleted: () => {
              withMarker.closeAllCircles()
              withMarker.closeAllInfoWindows()

              if (values.onCompleted) {
                values.onCompleted()
              }
            },
          })
        }}
        showTrace={() => {
          handleShowTrace(card)()
          handleCardAlertDrawerClose()
          stopInterval()
          withMarker.closeAllCircles()
          withMarker.closeAllInfoWindows()
        }}
        showLogs={() => {
          handleToggleCardAlertDrawer()
          setCurrentCard(card)
          withMarker.closeAllCircles()
          withMarker.closeAllInfoWindows()
        }}
        showHealth={() => {
          handleCardHealthModalVisible()
          setCurrentCard({ ...data, ...data.passenger })
        }}
      />
    )
  }

  const renderMarkersOfLatestStatus = ({ cards, autoFitBounds = true, shouldClear = true }) => {
    let bounds = new google.maps.LatLngBounds()

    withMarker.clearAllMarkers(
      shouldClear
        ? null
        : (markerById) => {
            // 清除不在 cards 的資料
            const cardIds = pluck('id')(cards)
            clearMarkers(Rfilter((x) => !cardIds.includes(x.id)))(markerById)
            markerById = toObject(Rfilter((x) => cardIds.includes(x.id)))(markerById)
          },
    )

    compose(
      tap(() => {
        if (autoFitBounds && length(cards) > 0) {
          handleFitBounds(bounds)
        }
      }),
      forEach((card) => {
        const latitude = path(['currentLat'])(card)
        const longitude = path(['currentLon'])(card)

        if (
          !latitude ||
          !longitude ||
          !withMarker.shouldMarkerUpdate({ ...card, latitude, longitude })
        ) {
          return
        }

        let { marker } = withMarker.findMarkerById(card)
        if (marker) {
          marker.setPosition({ lat: latitude, lng: longitude })
          marker.setIcon(withMarker.setIcon({ fillColor: withMarker.getMarkerColor(card) }))
        }
        if (!marker) {
          marker = withMarker.renderMarker({
            card: {
              ...card,
              cardSeq: card.id,
              latitude,
              longitude,
              guard: {
                latitude: card.latitude,
                longitude: card.longitude,
              },
            },
            render: ({ data, infoWindow }) => renderMapInfoBox({ card, data, infoWindow }),
          })
        }

        withMarker.addOrUpdateMarker({ ...card, marker, cardSeq: card.id, isActivites: false })
        bounds.extend(new window.google.maps.LatLng({ lat: latitude, lng: longitude }))
      }),
      // Rfilter((x) => x.id === 8),
      pathOr([], []),
    )(cards)
  }

  const handleMapClick = () => {
    withMarker.closeAllInfoWindows()
    withMarker.closeAllCircles()
  }

  return (
    <>
      {autoSolvedHistoryModalVisible && (
        <AutoSolvedAlertHistoryModal onCancel={handleAutoSolvedHistoryModalVisible} />
      )}
      <BasicLayout className="p-0 min-h-full" style={{ height: 'calc(100vh - 64px)' }}>
        <SearchDrawer
          visible={searchDrawerOpen}
          filter={filter}
          useCountryStates={countryStates}
          onClose={handleSearchDrawerOpen}
          onSearch={handleSearch}
        />
        <ViolationListDrawer
          visible={alertDrawerOpen}
          onAutoSolvedAlertVisible={handleAutoSolvedHistoryModalVisible}
          onClose={handleAlertDrawerClose}
          onClick={withMarker.handleToggleMarker}
        />
        {cardHealthModalVisible && (
          <CardHealthModal passenger={currentCard} onCancel={handleCardHealthModalVisible} />
        )}
        {cardTraceDrawerVisible && (
          <CardActivitiesSearchDrawer
            currentCardId={currentSearchCard.seq}
            card={currentSearchCard}
            clearFocusMarker={handleClearFocusMarker}
            onFocusMarker={handleFocusMarker}
            onClose={() => {
              handleCardTraceDrawerClose()
              startInterval()
            }}
            stopRefetch={stopInterval}
            focusedMarker={focusedMarker}
            onSearchCompleted={renderMarkersOfCardActivities}
          />
        )}
        {cardAlertDrawerVisible && (
          <CardViolationListDrawer
            visible
            onClick={withMarker.handleToggleMarker}
            item={currentCard}
            onClose={() => {
              handleCardAlertDrawerVisible()
              handleMapClick()
            }}
          />
        )}
        <GoogleMapComponent onLoad={mapOnLoaded} onMapClick={handleMapClick}>
          <Tooltip title="Search" placement="topLeft">
            <DrawerHandle onClick={handleSearchDrawerOpen} className={cx('is--top-left')}>
              <Icon type="search" css={{ fontSize: 20 }} />
            </DrawerHandle>
          </Tooltip>
          <Tooltip title="Violation alerts" placement="topRight">
            <DrawerHandle onClick={handleAlertDrawerOpen}>
              <Icon type="bell" css={{ fontSize: 20 }} />
            </DrawerHandle>
          </Tooltip>
        </GoogleMapComponent>
      </BasicLayout>
    </>
  )
}

export default ActivityMap
