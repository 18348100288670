import React from 'react'
import { Form, Row, DatePicker, Select, Button, Radio } from 'antd'
import { pathOr, filter, propEq, isEmpty, isNil } from 'ramda'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Flex } from 'components/common/Flex'
import useAuth from 'hooks/useAuth'
import { GUARD_AREA } from 'constants/permissions'

import CheckboxList from './CheckboxList'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const Option = Select.Option

const styleSelect = {
  minWidth: 195,
}

function SearchForm({ form, onSearch, isBindingRpt = false }) {
  const { states, byId, isLoading } = useSelector((state) => state.app.allArea)
  const { user } = useAuth()

  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const [currentDist, setCurrentDist] = React.useState([])
  const [currentArea, setCurrentArea] = React.useState([])

  React.useEffect(() => {
    if (!states || !states.length) {
      return
    }

    if (states.length === 1) {
      const userState = states[0]?.id
      setFieldsValue({ state: userState })
      const districts = pathOr([], [userState, 'childs'], byId)
      if (districts.length === 1) {
        setCurrentDist(districts)
        setFieldsValue({ dist: districts[0]?.id })
        const areas = pathOr([], ['childs'], districts[0])
        if (areas.length === 1) {
          setCurrentArea(areas)
          setFieldsValue({ area: areas[0]?.id })
        }
      }
    }
  }, [states]) // eslint-disable-line

  const handleStateChange = (value) => {
    if (!value) {
      setFieldsValue({ dist: '', area: '' })
      setCurrentDist([])
      setCurrentArea([])
      return
    }

    const districts = pathOr([], [value, 'childs'], byId)
    setCurrentDist(districts)

    setFieldsValue({ dist: '', area: '' })
    setCurrentArea([])
  }

  const handleDistChange = (value) => {
    if (!value) {
      setFieldsValue({ area: '' })
      setCurrentArea([])
      return
    }

    const area = filter(propEq('id', value), currentDist)[0]
    const areas = pathOr([], ['childs'], area)

    setCurrentArea(areas)
    setFieldsValue({ area: '' })
  }

  const handleSearch = () => {
    validateFields((err, values) => {
      if (err) {
        return
      }

      const dt =
        isEmpty(values.date) || isNil(values.date)
          ? []
          : [values.date[0].format('YYYY-MM-DD'), values.date[1].format('YYYY-MM-DD')]

      const params = {
        districtSetSeq: values.area || values.dist || values.state,
        isBinding: values.isBinding,
        notifyTypes: values.notifyTypes,
        start: dt[0] && new Date(dt[0]).valueOf(),
        end: dt[1] && values.date[1].format('x'),
        dateStr: dt,
      }

      onSearch(params)
    })
  }

  const disabledStartDate = (current) => {
    return current > moment().endOf('day')
  }

  return (
    <Flex style={{ flexWrap: 'wrap', marginBottom: 30 }}>
      <Form layout="inline" colon={false} labelAlign="left">
        <Row style={{ minHeight: 83 }}>
          <FormItem label="State">
            {getFieldDecorator('state', {
              initialValue: '',
            })(
              <Select
                style={styleSelect}
                loading={isLoading}
                onChange={handleStateChange}
                disabled={GUARD_AREA[user.memberRole].state.disabled}>
                {states && states.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {states.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem label="District">
            {getFieldDecorator('dist', {
              initialValue: '',
            })(
              <Select
                style={styleSelect}
                onChange={handleDistChange}
                disabled={GUARD_AREA[user.memberRole].dist.disabled ? true : isEmpty(currentDist)}>
                {currentDist && currentDist.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {currentDist.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem label="Area">
            {getFieldDecorator('area', {
              initialValue: '',
            })(
              <Select
                style={styleSelect}
                disabled={GUARD_AREA[user.memberRole].area.disabled ? true : isEmpty(currentArea)}>
                {currentArea && currentArea.length > 1 && (
                  <Option key="all" value="">
                    All
                  </Option>
                )}
                {currentArea.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.name}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('date', {
              rules: [
                {
                  required: true,
                  message: 'please enter the date range',
                },
              ],
            })(<RangePicker disabledDate={disabledStartDate} />)}
          </FormItem>
        </Row>
        {isBindingRpt ? null : (
          <Row style={{ marginBottom: 30 }}>
            <CheckboxList getFieldDecorator={getFieldDecorator} />
          </Row>
        )}
        <Row>
          <FormItem label="Binding">
            {getFieldDecorator('isBinding', { initialValue: true })(
              <Radio.Group>
                <Radio value={true}>YES</Radio>
                <Radio value={false}>NO</Radio>
              </Radio.Group>,
            )}
          </FormItem>
        </Row>
      </Form>
      <div style={{ height: 40, display: 'inline-flex', alignItems: 'center' }}>
        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>
    </Flex>
  )
}

export default Form.create()(SearchForm)
