import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyleButton from 'components/Button'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { Form, Input, Modal, Row, Checkbox } from 'antd'
import { isNil, equals } from 'ramda'
import { login } from 'reducers/auth'
import { forgotpassword, initpassword } from 'reducers/forgotpassword'
import { StyledModal, RowM, FormItem } from '../components'
import { LOGIN_PAGE } from 'constants/routes'
import { GOOGLE_RECAPTCHA_KEY, OPEN_RECAPTCHA_CHECK, TAIPEI_HOST } from 'constants/endpoint'

const mapStateToProps = (state) => ({
  auth: state.auth,
  forgotpwd: state.forgotpassword,
  rememberUserId: state.auth.rememberUserId,
  isLoginFailed: state.auth.isLoginFailed,
  isInitCookieAuth: state.views.isInitCookieAuth,
})

const mapDispatchToProps = {
  login,
  forgotpassword,
  initpassword,
}

@connect(mapStateToProps, mapDispatchToProps)
@Form.create()
export default class LoginPage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    isLoginFailed: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    const taipeiUrl = window.location.hostname.indexOf(TAIPEI_HOST)

    this.state = {
      currentPage: LOGIN_PAGE,
      isAuthenticated: props.auth.isAuthenticated,
      captchaVal: undefined,
      isAgreement: taipeiUrl > -1,
    }
  }

  componentDidUpdate = (prevProps, prevStates) => {
    if (!equals(prevStates.currentPage, this.state.currentPage)) {
      if (this.state.currentPage === LOGIN_PAGE) {
        if (this.props.forgotpwd.isDone) {
          this.props.initpassword()
        }
      }
    }
  }

  handleSwitchPage = (currentPage) => {
    this.setState({
      currentPage,
    })

    this.props.form.setFieldsValue({ email: '' })
  }

  handleCaptcha = (captchaVal) => {
    this.setState({ captchaVal })
  }

  handleSubmit = (e) => {
    const {
      login,
      form: { validateFields },
    } = this.props

    e.preventDefault()
    validateFields((err, values) => {
      if (err) {
        return
      }

      if (OPEN_RECAPTCHA_CHECK === 'true') {
        if (!this.state.captchaVal) {
          Modal.warning({
            title: '請點選我不是Robot',
            content: '請點選驗證',
          })
          return
        }
      }

      login({
        loginId: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      })
    })
  }

  renderForm() {
    return this.renderLoginRegisterForm()
  }

  renderLoginRegisterForm() {
    const {
      form: { getFieldDecorator },
      auth,
      isLoginFailed,
      rememberUserId,
    } = this.props
    const isLoading = auth.isLoading

    return (
      <Form onSubmit={this.handleSubmit} hideRequiredMark={true}>
        <div className="title_01 mb_32">Log in</div>
        <Row>
          <FormItem label="Account" colon={false}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
              initialValue: rememberUserId,
            })(<Input placeholder="" />)}
          </FormItem>
        </Row>

        <RowM>
          <FormItem label="Password" colon={false}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
            })(<Input type="password" placeholder="" />)}
          </FormItem>
        </RowM>

        {OPEN_RECAPTCHA_CHECK === 'true' ? (
          <RowM type="flex" justify="end" className="mt_50">
            <ReCAPTCHA
              ref="recaptcha"
              hl="en"
              sitekey={GOOGLE_RECAPTCHA_KEY}
              onChange={this.handleCaptcha}
            />
          </RowM>
        ) : null}

        <RowM type="flex" justify="end" className="mb_51 mt_17">
          {getFieldDecorator('rememberMe', {
            initialValue: !isNil(rememberUserId),
            valuePropName: 'checked',
          })(<Checkbox className="font_style_14">Remember Me</Checkbox>)}
        </RowM>

        <RowM type="flex" justify="center">
          <StyleButton
            className="btn_type_h40"
            type="darkblue"
            text="Log in"
            loading={isLoading}
            htmlType="submit"
          />
          {isLoginFailed && (
            <span style={{ color: 'red', paddingLeft: '8px' }}>Incorrect email or password</span>
          )}
        </RowM>
      </Form>
    )
  }

  render() {
    const { isInitCookieAuth } = this.props

    if (isInitCookieAuth) {
      return null
    }

    return <StyledModal title="Log in">{this.renderForm()}</StyledModal>
  }
}
