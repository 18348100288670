import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentUser } from 'reducers/users'
import { APP_TITLE } from 'constants/endpoint'
import AuthPage from 'containers/Auth/index'

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.auth.isLoading,
  isLoginFailed: state.auth.isLoginFailed,
  isInitCookieAuth: state.views.isInitCookieAuth,
})

const mapDispatchToProps = { getCurrentUser }

export default function withAuth(WrappedComponent) {
  @withRouter
  @connect(mapStateToProps, mapDispatchToProps)
  class CheckAuth extends React.Component {
    static propTypes = {
      isLoading: PropTypes.bool.isRequired,
      isLoginFailed: PropTypes.bool.isRequired,
    }

    constructor(props) {
      super(props)

      this.state = {
        isAuthenticated: props.auth.isAuthenticated,
      }
    }

    componentDidMount = () => {
      if (this.props.auth.isAuthenticated) {
        this.props.getCurrentUser()
      }

      document.title = APP_TITLE
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
        this.props.getCurrentUser()
      }
    }

    render() {
      const {
        auth: { isAuthenticated },
        isInitCookieAuth,
        history,
        match,
      } = this.props

      if (isInitCookieAuth) {
        return null
      }

      if (!isAuthenticated) {
        return <AuthPage />
      }

      return <WrappedComponent history={history} match={match} />
    }
  }
  return CheckAuth
}
