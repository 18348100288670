import React from 'react'
import { Form } from 'antd'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isEmpty } from 'ramda'

import useSWR from '@/hooks/useSWR'

import SearchForm from './SearchForm'
import initChart from './chart-config'

const GENDER = { MAN: 1, WOMAN: 0, UNKNOWN: -1 }
const GENDER_NAME = { [GENDER.UNKNOWN]: 'Unknown', [GENDER.WOMAN]: 'Female', [GENDER.MAN]: 'Male' }

function BindingReport() {
  const { data: genderDataSource, refetch } = useSWR('/v1/report/bindSex', {
    lazy: true,
  })

  const [chartOptions, setChartOptions] = React.useState()
  const [title, setTitle] = React.useState()

  React.useEffect(() => {
    if (!genderDataSource) {
      return
    }

    const xAxisData = genderDataSource.data.map((i) => i.date)

    let temp = {}

    genderDataSource.data.forEach((i) =>
      i.data.forEach((i) => {
        if (temp[i.sex]) {
          temp[i.sex].data.push(i.count)
          return
        }

        temp[i.sex] = {}
        temp[i.sex].data = []
        temp[i.sex].name = GENDER_NAME[i.sex]
        temp[i.sex].data.push(i.count)
      }),
    )

    let series = []

    Object.values(GENDER).forEach((i) => series.push(temp[i]))

    const options = initChart({ series, xAxisData, title })
    setChartOptions(options)
  }, [genderDataSource, title])

  const handleSearch = ({ dateStr, ...values }) => {
    setTitle(` ${isEmpty(dateStr) ? '' : dateStr[0] + ' ~ ' + dateStr[1]}`)
    refetch(values)
  }

  return (
    <>
      <SearchForm onSearch={handleSearch} isBindingRpt={true} />
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{
            style: { width: '100%', backgroundColor: '#fff', minHeight: 400 },
          }}
          allowChartUpdate
        />
      )}
    </>
  )
}

export default Form.create()(BindingReport)
