/* global google */
import React from 'react'
import {
  length,
  map,
  mergeAll,
  values,
  filter as Rfilter,
  compose,
  pathOr,
  path,
  forEach,
  tap,
} from 'ramda'
import useMarkers from 'hooks/useMarkers'
import useVisible from '@/hooks/useVisible'
import CardTraceInfoWindow from './CardTraceInfoWindow'

export default function useCardTrace({ handleFitBounds, googleMap }) {
  const [currentSearchCard, setCurrentCard] = React.useState()
  const [focusedMarker, setFocusedMarker] = React.useState()

  const withMarker = useMarkers()

  const [cardTraceDrawerVisible, handleDetailDrawerVisible, [, handleClose]] = useVisible()

  const renderMarkersOfCardActivities = (cards) => {
    let bounds = new google.maps.LatLngBounds()
    let coordinates = []
    withMarker.clearAllMarkers((markerById) => {
      compose(
        forEach((x) => x.marker.setMap(null)),
        Rfilter((x) => x.isActivites),
        values,
      )(markerById)
      markerById = compose(
        mergeAll,
        map((x) => ({ [x.id]: x })),
        Rfilter((x) => !x.isActivites),
      )(markerById)
    })
    withMarker.removePolyline()
    compose(
      forEach((card) =>
        compose(
          tap(() => {
            if (length(coordinates) > 0) {
              withMarker.addPolyline(
                new google.maps.Polyline({
                  path: coordinates,
                  strokeColor: withMarker.getMarkerColor(card),
                  map: googleMap,
                }),
              )
              handleFitBounds(bounds)
            }
          }),
          forEach((cardPosition) => {
            if (!cardPosition.latitude || !cardPosition.longitude) {
              return
            }
            const _card = {
              ...cardPosition,
              cardPosition: { cardSeq: card.id },
              notifyHistory: { cardSeq: card.id },
            }
            const marker = withMarker.renderMarker({
              card: _card,
              icon: { fillColor: withMarker.getMarkerColor(card) },
              render: ({ data, infoWindow }) => <CardTraceInfoWindow data={data} />,
            })
            const latlng = { lat: cardPosition.latitude, lng: cardPosition.longitude }
            bounds.extend(new window.google.maps.LatLng(latlng))
            withMarker.addOrUpdateMarker({ ..._card, marker, isActivites: true })
            coordinates.push(latlng)
          }),
          pathOr([], ['cardPositions']),
        )(card),
      ),
      pathOr([], []),
    )(cards)
  }

  const handleShowTrace = (card) => () => {
    const seq = path(['id'])(card)
    if (seq) {
      handleCardTraceDrawerVisible({ ...card, seq })
    }
  }

  const handleCardTraceDrawerVisible = (card) => {
    setCurrentCard(card)
    setFocusedMarker(undefined)
    withMarker.hideMarkers()
    withMarker.closeAllInfoWindows()
    handleDetailDrawerVisible()
  }

  const handleCardTraceDrawerClose = () => {
    withMarker.toggleMarkers(({ isActivites }) => !isActivites)
    withMarker.hideMarkers(({ isActivites }) => isActivites)
    withMarker.removePolyline()
    withMarker.closeAllInfoWindows()
    handleClose()
  }

  const handleFocusMarker = ({ idx, card, mapCenter }) => {
    const { marker, ...rest } = compose(pathOr({}, []), withMarker.findMarkerById)(card)
    if (!marker) {
      return
    }

    if (focusedMarker && focusedMarker.id !== card.id) {
      handleClearFocusMarker()
    }

    marker.setIcon(withMarker.setIcon({ fillColor: '#ff0000' }))
    google.maps.event.trigger(marker, 'click', { ...rest, latLng: marker.getPosition() })
    googleMap.setCenter(mapCenter)
    setFocusedMarker({ marker, ...rest })
  }

  const handleClearFocusMarker = () => {
    if (focusedMarker) {
      focusedMarker.marker.setIcon(
        withMarker.setIcon({ fillColor: focusedMarker.colorCode || '#7aa953' }),
      )
      setFocusedMarker(undefined)
    }
  }

  return {
    currentSearchCard,
    focusedMarker,
    cardTraceDrawerVisible,
    renderMarkersOfCardActivities,
    handleShowTrace,
    handleCardTraceDrawerVisible,
    handleCardTraceDrawerClose,
    handleFocusMarker,
    handleClearFocusMarker,
  }
}
