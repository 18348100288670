export const HOME = '/'
/**
 * Auth
 * 登入頁面
 */
export const LOGIN_PAGE = '/login'
export const REGISTER = '/register'
export const FORGET_PASSWORD = '/forgetpass'
export const REGISTER_ACTIVE = '/register-active'
export const RESETPASS = '/resetpass'
export const AGREEMENT = '/agreement'
export const EMAIL_REPLACE = '/emailReplace'

/**
 * ActivityManagement
 * 動態搜尋
 */

export const MAP = `/map`

/**
 * Card management
 * 裝置管理
 */

export const CARD_MANAGEMENT = 'card-management'
export const CARD_LIST = `/${CARD_MANAGEMENT}/card-list`

export const INVITE_ACCEPT = `/${CARD_MANAGEMENT}/card-invite-accept`

/**
 * Sub management
 * 副管理名單
 */

export const SUB_MANAGEMENT = 'sub-management'
export const SUBMANAGER_LIST = `/${CARD_MANAGEMENT}/submanager-list`

/**
 * Card Authority management
 * 授權管理
 */

export const CARDAUTHORITY_MANAGEMENT = 'cardauthority-management'
export const CARDAUTHORITY_LIST = `/${CARD_MANAGEMENT}/cardauthority-list`

/**
 * Save Area management
 * 守護區域管理 - 守護區域清單
 * 守護區域管理 - 守護紀錄
 */

export const SAVEAREA_MANAGEMENT = 'guardarea-management'
export const SAVEAREA_LIST = `/${SAVEAREA_MANAGEMENT}/guardarea-list`
export const SAVEAREA_HISTORY = `/${SAVEAREA_MANAGEMENT}/guardarea-history`
export const STATUS_AND_COLOR = `/${SAVEAREA_MANAGEMENT}/colors`

/**
 * OAuth
 * 第三方認證
 */
export const OAUTH = 'oauth'
export const OAUTH_LINE = `/${OAUTH}/line`

/**
 * AlertsHistory
 */
export const ALERTS_MANAGEMENT = 'alerts-management'
export const ALERTS_LIST = `/${ALERTS_MANAGEMENT}/alerts-list`

export const SETTING = '/setting'

export const AUTO_SOLVED_ALERT_HISTORY = '/auto-solved-alert-history'
export const CLEARED_ALERT_HISTORY = '/alerts-history'

export const STATISTICS = 'statistics'
export const STATISTICS_INDEX = `/${STATISTICS}/index`

/**
 * UserList
 */
export const USERS = '/users'

/**
 * Account active
 */
export const ACTIVITIES = '/activities'

/**
 * Account management
 */
export const ACCOUNTS = '/accounts'

/**
 * Account management
 */
export const REPORT = '/report'
