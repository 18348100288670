import React from 'react'

export default function useFieldDecorator({ field, initialValue, getFieldDecorator }) {
  let wrapper = React.useMemo(
    () => (getFieldDecorator ? getFieldDecorator(field, { initialValue }) : (node) => node),
    [getFieldDecorator, initialValue],
  )

  return wrapper
}
