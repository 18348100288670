import { createAction, handleActions, combineActions } from 'redux-actions'
import {
  pipe,
  concat,
  find,
  propEq,
  filter,
  compose,
  pluck,
  slice,
  forEach,
  length,
  pathOr,
} from 'ramda'
import { message } from 'antd'
import { switchMap, map, tap, mergeMap } from 'rxjs/operators'
import { createRequestTypes } from 'actions/Types'
import { ofType, catchRequestError, getPayloadByType } from '../utils/extendOperators'
import {
  listCardActivitiesAPI,
  listMultipleCardsActivitiesAPI,
  listCardsCurrentInfoAPI,
  getCardDetailAPI,
  listNotifyByCardAPI,
} from '../apis'

/**
 * Enum
 */

export const Status = {
  1: '綁定中',
  '-1': '未綁定',
}

/**
 * Action Types
 */

const LIST_CARD_ACTIVITIES = createRequestTypes('LIST_CARD_ACTIVITIES')
const LIST_MULTIPLE_CARDS_ACTIVITIES = createRequestTypes('LIST_MULTIPLE_CARDS_ACTIVITIES')
const LIST_CARDS_CURRENT_INFO = createRequestTypes('LIST_CARDS_CURRENT_INFO')
const GET_CARD_DETAIL = createRequestTypes('GET_CARD_DETAIL')
const CLEAR_CARD_DETAIL = createRequestTypes('CLEAR_CARD_DETAIL')
const CLEAR_CARD_ACTIVITIES = createRequestTypes('CLEAR_CARD_ACTIVITIES')
// 守護紀錄
const LIST_NOTIFY_BY_CARD = createRequestTypes('LIST_NOTIFY_BY_CARD')

/**
 * Action Creator
 */
export const listCardActivities = createAction(LIST_CARD_ACTIVITIES.REQUEST)
export const listMultipleCardsActivities = createAction(LIST_MULTIPLE_CARDS_ACTIVITIES.REQUEST)
export const listCardsCurrentInfo = createAction(LIST_CARDS_CURRENT_INFO.REQUEST)
export const getCardDetail = createAction(GET_CARD_DETAIL.REQUEST)
export const clearCardDetail = createAction(CLEAR_CARD_DETAIL.REQUEST)
export const clearCardActivities = createAction(CLEAR_CARD_ACTIVITIES.REQUEST)
export const listNotifyByCard = createAction(LIST_NOTIFY_BY_CARD.REQUEST)

export const listCardActivitiesEpic = (actions, { getState }) =>
  actions.pipe(
    ofType(LIST_CARD_ACTIVITIES.REQUEST),
    switchMap(({ payload: { onCompleted, ...payload } = {} }) =>
      listCardActivitiesAPI(payload).pipe(
        mergeMap((res) => {
          if (payload.defaultTimePeriod && res.data[0].cardPositions.length <= 0) {
            message.warning('近一小時並無動態')
          }
          if (res.totalPages === res.page + 1 && onCompleted) {
            const content = pathOr([], ['cards', 'activities', 'content'], getState())

            // 只有一頁的話第一次就會執行這裡所以 content 會是空的，因此如果只有一頁就直接用 res 的資料
            onCompleted(res.totalPages === 1 ? pathOr([], ['data'], res) : content)
          }

          return [createAction(LIST_CARD_ACTIVITIES.SUCCESS)(res)]
        }),
        catchRequestError((e) => {
          message.error(`搜尋裝置軌跡失敗 (${e.message})`)
          return createAction(LIST_CARD_ACTIVITIES.FAILURE)()
        }),
      ),
    ),
  )

export const listMultipleCardsActivitiesEpic = (actions, { getState }) =>
  actions.pipe(
    ofType(LIST_MULTIPLE_CARDS_ACTIVITIES.REQUEST),
    switchMap(({ payload = {} }) =>
      listMultipleCardsActivitiesAPI(payload).pipe(
        tap((result) => {
          if (result.data === null) {
            message.error('無裝置軌跡資料')
          }
        }),
        // Todo: handle pagination with rxjs expand or sth else
        map(createAction(LIST_MULTIPLE_CARDS_ACTIVITIES.SUCCESS)),
        catchRequestError((e) => {
          message.error(`搜尋裝置軌跡失敗 (${e.message})`)
          return createAction(LIST_MULTIPLE_CARDS_ACTIVITIES.FAILURE)()
        }),
      ),
    ),
  )

export const listCardsCurrentInfoEpic = pipe(
  getPayloadByType(LIST_CARDS_CURRENT_INFO.REQUEST),
  switchMap(({ id, onMapChange, onMarkerFocus, onFitBounds, ...params } = {}) =>
    listCardsCurrentInfoAPI(params).pipe(
      tap(({ data }) => {
        let card
        if (id) {
          card = find(propEq('id', id))(data)
        }

        if (params.search) {
          card = filter((c) => c.cardName && c.cardName.toLowerCase().includes(params.search))(
            data,
          )[0]
        }

        if (id) {
          const hasCard = id && card && card.current

          if (!hasCard) {
            message.warning('查無資料')
          }

          if (hasCard) {
            if (onMapChange) {
              onMapChange({
                mapCenter: {
                  lat: card.current.latitude,
                  lng: card.current.longitude,
                },
                mapZoom: 19,
              })
            }

            if (onMarkerFocus) {
              onMarkerFocus(card.id)
            }
          }
        }

        if (length(data) > 0 && onFitBounds) {
          const bounds = new window.google.maps.LatLngBounds()

          compose(
            forEach((x) =>
              bounds.extend(
                new window.google.maps.LatLng({
                  lat: x.latitude,
                  lng: x.longitude,
                }),
              ),
            ),
            slice(0, 1000),
            pluck('current'),
          )(data)

          onFitBounds(bounds)

          if (card && onMarkerFocus) {
            onMarkerFocus(card.id)
          }
        }
      }),
      map(createAction(LIST_CARDS_CURRENT_INFO.SUCCESS)),
      catchRequestError(createAction(LIST_CARDS_CURRENT_INFO.FAILURE)),
    ),
  ),
)

export const getCardDetailEpic = pipe(
  ofType(GET_CARD_DETAIL.REQUEST),
  switchMap(({ payload = '' }) =>
    getCardDetailAPI(payload).pipe(
      map(createAction(GET_CARD_DETAIL.SUCCESS)),
      catchRequestError((e) => {
        message.error(`取得裝置失敗: ${e.message}`)
        return createAction(GET_CARD_DETAIL.FAILURE)()
      }),
    ),
  ),
)

export const listNotifyByCardEpic = pipe(
  ofType(LIST_NOTIFY_BY_CARD.REQUEST),
  switchMap(({ payload = {} }) =>
    listNotifyByCardAPI(payload).pipe(
      map(createAction(LIST_NOTIFY_BY_CARD.SUCCESS)),
      catchRequestError((err) => {
        message.error(`取得守護紀錄失敗: ${err.response.message}`)
        return createAction(LIST_NOTIFY_BY_CARD.FAILURE)()
      }),
    ),
  ),
)

const initialState = {
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
  data: {
    guardareaList: [],
    cardAuthorities: [],
  },
  content: [],
  primaryCards: [],
  secondaryCards: [],
  primaryCardCount: 0,
  secondaryCardCount: 0,
  activities: {
    content: [],
    totalPages: 0,
  },
  totalPages: 0,
  type: 1,
  page: 0,
  size: 10,
  total: 0,
  search: null,
  currentCard: undefined,
  activitiesLog: {
    page: 0,
    totalPages: 0,
    size: 10,
    hasMore: false,
    content: [],
  },
}

export default handleActions(
  {
    [combineActions(
      LIST_CARD_ACTIVITIES.REQUEST,
      LIST_MULTIPLE_CARDS_ACTIVITIES.REQUEST,
      LIST_CARDS_CURRENT_INFO.REQUEST,
      LIST_NOTIFY_BY_CARD.REQUEST,
      GET_CARD_DETAIL.REQUEST,
    )]: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    [combineActions(
      LIST_CARD_ACTIVITIES.FAILURE,
      LIST_MULTIPLE_CARDS_ACTIVITIES.FAILURE,
      LIST_CARDS_CURRENT_INFO.FAILURE,
      LIST_NOTIFY_BY_CARD.FAILURE,
      GET_CARD_DETAIL.FAILURE,
    )]: (state, action) => ({
      ...state,
      isLoading: false,
    }),

    [LIST_CARD_ACTIVITIES.SUCCESS]: (state, action) => {
      const { data, ...rest } = action.payload
      let content = state.activities.content
      if (data) {
        if (content.length === 0) {
          content = data
        } else {
          content[0].cardPositions = content[0].cardPositions.concat(data[0].cardPositions)
        }
      }
      return {
        ...state,
        activities: {
          content,
          ...rest,
        },
        isLoading: false,
      }
    },
    [LIST_CARDS_CURRENT_INFO.SUCCESS]: (state, action) => {
      const content = action.payload.data.map((item) => item.current)
      return {
        ...state,
        content,
        isLoading: false,
      }
    },

    [LIST_MULTIPLE_CARDS_ACTIVITIES.SUCCESS]: (state, action) => {
      // 依照card各自merge
      let content = state.activities.content
      if (action.payload.data) {
        if (content.length === 0) {
          content = action.payload.data
        } else {
          action.payload.data.forEach((val) => {
            for (let i in content) {
              if (content[i].id === val.id) {
                content[i].cardPositions = content[i].cardPositions.concat(val.cardPositions)
              }
            }
          })
        }
      }
      return {
        ...state,
        activities: {
          content,
        },
        isLoading: false,
      }
    },
    [LIST_CARDS_CURRENT_INFO.SUCCESS]: (state, action) => ({
      ...state,
      content: action.payload.data,
      isLoading: false,
    }),
    [GET_CARD_DETAIL.SUCCESS]: (state, action) => {
      const newCard = action.payload.data
      // HOTFIX: guardareaList is null in some cases
      if (!newCard.guardareaList) {
        newCard.guardareaList = []
      }
      return {
        ...state,
        data: newCard,
        currentCard: newCard,
        isLoading: false,
      }
    },
    [CLEAR_CARD_DETAIL.REQUEST]: (state, action) => ({
      ...state,
      data: initialState.data,
      currentCard: undefined,
    }),
    [CLEAR_CARD_ACTIVITIES.REQUEST]: (state, action) => {
      let content = []
      // filter by specific card
      if (action.payload) {
        content = state.activities.content.filter((act) => act.id === action.payload)
      }

      return {
        ...state,
        activities: {
          content,
          totalPages: 0,
        },
        activitiesLog: initialState.activitiesLog,
      }
    },
    [LIST_NOTIFY_BY_CARD.SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      activitiesLog: {
        ...action.payload,
        hasMore: action.payload.page < action.payload.totalPages,
        content: action.payload.data
          ? concat(state.activitiesLog.content, action.payload.data)
          : state.activitiesLog.content,
      },
    }),
  },
  initialState,
)
