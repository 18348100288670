import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import views from './views'
import cards from './cards'
import guardAreas from './guardAreas'
import users from './users'
import register from './register'
import forgotpassword from './forgotpassword'
import app from './app'
import account from './account'

export default function createReducer(history) {
  return combineReducers({
    auth,
    views,
    cards,
    guardAreas,
    users,
    router: connectRouter(history),
    register,
    forgotpassword,
    app,
    account,
  })
}
