import React from 'react'
import { Form, Row, Col, TimePicker, Button, Alert, Input } from 'antd'
import moment from 'moment'
import useAuth from 'hooks/useAuth'

function Tab1(props) {
  const {
    form,
    form: { getFieldDecorator, getFieldValue },
    data,
    update,
  } = props

  const { user } = useAuth()

  const onSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        update({
          testDay: Number(getFieldValue('duration')),
          offStartTime: moment(getFieldValue('startTime')).format('HH:mm'),
          offEndTime: moment(getFieldValue('endTime')).format('HH:mm'),
        })
      }
    })
  }

  return (
    <div className="ml-24 mt-30">
      <Form onSubmit={onSubmit}>
        <Row style={{ marginBottom: 20 }} gutter={16} type="flex" align="middle">
          <Col span={2}>Quarantine Duration</Col>
          <Col span={2}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('duration', {
                rules: [{ required: true }],
                initialValue: data?.testDay,
              })(<Input type="number" min="0" className="w-144" />)}
            </Form.Item>
          </Col>
          <Col span={2}>days</Col>
        </Row>
        <Row style={{ marginBottom: 20 }} gutter={16} type="flex" align="middle">
          <Col span={2}>COVID test time</Col>
          <Col span={2}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('startTime', {
                rules: [{ required: true }],
                initialValue: data?.offStartTime && moment(data.offStartTime, 'HH:mm'),
              })(<TimePicker format="HH:mm" className="w-144" placeholder="" />)}
            </Form.Item>
          </Col>
          <Col>to</Col>
          <Col span={2}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('endTime', {
                rules: [{ required: true }],
                initialValue: data?.offEndTime && moment(data.offEndTime, 'HH:mm'),
              })(<TimePicker format="HH:mm" className="w-144" placeholder="" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col>On the final day, system will not send "Leave Fence" alert.</Col>
        </Row>
        <Row>
          <Col>
            <Button type="primary" htmlType="submit" disabled={!user.isAdmin}>
              Update
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="mt-48">
        <Alert
          showIcon
          className="w-1/2"
          message="Note"
          description={
            <div>
              <p>
                System will not send <strong>Leave Fence</strong> alert
              </p>
              <p className="mt-4">
                System sends notification of <strong>Fence off</strong>
              </p>
            </div>
          }
          type="info"
        />
      </div>
    </div>
  )
}

export default Form.create()(Tab1)
