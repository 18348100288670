import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { NOTIFY_STATE, NOTIFY_STATE_NAME } from 'constants/notifyState'
import State from './State'
import { StatusLayout, Block, BlockHeader, BlockContent, StateHeader } from './style'
import useAuth from 'hooks/useAuth'

function Status({ data }) {
  const [StatusGrid, setStatusGrid] = useState([])
  const { statuses } = useAuth()

  useEffect(() => {
    setStatusGrid(statuses.filter(x => x !== NOTIFY_STATE.DelayToHome))
  }, [statuses.length]) // eslint-disable-line

  const renderState = (id) => {
    const sData = data.data.childs.find((i) => i.notifyState === id)
    const allState = sData.childs.map((i) => {
      return <State key={i.id} data={i} />
    })

    return (
      <>
        {allState}
        <StateHeader>
          <span>Total</span>
          <span>{sData.total}</span>
        </StateHeader>
      </>
    )
  }

  return (
    <StatusLayout>
      <Row>
        {StatusGrid &&
          StatusGrid.map((x) => (
            <Col key={x} xs={24} lg={8}>
              <Block>
                <a name={NOTIFY_STATE_NAME[x]} id={NOTIFY_STATE_NAME[x].toLowerCase()}>
                  <span />
                </a>
                <BlockHeader>{NOTIFY_STATE_NAME[x]}</BlockHeader>
                <BlockContent>{data && renderState(x)}</BlockContent>
              </Block>
            </Col>
          ))}
      </Row>
    </StatusLayout>
  )
}

export default Status
