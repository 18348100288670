import React from 'react'
import { Modal, Table } from 'antd'

import moment from 'moment'
import useSWR from '@/hooks/useSWR'
import { NOTIFY_BY_ID } from 'constants/notifyState'

function ActiveModal({ member, onCancel }) {
  const { data: activities, loading, refetch } = useSWR('/v1/member/log', {
    variables: {
      pageSize: 10,
      memberSeq: member.id,
    },
  })

  const handleTableChange = ({ current }) => {
    refetch({ pageNum: current - 1 })
  }

  const columns = [
    {
      title: 'Time',
      dataIndex: 'createAt',
      render: (value) => moment(value).format('YYYY.MM.DD HH:mm:ss'),
    },
    {
      title: 'Activities',
      dataIndex: 'activite',
    },
    {
      title: 'Detail',
      dataIndex: 'notifyType',
      render: (value) => (value ? NOTIFY_BY_ID[value] : ''),
    },
    {
      title: 'Name',
      dataIndex: 'passengerName',
    },
  ]

  return (
    <Modal visible title={member?.memberId} width={650} onCancel={onCancel} footer={null}>
      <Table
        rowKey={(record) => `${record.createAt}-${record.notifyType}`}
        size="small"
        bordered={false}
        loading={loading}
        columns={columns}
        dataSource={activities?.data}
        pagination={{ pageSize: 10, total: activities?.total }}
        onChange={handleTableChange}
      />
    </Modal>
  )
}

export default ActiveModal
