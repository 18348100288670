import React, { useState, useEffect } from 'react'
import { Checkbox, Icon, Button } from 'antd'

import { StyleFieldsWrap } from './style'

let TIMER_ID

export default React.memo(({ onChange, tbColumn }) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState()

  useEffect(() => {
    if (!tbColumn) {
      return
    }
    setValue(tbColumn.filter((i) => i.checked).map((i) => i.dataIndex))
  }, [tbColumn])

  const handleVisible = () => {
    setVisible((c) => !c)
  }

  const handleMouseLeave = (visible) => {
    if (visible) {
      TIMER_ID = setTimeout(() => {
        setVisible(false)
      }, 1000)
    }
  }

  const handleMouseEnter = () => {
    clearTimeout(TIMER_ID)
  }

  return (
    <>
      <div style={{ display: 'inline-block' }}>
        <Button
          style={btnStyle}
          onClick={handleVisible}
          onMouseLeave={() => handleMouseLeave(visible)}>
          <Icon style={{ fontSize: 16 }} type={visible ? 'menu-unfold' : 'menu-fold'} />
        </Button>
        <div style={{ position: 'relative' }}>
          <StyleFieldsWrap
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => handleMouseLeave(visible)}
            className={visible ? 'fold' : ''}>
            <Checkbox.Group
              value={value}
              style={{ padding: 10, width: 150 }}
              onChange={(v) => onChange(v)}>
              {tbColumn.map((v) => (
                <Checkbox key={v.dataIndex} value={v.dataIndex}>
                  {v.title}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </StyleFieldsWrap>
        </div>
      </div>
    </>
  )
})

const btnStyle = {
  backgroundColor: '#f5f5f5',
  borderColor: '#e9e9e9',
}
