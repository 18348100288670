import '@/bootstrap'
import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider } from 'styled-components'
import defaultTheme from '@/theme'
import history from '@/utils/history'
import '@/locales/i18n'
import '../index.css'

const renderer = ({ theme = {} } = {}) => (App) => {
  return (
    <ThemeProvider theme={mergeDeepRight(defaultTheme, theme)}>
      <ConfigProvider locale={enUS}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </ConfigProvider>
    </ThemeProvider>
  )
}

export default renderer
