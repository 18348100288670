import React from 'react'
import {
  path,
  pathOr,
  compose,
  pluck,
  flatten,
  filter,
  head,
  equals,
  gt,
  ifElse,
  __,
  identity,
  length,
  concat,
  map,
  mergeAll,
  values,
} from 'ramda'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const dfs = (nodes) => {
  const result = {}

  const traversal = (node, parent) => {
    if (!node) return
    result[node.id] = { ...node, parent }
    if (node.childs) node.childs.map((c) => traversal(c, node))
  }

  nodes.map((x) => traversal(x))

  return compose(
    mergeAll,
    map((x) => ({
      [x.id]: {
        ...x,
        key: x.id,
        childs: compose(
          map((c) => ({ ...c, key: c.id })),
          pathOr([], ['childs']),
        )(x),
      },
    })),
    values,
  )(result)
}

const addAllOfItemIfNeed = ifElse(
  compose(gt(__, 1), length),
  concat([{ key: 'all', id: -1, name: 'All' }]),
  identity,
)

const _flatten = compose(filter(Boolean), flatten, pluck('childs'))

const childsOf = (parentId) => compose(addAllOfItemIfNeed, pathOr([], [parentId, 'childs']))

const mappedState = createSelector(
  (state) => state.app,
  (app) => ({
    states: app.allArea.states,
    loading: app.allArea.isLoading,
    loaded: app.allArea.loaded,
  }),
)

export function useCountryStates({ onCompleted, districtSetSeq } = {}) {
  const [countryStates, setCountryStates] = React.useState([])
  const [countries, setCountries] = React.useState([])
  const [districts, setDistricts] = React.useState([])
  const [byId, setById] = React.useState({})
  const [called, setIsCalled] = React.useState(false)

  const [currentCountries, setCurrentCountries] = React.useState([])
  const [currentDists, setCurrentDists] = React.useState([])

  const handleCountryStateChange = (stateId) => {
    setCurrentCountries(childsOf(stateId)(byId))
  }

  const handleCountryChange = (countryId) => {
    setCurrentDists(childsOf(countryId)(byId))
  }

  const appState = useSelector(mappedState)

  React.useEffect(() => {
    if (!appState.loaded) return

    const states = addAllOfItemIfNeed(appState.states)
    const countries = _flatten(states)
    const districts = _flatten(countries)
    const _byId = dfs(states)

    setCountryStates(states)
    setCountries(countries)
    setDistricts(districts)
    setById(_byId)
    setCurrentCountries(compose(pathOr([], ['childs']), head)(states))
    setIsCalled(true)

    if (onCompleted) {
      onCompleted({
        states,
        countries,
        districts,
        byId: _byId,
        isLevel1: (value) => compose(equals(1), path([value, 'level']))(_byId),
        isLevel2: (value) => compose(equals(2), path([value, 'level']))(_byId),
        isLevel3: (value) => compose(equals(3), path([value, 'level']))(_byId),
      })
    }
  }, [JSON.stringify(appState.loaded)]) // eslint-disable-line

  return {
    // ...query,
    byId,
    countryStates,
    countries,
    districts,
    currentCountries,
    currentDists,
    handleCountryStateChange,
    handleCountryChange,
    called,
    loading: appState.loading,
  }
}
