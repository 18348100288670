import React from 'react'
import { Modal, Input, Button } from 'antd'

export default function ActionModal({ item, onOk, isSolving, isPending, ...props }) {
  const [description, setDesc] = React.useState()
  const handleChange = (evt) => {
    setDesc(evt.target.value)
  }
  const handleSolved = () => {
    onOk({ ...item, description, action: 1 })
  }

  const handlePending = () => {
    onOk({ ...item, description, action: 2 })
  }

  return (
    <Modal
      visible
      title={`Take Action for Bracelet ${item?.major ?? ''}`}
      maskClosable={false}
      keyboard={false}
      width={420}
      footer={[
        <Button
          key="pending-btn"
          style={{ width: '48%' }}
          disabled={!!!description}
          type="primary"
          loading={isPending}
          onClick={handlePending}>
          Pending
        </Button>,
        <Button
          key="solved-btn"
          disabled={!!!description}
          type="primary"
          className="ml-8"
          style={{ width: '48%' }}
          loading={isSolving}
          onClick={handleSolved}>
          Solved
        </Button>,
      ]}
      {...props}>
      <Input.TextArea onChange={handleChange} value={description} autoSize={{ minRows: 5 }} />
    </Modal>
  )
}
