/**
 * alert status
 */
import theme from '@/theme'

export const NOTIFY_STATE = {
  Fever: 7,
  LeaveFence: 5,
  RemoveBracelte: 4,
  LowBattery: 6,
  DelayToHome: 9,
  Normal: -1,
  OnTheWayHome: -2,
  FanceOff: -3,
  COVIDTest: -4,
  HighBloodPressure: 10,
  LowBloodOxygen: 11,
  LeaveHome: 12,
}

export const NOTIFY_STATE_NAME = {
  [NOTIFY_STATE.Fever]: 'Fever',
  [NOTIFY_STATE.LeaveFence]: 'Leave Fence',
  [NOTIFY_STATE.RemoveBracelte]: 'Remove Bracelet',
  [NOTIFY_STATE.LowBattery]: 'No Power',
  [NOTIFY_STATE.DelayToHome]: 'Delay to Home',
  [NOTIFY_STATE.Normal]: 'Normal',
  [NOTIFY_STATE.OnTheWayHome]: 'On The Way Home',
  [NOTIFY_STATE.FanceOff]: 'Fance Off',
  [NOTIFY_STATE.COVIDTest]: 'COVID Test',
  [NOTIFY_STATE.HighBloodPressure]: 'High Blood Pressure',
  [NOTIFY_STATE.LowBloodOxygen]: 'Low Blood Oxygen',
  [NOTIFY_STATE.LeaveHome]: 'Leave Home',
}

export const NOTIFY_BY_ID = {
  7: NOTIFY_STATE_NAME[NOTIFY_STATE.Fever],
  5: NOTIFY_STATE_NAME[NOTIFY_STATE.LeaveFence],
  4: NOTIFY_STATE_NAME[NOTIFY_STATE.RemoveBracelte],
  6: NOTIFY_STATE_NAME[NOTIFY_STATE.LowBattery],
  9: NOTIFY_STATE_NAME[NOTIFY_STATE.DelayToHome],
  10: NOTIFY_STATE_NAME[NOTIFY_STATE.HighBloodPressure],
  11: NOTIFY_STATE_NAME[NOTIFY_STATE.LowBloodOxygen],
  12: NOTIFY_STATE_NAME[NOTIFY_STATE.LeaveHome],
  '-1': NOTIFY_STATE_NAME[NOTIFY_STATE.Normal],
  '-2': NOTIFY_STATE_NAME[NOTIFY_STATE.OnTheWayHome],
  '-3': NOTIFY_STATE_NAME[NOTIFY_STATE.FanceOff],
  '-4': NOTIFY_STATE_NAME[NOTIFY_STATE.COVIDTest],
}

export const NOTIFY_STATE_COLOR = {
  [NOTIFY_STATE.Fever]: theme.card.red,
  [NOTIFY_STATE.LeaveFence]: theme.card.orange,
  [NOTIFY_STATE.RemoveBracelte]: theme.card.yellow,
  [NOTIFY_STATE.LowBattery]: theme.card.lightblue,
  [NOTIFY_STATE.DelayToHome]: theme.card.deepwine,
  [NOTIFY_STATE.Normal]: theme.card.green,
  [NOTIFY_STATE.OnTheWayHome]: theme.card.darkblue,
  [NOTIFY_STATE.FanceOff]: theme.card.lightorange,
  [NOTIFY_STATE.COVIDTest]: theme.card.purple,
  [NOTIFY_STATE.HighBloodPressure]: theme.card.pink,
  [NOTIFY_STATE.LowBloodOxygen]: theme.card.lightgray,
  [NOTIFY_STATE.LeaveHome]: theme.card.darkblue,
}
