import React from 'react'
import { Table, Button } from 'antd'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import useSWR from '@/hooks/useSWR'
import useVisible from '@/hooks/useVisible'
import BasicLayout from 'components/BasicLayout'
import AllAreaSearchForm from 'components/AllAreaSearchForm'
import { ROLE_NAME } from 'constants/roles'

import ActiveModal from './ActiveModal'

const ROLE_OPTIONS = Object.keys(ROLE_NAME).map((i) => ({ value: i, name: ROLE_NAME[i] }))

function Activities() {
  const [member, setMember] = React.useState()
  const [page, setPage] = React.useState(0)
  const [detailModalVisible, handleDetailModalVisible] = useVisible()
  const { byId } = useSelector((state) => state.app.allArea)

  const { data: accountData, loading, refetch } = useSWR('/v1/member/fullList', {
    lazy: true,
    variables: {
      pageSize: 10,
    },
  })

  const handleSearch = (values) => {
    refetch({
      pageNum: 0,
      memberRole: values.role,
      districtSetSeq: values.area || values.dist || values.state,
    })
    setPage(0)
  }

  const handleTableChange = ({ current }) => {
    refetch({ pageNum: current - 1 })
    setPage(current - 1)
  }

  const _handleDetailModalVisible = (val) => () => {
    setMember(val)
    handleDetailModalVisible()
  }

  const columns = [
    {
      title: 'Account',
      dataIndex: 'memberId',
    },
    {
      title: 'State',
      align: 'center',
      dataIndex: 'state',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 1) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 2) {
          const stateSeq = pathOr(undefined, ['parentSeq'], area)
          const state = pathOr(undefined, [stateSeq], byId)
          return pathOr(undefined, ['name'], state)
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)

        const stateSeq = pathOr(undefined, ['parentSeq'], country)
        const state = pathOr(undefined, [stateSeq], byId)
        const stateName = pathOr(undefined, ['name'], state)

        return stateName || 'ALL'
      },
    },
    {
      title: 'District',
      align: 'center',
      dataIndex: 'district',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)

        if (level === 2) {
          return pathOr(undefined, ['name'], area)
        }

        if (level === 1) {
          return 'ALL'
        }

        const countrySeq = pathOr(undefined, ['parentSeq'], area)
        const country = pathOr(undefined, [countrySeq], byId)
        const countryName = pathOr(undefined, ['name'], country)

        return countryName || 'ALL'
      },
    },
    {
      title: 'Area',
      align: 'center',
      dataIndex: 'districtSetSeq',
      render: (v, r) => {
        const area = pathOr(undefined, [r.districtSetSeq], byId)
        const level = pathOr(undefined, ['level'], area)
        if (level === 1 || level === 2) {
          return 'ALL'
        }

        return pathOr('ALL', ['name'], area)
      },
    },
    {
      title: 'Detail',
      align: 'center',
      key: 'action',
      render: (v, r) => (
        <StyledActionButton
          type="primary"
          size="small"
          icon="eye"
          onClick={_handleDetailModalVisible(r)}
        />
      ),
    },
  ]

  return (
    <>
      {detailModalVisible && <ActiveModal member={member} onCancel={handleDetailModalVisible} />}
      <BasicLayout
        title="Account activities"
        renderActionBar={() => (
          <AllAreaSearchForm
            labelWidth={90}
            label="Role"
            addon={{
              type: 'select',
              options: ROLE_OPTIONS,
              defaultValue: ROLE_OPTIONS[0].value,
            }}
            searchFieldName="role"
            onSearch={handleSearch}
          />
        )}>
        <Block>
          <Table
            rowKey="id"
            size="small"
            bordered
            loading={loading}
            columns={columns}
            dataSource={accountData?.data}
            pagination={{ current: page + 1, pageSize: 10, total: accountData?.total }}
            onChange={handleTableChange}
          />
        </Block>
      </BasicLayout>
    </>
  )
}

const StyledActionButton = styled(Button)`
  border: 1px solid #50a1da;
  background: ${({ theme }) => theme.lightblue};
  i {
    vertical-align: text-top;
  }
`

export const Block = styled.div`
  background-color: #f9f9f9;
  padding: 20px 20px 1px;
`

export default Activities
