import React from 'react'
import moment from 'moment'
import { pathOr } from 'ramda'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import { Drawer, List, Row, Col, Icon } from 'antd'
import { withI18next } from '@/locales/withI18next'
import useSWR from '@/hooks/useSWR'
import useViewport from '@/hooks/useViewport'
import Loading from '@/components/Loading'

let _called = false

function useNotifyLists(variables = {}) {
  const [notifyHistory, setNotifyHistory] = React.useState([])

  const { data, ...rest } = useSWR(
    variables?.id ? `/v1/notify/listByCard/{id}/{page}/{size}` : null,
    {
      variables,
      lazy: true,
      onCompleted: (response) => {
        setNotifyHistory((prev) => {
          const data = pathOr([], ['data'])(response)
          const result = !_called ? data : prev.concat(data)

          _called = true

          return result
        })
      },
    },
  )

  return { ...data, ...rest, notifyHistory }
}

function CardViolationAlertsDrawer({ t, item, onClick, onClose, tReady, ...props }) {
  const [page, setPage] = React.useState(0)

  const { height } = useViewport()

  const { loading, totalPages, notifyHistory, refetch } = useNotifyLists({
    page,
    size: 20,
    solved: false,
    id: item?.id,
  })

  React.useEffect(() => {
    _called = false
    refetch({ id: item?.id })
  }, [item?.id]) // eslint-disable-line

  const handleLoadMore = () => {
    setPage((prev) => prev + 1)
    refetch({ id: item?.id, page: page + 1, size: 20 })
  }

  const handleClick = (card) => () => {
    if (onClick) {
      onClick({ card: { ...card, cardSeq: card.deviceSeq }, showActions: false, canRead: true })
    }
  }

  let hasMore = page + 1 < totalPages

  return (
    <DrawerWithoutMask
      {...props}
      width={376}
      title={
        <div className="text-center">
          {item?.cardName}'s {t('violation logs')}
        </div>
      }
      bodyStyle={{ paddingTop: 10, paddingRight: 0 }}
      placement="right"
      mask={false}
      getContainer={false}
      style={{ position: 'absolute' }}
      onClose={onClose}>
      <div className="overflow-y-auto pr-24" css={{ height: height - 209 }}>
        <InfiniteScroll
          initialLoad={false}
          useWindow={false}
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={!loading && hasMore}>
          <List
            split={false}
            itemLayout="horizontal"
            dataSource={notifyHistory}
            loading={loading}
            renderItem={(card) => (
              <ListItem key={card.id}>
                <Row>
                  <Col>
                    <div
                      className="flex items-center cursor-pointer border-transparent border-b-2 hover:border-blue-300"
                      onClick={handleClick(card)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(`violation-alert-${card.notifyTypeValue}`, {
                            name: card.cardName,
                            position: card.guardareaName || '',
                            time: moment(card.createTime).format('YYYY.MM.DD HH:mm:ss'),
                          }),
                        }}></span>
                      <span>
                        <Icon
                          type="right"
                          className="relative -top-3 ml-2"
                          style={{ fontSize: 12 }}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
              </ListItem>
            )}>
            {loading && hasMore && <Loading />}
          </List>
        </InfiniteScroll>
      </div>
    </DrawerWithoutMask>
  )
}

const DrawerWithoutMask = styled(Drawer)`
  width: 0 !important;

  .ant-drawer-header {
    background-color: #eee;
  }

  .ant-drawer-content {
    background-color: #eee;
  }

  .ant-drawer-close > i {
    position: relative;
    top: -3px;
  }

  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0,0,0,.08), -9px 0 28px 0 rgba(0,0,0,.05), -12px 0 48px 16px rgba(0,0,0,.03);
  }

  span.lightblue-font {
    font-size: 14px;
    font-weight: 500;
    color: #79abe5;
  }

  span.lightred-font {
    font-size: 14px;
    font-weight: 500;
    color: #e57992;
  }
}
`

const ListItem = styled(List.Item)`
  padding-top: 18px !important;
  padding-bottom: 0px !important;
`

export default withI18next(['all'])(CardViolationAlertsDrawer)
