import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import useSWR from '@/hooks/useSWR'

function Board() {
  const { data, loading } = useSWR(`/v1/passenger/statutics`)
  return (
    <Row>
      <Col span={5}>
        <b>ALL TRACKED USERS</b>
        <StyledValue>{loading ? '-' : data?.data.total}</StyledValue>
      </Col>
      <Col span={5}>
        <b>NEW USERS TODAY</b>
        <StyledValue>{loading ? '-' : data?.data.todayAmount}</StyledValue>
      </Col>
    </Row>
  )
}

const StyledValue = styled.div`
  font-size: 23px;
  color: ${({ theme }) => theme.blue};
`

export default Board
