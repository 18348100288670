import React from 'react'
import { Modal } from 'antd'
import AutoSolvedAlertHistory from './AutoSolvedAlertHistory'

export default function AutoSolvedAlertHistoryModal(props) {
  return (
    <Modal
      visible
      width={960}
      style={{ top: 30 }}
      title="Auto Solved Alert History"
      okButtonProps={{ style: { display: 'none' } }}
      {...props}>
      <AutoSolvedAlertHistory />
    </Modal>
  )
}
