import { useState, useCallback } from 'react'
import { includes, equals } from 'ramda'

const useColumns = ({ columns }) => {
  const [tbColumn, setTbColumn] = useState(
    columns.filter((i) => (i.checked && equals(i.type, 'checkbox')) || !equals(i.type, 'checkbox')),
  )

  const [dynamicColumn, setDynamicColumn] = useState(
    columns.filter((i) => i.checked && equals(i.type, 'checkbox')),
  )

  const handleChange = useCallback(
    (val) => {
      let col = []

      columns.forEach((i) => {
        if (!equals(i.type, 'checkbox')) {
          col.push(i)

          return
        }

        if (equals(i.type, 'checkbox')) {
          col.push({ ...i, checked: includes(i.dataIndex, val) })

          return
        }
      })

      setDynamicColumn(col.filter((i) => i.checked))
      setTbColumn(col)
    },
    [columns],
  )

  return { tbColumn, dynamicColumn, handleChange }
}

export default useColumns
