import { combineEpics } from 'redux-observable'

import { loginEpic, logoutEpic, loginInitEpic } from './auth'
import { registerEpic, registerEmailConfirmEpic } from './register'
import {
  forgotpasswordEpic,
  resetpasswordEpic,
  resetpasswordEmailConfirmEpic,
  initpasswordEpic,
} from './forgotpassword'

import {
  listCardActivitiesEpic,
  listMultipleCardsActivitiesEpic,
  listCardsCurrentInfoEpic,
  getCardDetailEpic,
  listNotifyByCardEpic,
} from './cards'

import {
  listNotifyEpic,
  unreadNotifyEpic,
  readNotifyEpic,
  latestStatusEpic,
  exportNotifySummaryEpic,
  getNotifySummaryEpic,
  latestStatusFilterEpic,
} from './guardAreas'

import {
  listUsersEpic,
  updateUserEpic,
  getUserEpic,
  getCurrentUserEpic,
  getLineBindingUrlEpic,
  bindingLineEpic,
  unbindingLineEpic,
  verifyPhoneEpic,
  getVerifyCodeEpic,
  changeEmailEpic,
  changePasswordEpic,
  changeEmailConfirmedEpic,
} from './users'

import { listAreasEpic, listAlertAreaEpic } from './app'

import { addAccountEpic, updateAccountEpic } from './account'

import { addHospitalEpic, updateHospitalEpic } from './setting'

const cardsEpics = [
  listCardActivitiesEpic,
  listMultipleCardsActivitiesEpic,
  listCardsCurrentInfoEpic,
  getCardDetailEpic,
  listNotifyByCardEpic,
]

const guardAreasEpics = [
  listNotifyEpic,
  unreadNotifyEpic,
  readNotifyEpic,
  latestStatusEpic,
  exportNotifySummaryEpic,
  getNotifySummaryEpic,
  latestStatusFilterEpic,
]

const usersEpics = [
  listUsersEpic,
  updateUserEpic,
  getUserEpic,
  getCurrentUserEpic,
  getLineBindingUrlEpic,
  bindingLineEpic,
  unbindingLineEpic,
  verifyPhoneEpic,
  getVerifyCodeEpic,
  changeEmailEpic,
  changePasswordEpic,
  changeEmailConfirmedEpic,
]

const registerEpics = [registerEpic, registerEmailConfirmEpic]

const forgotpassowrdEpics = [
  forgotpasswordEpic,
  resetpasswordEpic,
  resetpasswordEmailConfirmEpic,
  initpasswordEpic,
]

const appEpics = [listAreasEpic, listAlertAreaEpic]

const accountEpics = [addAccountEpic, updateAccountEpic]

const settingEpics = [addHospitalEpic, updateHospitalEpic]

export default combineEpics(
  loginEpic,
  logoutEpic,
  loginInitEpic,
  ...forgotpassowrdEpics,
  ...registerEpics,
  ...cardsEpics,
  ...guardAreasEpics,
  ...usersEpics,
  ...appEpics,
  ...accountEpics,
  ...settingEpics,
)
