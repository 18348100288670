import React from 'react'
import styled from 'styled-components'
import { Modal, Row, Col } from 'antd'
import useSWR from '@/hooks/useSWR'
import Loading from '@/components/Loading'
import { useCountryStates } from '@/data/countryStates'
import { pathOr, isNil, find, propEq } from 'ramda'

export default function PassengerDetailModal({ passenger, onCancel }) {
  const { data, loading } = useSWR(`/v1/passenger/detail/${passenger.id}`)
  const { countryStates: countryData } = useCountryStates()

  const profile = pathOr([], ['data'], data)

  const state = !isNil(profile.level1Seq)
    ? find(propEq('id', profile.level1Seq))(countryData)
    : null
  const district = state ? find(propEq('id', profile.level2Seq))(state.childs) : null
  const area = district ? find(propEq('id', profile.level3Seq))(district.childs) : null

  return (
    <StyleModal
      visible
      title={profile.name}
      width={650}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary' }}>
      {loading && <Loading style={{ fontSize: 16 }} />}
      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <StyledTitle>Passport number</StyledTitle>
          <span>{profile.identityId}</span>
        </Col>
        <Col span={8}>
          <StyledTitle>Phone number</StyledTitle>
          <span>{profile.contactMobile}</span>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <StyledTitle>Gender</StyledTitle>
          <span>{profile.sex === 0 ? 'Female' : profile.sex === 1 ? 'Male' : 'Unknown'}</span>
        </Col>
        <Col span={8}>
          <StyledTitle>Ethnicity</StyledTitle>
          <span>{profile.ethnicity}</span>
        </Col>
        <Col span={8}>
          <StyledTitle>Country</StyledTitle>
          <span>{profile.country}</span>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <StyledTitle>State</StyledTitle>
          <span>{state?.displayName}</span>
        </Col>
        <Col span={8}>
          <StyledTitle>District</StyledTitle>
          <span>{district?.displayName}</span>
        </Col>
        <Col span={8}>
          <StyledTitle>Area</StyledTitle>
          <span>{area?.displayName}</span>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <StyledTitle>Bracelet ID</StyledTitle>
          <span>
            {passenger.major ? `${passenger.major}(${passenger.major})(${passenger.minor})` : '-'}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledTitle>Full address</StyledTitle>
          <span>{profile.address}</span>
        </Col>
      </Row>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)`
  .ant-modal-footer button + button {
    display: none;
  }
`

const StyledTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
`
