// import update from 'immutability-helper'
import { createAction, handleActions } from 'redux-actions'
import { pipe, concat, pathOr, compose, pick, mergeDeepRight, map as Rmap } from 'ramda'
import { message } from 'antd'
import { switchMap, map, tap, mapTo } from 'rxjs/operators'
import { createRequestTypes } from 'actions/Types'
import { ofType, catchRequestError } from '../utils/extendOperators'
import { exportToCSV } from 'utils/webHelper'
import {
  unreadNotifyAPI,
  listNotifyAPI,
  readNotifyAPI,
  latestStatusAPI,
  exportNotifySummaryAPI,
  getNotifySummaryAPI,
} from '../apis'

/**
 * Action Types
 */

const LIST_NOTIFY = createRequestTypes('LIST_NOTIFY')
const CLEAR_NOTIFY = createRequestTypes('CLEAR_NOTIFY')
const CLEAR_UFOS = createRequestTypes('CLEAR_UFOS')
const UNREAD_NOTIFY = createRequestTypes('UNREAD_NOTIFY')
const LATEST_STATUS_FILTER = createRequestTypes('LATEST_STATUS_FILTER')
const LATEST_STATUS = createRequestTypes('LATEST_STATUS')
const READ_NOTIFY = createRequestTypes('READ_NOTIFY')
const EXPORT_NotifySummary = createRequestTypes('EXPORT_NotifySummary')
const GET_NotifySummary = createRequestTypes('GET_NotifySummary')
/**
 * Action Creator
 */
export const listNotify = createAction(LIST_NOTIFY.REQUEST)
export const clearNotify = createAction(CLEAR_NOTIFY.REQUEST)
export const clearUfos = createAction(CLEAR_UFOS.REQUEST)
export const unreadNotify = createAction(UNREAD_NOTIFY.REQUEST)
export const readNotify = createAction(READ_NOTIFY.REQUEST)
export const latestStatus = createAction(LATEST_STATUS.REQUEST)
export const latestStatusFilter = createAction(LATEST_STATUS_FILTER.REQUEST)
export const exportNotifySummary = createAction(EXPORT_NotifySummary.REQUEST)
export const getNotifySummary = createAction(GET_NotifySummary.REQUEST)

/**
 * Epics
 */

export const unreadNotifyEpic = (actions, { getState }) =>
  actions.pipe(
    ofType(UNREAD_NOTIFY.REQUEST),
    switchMap(({ payload: { onCompleted, ...payload } = {} }) =>
      unreadNotifyAPI({
        ...mergeDeepRight(getState().guardAreas.latestStatusFilter, payload),
      }).pipe(
        tap((response) => {
          if (onCompleted) {
            onCompleted(response)
          }
        }),
        map((response) => createAction(UNREAD_NOTIFY.SUCCESS)({ ...response, ...payload })),
        catchRequestError(createAction(UNREAD_NOTIFY.FAILURE)),
      ),
    ),
  )

export const latestStatusEpic = (actions, { getState }) =>
  actions.pipe(
    ofType(LATEST_STATUS.REQUEST),
    switchMap(({ payload: { onCompleted, ...payload } = {} }) => {
      const _payload = mergeDeepRight(getState().guardAreas.latestStatusFilter, payload)

      const { dist, state, country, ...params } = _payload

      return latestStatusAPI(params).pipe(
        tap((response) => {
          if (onCompleted) {
            onCompleted(compose(pathOr([], ['data', 'cards']))(response))
          }
        }),
        map((response) => createAction(LATEST_STATUS.SUCCESS)({ ...response, ..._payload })),
        catchRequestError((error) => createAction(LATEST_STATUS.FAILURE)({ error, ..._payload })),
      )
    }),
  )

export const latestStatusFilterEpic = pipe(
  ofType(LATEST_STATUS_FILTER.REQUEST),
  mapTo(latestStatus()),
)

export const readNotifyEpic = pipe(
  ofType(READ_NOTIFY.REQUEST),
  switchMap(({ payload: { onCompleted, onError, ...payload } = {} }) =>
    readNotifyAPI(payload).pipe(
      // mapTo(unreadNotify(), latestStatus()),
      tap(() => {
        message.success('Case closed')
        if (onCompleted) {
          onCompleted()
        }
      }),
      map(createAction(READ_NOTIFY.SUCCESS)),
      catchRequestError((e) => {
        message.error(`Update failed: ${pathOr(e.message, ['response', 'error', 'message'], e)}`)
        if (onError) {
          onError(e)
        }
        return createAction(READ_NOTIFY.FAILURE)()
      }),
    ),
  ),
)

export const listNotifyEpic = pipe(
  ofType(LIST_NOTIFY.REQUEST),
  switchMap(({ payload = {} }) =>
    listNotifyAPI(payload).pipe(
      map((response) => createAction(LIST_NOTIFY.SUCCESS)({ ...response, ...payload })),
      catchRequestError(createAction(LIST_NOTIFY.FAILURE)),
    ),
  ),
)

export const exportNotifySummaryEpic = pipe(
  ofType(EXPORT_NotifySummary.REQUEST),
  switchMap((payload) =>
    exportNotifySummaryAPI().pipe(
      tap((res) => {
        exportToCSV('NotifySummary', res)
        message.success('匯出資料成功')
      }),
      map(createAction(EXPORT_NotifySummary.SUCCESS)),
      catchRequestError((e) => {
        message.error(`匯出卡片資料失敗： ${pathOr(e.message, ['response', 'message'], e)}`)
        return createAction(EXPORT_NotifySummary.FAILURE)()
      }),
    ),
  ),
)

export const getNotifySummaryEpic = pipe(
  ofType(GET_NotifySummary.REQUEST),
  switchMap(() =>
    getNotifySummaryAPI().pipe(
      map(createAction(GET_NotifySummary.SUCCESS)),
      catchRequestError(createAction(GET_NotifySummary.FAILURE)),
    ),
  ),
)

const initalState = {
  isLoading: false,
  isLoadingGuardArea: false,
  isLoadingNotify: false,
  isReadingNotify: false,
  content: [],
  byId: {},
  currentGuardArea: {
    cards: [],
  },
  ufos: [],
  ufosInRange: [],
  totalPages: 0,
  ufosTotalPages: 0,
  ufosTotalCount: 0,
  sysGuardArea: [],
  customGuardArea: [],
  sysGuardAreaCount: 0,
  customGuardAreaCount: 0,
  notifyHistory: {
    page: 0,
    totalPages: 0,
    size: 10,
    hasMore: false,
    content: [],
  },
  unreadNotifyHistory: [],
  latestStatusFilter: {},
  latestStatuses: {
    cards: [],
  },
  notifySummary: null,
  isLoadingNotifySummary: false,
}

export default handleActions(
  {
    [READ_NOTIFY.REQUEST]: (state, action) => ({
      ...state,
      isLoading: false,
      isReadingNotify: true,
    }),
    [READ_NOTIFY.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isReadingNotify: false,
    }),
    [UNREAD_NOTIFY.REQUEST]: (state, action) => ({
      ...state,
      isLoadingNotify: true,
      isReadingNotify: false,
    }),
    [UNREAD_NOTIFY.SUCCESS]: (state, action) => ({
      ...state,
      unreadNotifyHistory: action.payload.data || [],
      isLoadingNotify: false,
    }),
    [UNREAD_NOTIFY.FAILURE]: (state, action) => ({
      ...state,
      isLoadingNotify: false,
    }),
    [LATEST_STATUS_FILTER.REQUEST]: (state, action) => ({
      ...state,
      latestStatusFilter: action.payload,
    }),
    [LATEST_STATUS.REQUEST]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      }
    },
    [LATEST_STATUS.SUCCESS]: (state, action) => ({
      ...state,
      latestStatuses: {
        cards: compose(
          Rmap((x) => ({ ...x, cardSeq: x.id })),
          pathOr([], ['payload', 'data', 'cards']),
        )(action),
      },
      latestStatusFilter: pick(['level3seq', 'notifyTypes', 'dist', 'country', 'state'])(
        action.payload,
      ),
      isLoading: false,
    }),
    [LATEST_STATUS.FAILURE]: (state, action) => ({
      ...state,
      latestStatusFilter: pick(['level3seq', 'notifyTypes', 'dist', 'country', 'state'])(
        action.payload,
      ),
      isLoading: false,
    }),
    [LIST_NOTIFY.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [LIST_NOTIFY.SUCCESS]: (state, action) => ({
      ...state,
      notifyHistory: {
        ...action.payload,
        hasMore: action.payload.page < action.payload.totalPages,
        content: concat(
          state.notifyHistory.content,
          action.payload.data ? action.payload.data : [],
        ),
      },
      isLoading: false,
    }),
    [LIST_NOTIFY.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    [CLEAR_NOTIFY.REQUEST]: (state, action) => ({
      ...state,
      notifyHistory: initalState.notifyHistory,
    }),
    [CLEAR_UFOS.REQUEST]: (state, action) => ({
      ...state,
      ufos: [],
      ufosInRange: [],
    }),
    [EXPORT_NotifySummary.REQUEST]: (state, action) => ({
      ...state,
      isDownloading: true,
    }),
    [EXPORT_NotifySummary.SUCCESS]: (state, action) => ({
      ...state,
      isDownloading: false,
    }),
    [EXPORT_NotifySummary.FAILURE]: (state, action) => ({
      ...state,
      isDownloading: false,
    }),
    [GET_NotifySummary.REQUEST]: (state) => ({
      ...state,
      isLoadingNotifySummary: true,
    }),
    [GET_NotifySummary.SUCCESS]: (state, action) => ({
      ...state,
      notifySummary: {
        ...action.payload.data,
      },
      isLoadingNotifySummary: false,
    }),
    [GET_NotifySummary.FAILURE]: (state) => ({
      ...state,
      isLoadingNotifySummary: false,
    }),
  },
  initalState,
)
