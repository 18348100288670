import theme from '@/theme'

export const cardStatus = [
  {
    name: 'Fever',
    title: 'Fever',
    value: 7,
    color: theme.card.red,
  },
  {
    name: 'Leave fence',
    title: (
      <>
        Leave <br />
        fence
      </>
    ),
    value: 5,
    color: theme.card.orange,
  },
  {
    name: 'Remove bracelet',
    title: (
      <>
        Remove <br />
        bracelet
      </>
    ),
    value: 4,
    color: theme.card.yellow,
  },
  {
    name: 'No Power',
    title: <>No Power</>,
    value: 6,
    color: theme.card.lightblue,
  },
  {
    name: 'Delay to home',
    title: <>Delay to home</>,
    value: 9,
    color: theme.card.deepwine,
  },
  {
    name: 'High Blood Pressure',
    title: <>High Blood Pressure</>,
    value: 10,
    color: theme.card.pink,
  },
  {
    name: 'Low Blood Oxygen',
    title: <>Low Blood Oxygen</>,
    value: 11,
    color: theme.card.lightgray,
  },
  {
    name: 'Leave Home',
    title: <>Leave Home</>,
    value: 12,
    color: theme.card.darkblue,
  },
  {
    name: 'Normal',
    title: <>Normal</>,
    value: 0,
    color: theme.card.green,
  },
  {
    name: 'On the Way Home',
    title: <>On the Way Home</>,
    value: -2,
    color: theme.card.blue,
  },
  {
    name: 'Fence off',
    title: <>Fence off</>,
    value: -3,
    color: theme.card.lightorange,
  },
  {
    name: 'COVID test',
    title: <>COVID test</>,
    value: -4,
    color: theme.card.purple,
  },
]

export const readStatus = {
  0: 'No Process',
  1: 'Solved',
  2: 'Pending',
}
