import { compose, join, map, filter, keys } from 'ramda'

export default function toQueryString(paramsObject = {}) {
  if (!paramsObject) {
    return ''
  }

  return compose(
    join('&'),
    map((key) =>
      Array.isArray(paramsObject[key])
        ? // convert to key=val1,val2,val3 string
          `${key}=${paramsObject[key].map((val) => `${encodeURIComponent(val)}`).join(',')}`
        : // convert to key=val string
          `${key}=${encodeURIComponent(paramsObject[key])}`,
    ),
    filter(
      (key) =>
        paramsObject[key] !== '' &&
        paramsObject[key] !== null &&
        typeof paramsObject[key] !== 'undefined',
    ),
    keys,
  )(paramsObject)
}
