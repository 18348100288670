import { ROLES } from './roles'

const handleGuardArea = (state, dist, area) => ({
  state: { disabled: state, required: state },
  dist: { disabled: dist, required: dist },
  area: { disabled: area, required: area },
})

export const GUARD_AREA = {
  [ROLES.Admin]: handleGuardArea(false, false, false),
  [ROLES.AIRPORT_STAFF]: handleGuardArea(false, false, false),
  [ROLES.HOSPITAL_STAFF]: handleGuardArea(false, false, false),
  [ROLES.CONTROL_CENTER]: handleGuardArea(false, false, false),
  [ROLES.IPD]: handleGuardArea(true, true, false),
  [ROLES.ENFORCEMENT_TEAM]: handleGuardArea(true, true, true),
  [ROLES.MEDICAL_STAFF]: handleGuardArea(false, false, false),
  [ROLES.IPK]: handleGuardArea(true, false, false),
}
