import React from 'react'
import styled from 'styled-components'
import { Table, Input, Form } from 'antd'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus()
      }
    })
  }

  save = (e) => {
    const { record, handleSave } = this.props
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return
      }
      this.toggleEdit()
      handleSave({ ...record, ...values })
    })
  }

  renderCell = (form) => {
    this.form = form
    const { children, dataIndex, record, title } = this.props
    const { editing } = this.state
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            className="w-full"
            onPressEnter={this.save}
            onBlur={this.save}
          />,
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}>
        {children}
      </div>
    )
  }

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    )
  }
}

export default class EditableTable extends React.Component {
  handleDelete = (key) => {
    if (this.props.onDelete) {
      this.props.onDelete(key)
    }
  }

  handleAdd = () => {
    if (this.props.onAdd) {
      this.props.onAdd()
    }
  }

  handleSave = (row) => {
    if (this.props.onSave) {
      this.props.onSave(row)
    }
  }

  render() {
    const { dataSource, columns, editable, ...props } = this.props
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    }
    const _columns = columns.map((col) => {
      if (!col.editable || !editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })
    return (
      <StyledTable
        // bordered
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={_columns}
        {...props}
      />
    )
  }
}

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 13px 16px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  td:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }

  .ant-form-item-control {
    line-height: 32px;
  }
`
