import React from 'react'
import styled from 'styled-components'
import {
  identity,
  gt,
  path,
  __,
  length,
  head,
  compose,
  pathOr,
  ifElse,
  always,
  equals,
  anyPass,
} from 'ramda'
import { Drawer, Row, Col, Select, Divider, Form, Checkbox, Button } from 'antd'
import { withI18next } from '@/locales/withI18next'
import { createFormItemLayout } from '@/utils/form'
import { isNil } from '@/utils/webHelper'
import { cardStatus } from '@/constants/card'
import useAuth from 'hooks/useAuth'
import { GUARD_AREA } from 'constants/permissions'
import { getLevel3Seq } from './utils'

const CheckboxGroup = Checkbox.Group

const _isNil = anyPass([isNil, equals(-1)])

const _value = ifElse(compose(gt(__, 1), length), always(-1), compose(path(['id']), head))

function SearchDrawer({ filter, t, onSearch, onClose, form, tReady, useCountryStates, ...props }) {
  const { defaultNotifyTypes, checkPermission, user } = useAuth()

  const { getFieldDecorator, getFieldsValue } = form

  const {
    handleCountryChange,
    handleCountryStateChange,
    countryStates,
    currentCountries,
    currentDists,
    called,
    loading,
    byId,
  } = useCountryStates

  React.useEffect(() => {
    if (!called) return
    if (filter.state) {
      handleCountryStateChange(filter.state)
    }
    if (filter.country) {
      handleCountryChange(filter.country)
    }
  }, [JSON.stringify(filter), called]) // eslint-disable-line

  const _handleCountryStateChange = (value) => {
    handleCountryStateChange(value)

    const countries = pathOr([], [value, 'childs'], byId)

    let country = _value(countries)
    let dist = ifElse(isNil, identity, (v) => compose(_value, pathOr([], [v, 'childs']))(byId))(
      country,
    )

    form.setFieldsValue({ country, dist })
  }

  const _handleCountryChange = (value) => {
    handleCountryChange(value)
    form.setFieldsValue({
      dist: compose(_value, pathOr([], [value, 'childs']))(byId),
    })
  }

  const handleSearch = () => {
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      if (onSearch) {
        onSearch({
          ...values,
          level3seq: getLevel3Seq(values),
          notifyTypes: values.notifyTypes,
        })
      }
    })
  }

  const { state, country } = getFieldsValue()

  const disableState = loading || GUARD_AREA[user.memberRole]?.state?.disabled
  const disableCountry = loading || _isNil(state) || GUARD_AREA[user.memberRole]?.dist?.disabled
  const disableDist = loading || _isNil(country) || GUARD_AREA[user.memberRole]?.area?.disabled

  return (
    <StyledDrawer
      {...props}
      width={260}
      title={<div className="text-center">{t('search')}</div>}
      placement="left"
      bodyStyle={{ paddingLeft: 12, paddingRight: 16 }}
      mask={false}
      getContainer={false}
      style={{ position: 'absolute' }}
      onClose={onClose}>
      <Form colon={false} labelAlign="left">
        <FormItem {...formItemlayLong} label={'State'} className="mb-12">
          {getFieldDecorator('state', {
            initialValue: ifElse(equals(true), always(undefined), () =>
              pathOr(undefined, ['state'], filter),
            )(loading),
          })(
            <Select
              loading={loading}
              disabled={disableState}
              className="w-full"
              onChange={_handleCountryStateChange}>
              {countryStates.map((x) => (
                <Select.Option key={x.key} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem {...formItemlayLong} label={'District'} className="mb-12">
          {getFieldDecorator('country', {
            initialValue: ifElse(equals(true), always(undefined), () =>
              pathOr(undefined, ['country'], filter),
            )(loading),
          })(
            <Select
              loading={loading}
              disabled={disableCountry}
              className="w-full"
              onChange={_handleCountryChange}>
              {currentCountries.map((x) => (
                <Select.Option key={x.key} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem {...formItemlayLong} label={'Area'} className="mb-12">
          {getFieldDecorator('dist', {
            initialValue: ifElse(equals(true), always(undefined), () =>
              pathOr(undefined, ['dist'], filter),
            )(loading),
          })(
            <Select disabled={disableDist} loading={loading} className="w-full">
              {currentDists.map((x) => (
                <Select.Option key={x.key} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <Divider className="my-20" />
        {getFieldDecorator('notifyTypes', {
          initialValue: pathOr(defaultNotifyTypes, ['notifyTypes'], filter),
        })(
          <CheckboxGroup style={{ width: '96%' }}>
            {cardStatus
              .filter((status) => checkPermission(status.value))
              .map((status, idx) => (
                <Row key={`checkbox-${idx}`} gutter={[16, 16]} align="middle">
                  <label htmlFor={status.name}>
                    <Col span={3}>
                      {status.value >= 0 && <Checkbox value={status.value} id={status.name} />}
                    </Col>
                    <Col span={10}>{status.title}</Col>
                    <Col span={11}>
                      <Block style={{ background: status.color }} />
                    </Col>
                  </label>
                </Row>
              ))}
          </CheckboxGroup>,
        )}

        <Button type="primary" className="mt-20" block onClick={handleSearch}>
          {t('search')}
        </Button>
      </Form>
    </StyledDrawer>
  )
}

const formItemlayLong = createFormItemLayout(5, 18)

const FormItem = Form.Item

const Block = styled.div`
  height: 26px;
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-close > i {
    position: relative;
    top: -3px;
  }

  .ant-drawer-wrapper-body {
    ::-webkit-scrollbar {
      width: 8px; // for vertical scroll bar
      height: 8px; // for horizontal scroll bar
    }
  }
`

export default compose(Form.create(), withI18next(['all']))(SearchDrawer)
