/* global google */
import React from 'react'
import ReactDOM from 'react-dom'
import { message } from 'antd'
import { renderToString } from 'react-dom/server'
import { useDispatch } from 'react-redux'
import * as fa from 'fontawesome-markers'
import {
  pathOr,
  isNil,
  compose,
  values,
  map as Rmap,
  forEach,
  mergeAll,
  filter as Rfilter,
  find,
  propEq,
  ifElse,
  path,
  identity,
  is,
} from 'ramda'
import { uuid } from '@/utils/webHelper'
import Skeleton from 'components/Skeleton'
import { readNotify } from 'reducers/guardAreas'
import { getCardDetailAPI } from 'apis'
import { cardStatus } from '@/constants/card'

const _values = (...fns) => compose(...fns, values)

let markerById = {}
let infoWindows = {}
let circles = {}
let polyline = null
let map = null

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const clearMarkers = (...args) =>
  markerValues(
    forEach((x) => x.marker.setMap(null)),
    ...args,
  )

export const markerValues = (...args) => compose(...args, values)

export const toObject = (...args) =>
  compose(
    mergeAll,
    Rmap((x) => ({ [x.id]: x })),
    ...args,
    values,
  )

export function useMarkers({ refetchQueries } = {}) {
  const dispatch = useDispatch()

  const [notifyHistory, setNotifyHistory] = React.useState(null)

  const getCardField = (card) => {
    // return seach ? 'cardPosition' : 'notifyHistory'
    return card?.notifyHistory ? 'notfiyHistory' : 'cardPosition'
  }

  const closeAllInfoWindows = () => {
    _values(Rmap((x) => x?.setMap(null)))(infoWindows)
    infoWindows = {}
  }

  const closeAllCircles = () => {
    _values(Rmap((x) => x?.setMap(null)))(circles)
    circles = {}
  }

  const closeInfoWindow = (infoWindow) => () => {
    if (!isNil(infoWindow)) {
      infoWindow?.setMap(null)
      closeAllCircles()
    }
  }

  const hideAllMarkers = (filter = (x) => x) => {
    _values(
      Rmap((x) => x?.marker?.setVisible(false)),
      Rfilter(filter),
    )(markerById)
  }

  const toggleAllMarkers = (filter = (x) => x) => {
    _values(
      Rmap((x) => x?.marker?.setVisible(true)),
      Rfilter(filter),
    )(markerById)
  }

  const clearAllMarkers = (filter) => {
    if (filter) {
      filter(markerById)
      return
    }

    _values(Rmap((x) => x?.marker?.setMap(null)))(markerById)

    markerById = {}
  }

  const closeEventModal = () => {
    setNotifyHistory(null)
  }

  const handleReadNotify = (id) => ({ onCompleted, ...values }) => {
    dispatch(
      readNotify({
        id: id,
        ...values,
        onCompleted: () => {
          if (onCompleted) {
            onCompleted()
          }
          if (refetchQueries) {
            refetchQueries()
          }
        },
      }),
    )
  }

  const getCardById = async ({ card, search }) => {
    let cardSeq = card?.cardSeq

    if (!cardSeq) {
      return Promise.resolve({ data: {} })
    }

    return getCardDetailAPI(cardSeq).toPromise()
  }

  const getMarkerColor = (card) => {
    const status = find(propEq('value', card.notifyState))(cardStatus)
    return status?.color || '#7aa953'
  }

  const findInfoWindow = (card) => {
    return infoWindows[card.id]
  }

  const findMarkerById = (card) => {
    const id = ifElse(is(String), identity, path(['id']))(card)
    return pathOr({}, [id], markerById)
  }

  const addInfoWindow = (data) => {
    infoWindows[data.id] = data.infoWindow
  }

  const addOrUpdateMarker = (card) => {
    markerById[card.id] = card
  }

  const addPolyline = (value) => {
    polyline = value
  }

  const removePolyline = () => {
    if (polyline) {
      polyline.setMap(null)
      polyline = null
    }
  }

  const setMap = (value) => {
    map = value
  }

  const setIcon = (options) => {
    return {
      path: fa.MAP_MARKER,
      scale: 0.5,
      strokeWeight: 0,
      fillOpacity: 1,
      ...options,
    }
  }

  const shouldMarkerUpdate = (card) => {
    const prevCard = markerById[card.id]
    const latlng = prevCard?.marker?.getPosition()?.toJSON()

    return (
      (!prevCard && card) ||
      latlng?.lat !== card.latitude ||
      latlng?.lng !== card.longitude ||
      getMarkerColor(prevCard) !== getMarkerColor(card)
    )
  }

  const renderMarker = ({ icon, card, render, search }) => {
    const marker = new google.maps.Marker({
      icon: {
        path: fa.MAP_MARKER,
        scale: 0.5,
        strokeWeight: 0,
        fillOpacity: 1,
        fillColor: getMarkerColor(card),
        ...icon,
      },
      position: { lat: card.latitude, lng: card.longitude },
      map: map,
    })

    marker.addListener('click', async (evt) => {
      let _infoWindow = findInfoWindow(card)
      let circle
      if (_infoWindow?.getMap()) {
        return
      }

      closeAllInfoWindows()
      closeAllCircles()

      if (card.guard) {
        circle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map,
          center: {
            lat: card.guard.latitude,
            lng: card.guard.longitude,
          },
          radius: card.radius,
        })
        circles[card.id] = circle
      }

      const containerId = `gm-${uuid()}`

      const infoWindow = new google.maps.InfoWindow({
        position: { lat: evt.latLng.lat(), lng: evt.latLng.lng() },
        disableAutoPan: false,
        pixelOffset: new google.maps.Size(9, -25),
        content: `<div id="${containerId}">${renderToString(
          <div style={{ width: 244 }}>
            <Skeleton length={2} />
          </div>,
        )}</div>`,
      })

      // 手動用 trigger.event(element, 'event') 觸發的參數會放在 evt
      const _card = { ...card, ...evt }

      infoWindow.open(map)
      infoWindow.addListener('closeclick', (evt) => {
        closeInfoWindow(infoWindow)
        closeAllCircles()
      })

      addInfoWindow({ id: _card.id, infoWindow })

      let { data } = await getCardById({ card: _card, search })

      await sleep(500)

      circle && circle.setMap(map)

      const el = document.getElementById(containerId)

      if (el) {
        el.innerHTML = ''
      }

      ReactDOM.render(render({ data, infoWindow }), el)
    })

    return marker
  }

  const handleToggleMarker = ({ card: _card, showActons = false, onCompleted, ...props }) => {
    const target = markerById[_card?.cardSeq]

    if (!target) {
      message.warning(`Cannot find the ${_card.cardName} card on map`)
      return
    }

    const { marker, ...card } = target
    const latLng = { lat: card.currentLat, lng: card.currentLon }

    const alertCard = {
      ...card,
      notifyId: _card.notifyId,
      isViolationAlert: _card.isViolationAlert || false,
      // 改用事件id, 因為打 read notify API 時是要帶事件 id 而不是 cardSeq
      id: _card.id,
      cardSeq: _card.cardSeq,
      latLng: { lat: () => latLng.lat, lng: () => latLng.lng },
    }

    google.maps.event.trigger(marker, 'click', { ...alertCard })

    map.setCenter(marker.getPosition())
  }

  return {
    addInfoWindow,
    addOrUpdateMarker,
    addPolyline,
    clearAllMarkers,
    closeAllInfoWindows,
    closeAllCircles,
    closeInfoWindow,
    findInfoWindow,
    findMarkerById,
    getCardById,
    getCardField,
    getMarkerColor,
    handleReadNotify,
    handleToggleMarker,
    notifyHistory,
    removePolyline,
    renderMarker,
    setIcon,
    setMap,
    shouldMarkerUpdate,
    handleCloseEventModal: closeEventModal,
    hideMarkers: hideAllMarkers,
    toggleMarkers: toggleAllMarkers,
  }
}

export default useMarkers
