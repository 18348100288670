import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Menu, Layout, Divider } from 'antd'
import { rem } from 'polished'
import { withI18next } from '@/locales/withI18next'
import Loading from '@/components/Loading'
import { unreadNotify, exportNotifySummary, getNotifySummary } from 'reducers/guardAreas'
import { getCurrentUser } from 'reducers/users'
import { logout } from 'reducers/auth'
import {
  MAP,
  CLEARED_ALERT_HISTORY,
  SETTING,
  STATISTICS,
  STATISTICS_INDEX,
  HOME,
  USERS,
  ACCOUNTS,
  ACTIVITIES,
  REPORT,
} from 'constants/routes'
import { Link } from 'react-router-dom'
import history from '@/utils/history'
import { ROLES } from 'constants/roles'
import useAuth from 'hooks/useAuth'

const style = { height: '100%', borderRight: 0 }

const menuRouteMap = {
  home: {
    key: 'home',
    SubMenus: {},
  },
  [MAP]: {
    key: MAP,
    SubMenus: {
      MAP,
    },
  },
  [CLEARED_ALERT_HISTORY]: {
    key: CLEARED_ALERT_HISTORY,
    SubMenus: {
      CLEARED_ALERT_HISTORY,
    },
  },
  [SETTING]: {
    key: SETTING,
    SubMenus: {
      SETTING,
    },
  },
  [STATISTICS]: {
    key: STATISTICS_INDEX,
    SubMenus: {
      STATISTICS_INDEX,
    },
  },
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  isLoading: state.users.isLoading,
  isUpdatingEmail: state.users.isUpdatingEmail,
  unreadNotifyHistory: state.guardAreas.unreadNotifyHistory,
  notifySummary: state.guardAreas.notifySummary,
  isLoadingNotifySummary: state.guardAreas.isLoadingNotifySummary,
  isRefreshingMap:
    state.guardAreas.isLoadingNotify || state.guardAreas.isLoading || state.cards.isLoading,
})

const mapDispatchToProps = {
  logout,
  getCurrentUser,
  unreadNotify,
  exportNotifySummary,
  getNotifySummary,
}

function AppHeader(props) {
  const {
    // getNotifySummary,
    // exportNotifySummary,
    // unreadNotify,
    currentUser,
    // getCurrentUser,
    // notifySummary,
    // unreadNotifyHistory,
    // isLoading,
    // isUpdatingEmail,
    isRefreshingMap,
    logout,
  } = props

  const { user } = useAuth()

  const handleLogoClick = () => {
    history.push('/')
  }

  return (
    <StyledHeader>
      <Flex>
        <Logo onClick={handleLogoClick} className="cursor--pointer">
          <img src="/img/logo.png" alt="backstage" />
        </Logo>
        <StyledMenu
          mode="horizontal"
          defaultSelectedKeys={[menuRouteMap[STATISTICS].key]}
          style={style}>
          <MainMenu key={menuRouteMap[STATISTICS].key}>
            <Link to={HOME}>
              <span>Statistics</span>
            </Link>
          </MainMenu>
          <MainMenu key={menuRouteMap[MAP].key}>
            <Link to={MAP}>
              <span>Map</span>
            </Link>
          </MainMenu>
          <MainMenu key="3">
            <Link to={USERS}>
              <span>{`User list & condition`}</span>
            </Link>
          </MainMenu>
          <MainMenu key={menuRouteMap[CLEARED_ALERT_HISTORY].key}>
            <Link to={CLEARED_ALERT_HISTORY}>
              <span>Alerts history</span>
            </Link>
          </MainMenu>
          {
            user.isAdmin && (
              <MainMenu key={menuRouteMap[SETTING].key}>
                <Link to={SETTING}>
                  <span>Setting</span>
                </Link>
              </MainMenu>
            )
          }
          {
            user.isAdmin && (
              <MainMenu key="4">
                <Link to={ACTIVITIES}>
                  <span>Account activities</span>
                </Link>
              </MainMenu>
            )
          }
          {
            user.isAdmin && (
              <MainMenu key="5">
                <Link to={ACCOUNTS}>
                  <span>Account management</span>
                </Link>
              </MainMenu>
            )
          }
          <MainMenu key="6">
            <Link to={REPORT}>
              <span>Report</span>
            </Link>
          </MainMenu>
        </StyledMenu>
      </Flex>
      <div className="auth-identity">
        <div>
          Unit：{ROLES[currentUser.memberRole]} <StyleDivider type="vertical" />
          id：{currentUser.memberId}
        </div>
        <div style={{ cursor: 'pointer', marginLeft: 10 }} onClick={logout}>
          Logout
        </div>
        <Loading
          style={{ fontSize: 16 }}
          className={cx('text-white relative -right-10 -top-2', {
            visible: isRefreshingMap,
            invisible: !isRefreshingMap,
          })}
        />
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${rem('24px')};
  color: white;
  white-space: nowrap;
  min-height: 60px;
  min-width: 320px;
  width: ${({ width }) => width};
  padding-left: 16px;
  padding-right: 8px;
  background-color: ${(p) => p.theme.header.bg};
  height: inherit;
  flex-wrap: wrap;

  .b_font_14_white {
    font-size: 14px;
    font-weight: bold;
    color: ${(p) => p.theme.header.textColor};
  }

  p {
    margin-bottom: 0;
  }

  > .auth-identity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 20px;
    min-width: 220px;
    font-size: 13px;
    font-weight: 700;
    color: ${(p) => p.theme.header.textColor};
  }

  .anticon {
    color: ${(p) => p.theme.header.textColor};
    cursor: pointer;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const StyleDivider = styled(Divider)`
  background-color: ${(p) => p.theme.header.textColor};
  width: 0.1rem;
`

const StyledMenu = styled(Menu)`
  &.ant-menu {
    height: auto !important;
    margin-bottom: auto;
    background: ${(p) => p.theme.menu.bg};
  }

  &.ant-menu.ant-menu-horizontal .ant-menu-item-selected {
    background: ${(p) => p.theme.menu.activeBg};

    > a {
      color: ${(p) => p.theme.menu.activeColor};
    }

    &:after {
      display: none;
    }
  }

  .ant-menu-submenu-title {
    color: ${(p) => p.theme.menu.textColor};

    i[class*='icon-'] {
      > svg > g > g {
        transition: stroke 0.3s ease;
        stroke: ${(p) => p.theme.menu.textColor};
      }
    }
  }

  .ant-menu-submenu-title:hover {
    color: ${(p) => p.theme.menu.textColor};
    background: ${(p) => p.theme.menu.hoverBg};

    i[class*='icon-'] {
      > svg > path {
        fill: ${(p) => p.theme.menu.textColor};
      }
    }
  }

  &.ant-menu-horizontal {
    .ant-menu-item {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      color: ${(p) => p.theme.menu.textColor};
      font-weight: 500;
      font-size: 13px;

      a:hover {
        color: ${(p) => p.theme.menu.activeColor};
      }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: ${(p) => p.theme.menu.activeBg};
      color: ${(p) => p.theme.menu.activeColor};
    }

    .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      right: 10px;
      top: 47%;
    }

    .ant-menu-horizontal
      .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      height: 1px;
      background: ${(p) => p.theme.header.bg};
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow {
      top: 43%;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(-3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(3px);
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(-3px);
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
      background: ${(p) => p.theme.primary};
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
      background: ${(p) => p.theme.primary};
    }

    .ant-menu-submenu-title {
      margin-top: 6px;
      padding-left: 10px !important;
      padding-bottom: 6px;
      /* border-bottom: 1px solid #484848; */
      font-size: 14px;

      .anticon {
        position: relative;
        top: 1px;
        margin-right: 6px;
      }

      > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-submenu-selected {
      color: ${(p) => p.theme.activeColor};
      background: ${(p) => p.theme.menu.activeBg};

      > .ant-menu-submenu-title {
        color: ${(p) => p.theme.activeColor};
      }

      i[class*='icon-'] {
        > svg > path {
          fill: ${(p) => p.theme.activeColor};
        }
      }
    }
  }
`

const MainMenu = styled(Menu.Item)`
  &.ant-menu-item {
    height: 40px;
    padding: 0px 12px 1px !important;
    background-color: ${(p) => p.theme.menu.bg} !important;
    border-bottom: transparent !important;

    > a {
      font-size: 14px;
      font-weight: 600;
      color: ${(p) => p.theme.menu.textColor};

      .anticon {
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }

  &.ant-menu-item-disabled > a {
    color: ${(p) => p.theme.menu.textColor} !important;
  }

  &.ant-menu-item:hover,
  &.ant-menu-item-selected {
    border-bottom: transparent !important;
    > a {
      color: ${(p) => p.theme.activeColor};
    }

    i[class*='icon-'] {
      > svg > path {
        fill: ${(p) => p.theme.activeColor};
      }
    }
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  font-weight: 500;
  height: 60px;

  .logo-text {
    color: #fff;
    font-size: 16px;
    margin-top: -2px;

    &.is-hidden {
      display: none;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 55px;
    margin-right: 5px;
  }
`
export default withI18next(['all'])(connect(mapStateToProps, mapDispatchToProps)(AppHeader))
