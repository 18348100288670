import React from 'react'

export default function useVisible({ visible = false, onRequestOpen } = {}) {
  const [_visible, setVisible] = React.useState(visible)

  const handleVisible = (evt) => {
    setVisible((prev) => !prev)

    if (onRequestOpen) {
      onRequestOpen()
    }
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleOpen = () => {
    setVisible(true)
  }

  return [_visible, handleVisible, [handleOpen, handleClose]]
}
